<template>
  <div>
    <div class="list">
      <label>联系人：</label>
      <span>{{ data.orderLinkman }}</span>
      <label class="list-tel">联系电话：</label>
      <span>{{ data.orderLinkphone }}</span>
    </div>
    <div class="list">
      <label>所在地址：</label>
      <span
        >{{ data.orderAddressProvince }}{{ data.orderAddressCity
        }}{{ data.orderAddressDistrict }}{{ data.orderCompanyAddress }}</span
      >
    </div>
    <div class="list">
      <label>所在单位：</label>
      <span>{{ data.orderCompany }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
<style lang="scss" scoped>
.list {
  margin-bottom: 8px;
  > label {
    color: #909399;
  }
  &-tel {
    margin-left: 20px;
  }
}
</style>
