<template>
  <el-tabs v-model="activeName" class="tabs">
    <el-tab-pane label="客户信息" name="first">
      <div class="user">
        <div class="user-list">
          <span>用户名：</span>{{ consult.userRealName }}
        </div>
        <div class="user-list">
          <span>手机号码：</span>{{ consult.userPhone }}
        </div>
        <div class="user-list"><span>邮箱：</span>{{ consult.userEmail }}</div>
      </div>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
export default {
  props: {
    consult: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      activeName: "first",
    };
  },
};
</script>
<style lang="scss" scoped>
.tabs {
  /deep/ .el-tabs__header {
    margin-bottom: 10px;
  }
  /deep/ .el-tabs__item {
    height: 35px;
    line-height: 35px;
  }
}
.user {
  padding: 0 10px;
  &-list {
    margin-bottom: 10px;
  }
}
</style>
