<template>
  <div class="goog-wrapper flex">
    <div class="flex-1">
      <div class="labName" v-if="labName">商家：{{ labName }}</div>
      <div v-for="(good, gIndex) in goods" :key="gIndex" class="good">
        <div class="flex">
          <div class="good-cover">
            <img
              :src="good.productmallWaresImage"
              v-if="good.productmallWaresImage"
            />
            <img v-else :src="$oss('common/default_device.png')" />
          </div>
          <div class="flex-1 good-detail">
            <div><label>名称：</label>{{ good.productmallWaresName }}</div>
            <div>
              <label>品牌：</label><span>{{ good.productmallWaresBrand }}</span>
            </div>
            <div>
              <label>规格：</label><span>{{ good.waresRankName }}</span>
              <label class="good-numLabel">数量：</label>
              <span>x{{ good.quantity }}</span>
            </div>
            <div>
              <label>单价：</label>
              <span>￥{{ good.discountPrice }}</span>
              <span class="good-discountPrice" v-if="+good.isDiscount"
                >￥{{ good.price }}</span
              >
              <label class="good-xjLabel">小计：</label>
              <span>￥{{ good.quantity * +good.discountPrice }}</span>
            </div>
          </div>
        </div>
        <div v-if="+orderStatus == 7" class="good-eva">
          <el-button size="mini" type="primary" @click="$emit('evalute', good)"
            >去评价</el-button
          >
        </div>
      </div>
    </div>
    <div class="flex flex-center flex-middle good-totalWrapper">
      <div>
        <div class="good-totalLabel">合计:</div>
        <div class="good-total">￥{{ totalMoney }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    goods: {
      type: Array,
      default: () => [],
    },
    labName: {
      type: String,
      default: "",
    },
    totalMoney: {
      type: [String, Number],
      default: null,
    },
    orderStatus: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variable";
.labName {
  padding: 10px;
  border-bottom: 1px dashed $border-color;
}
.good {
  border-bottom: 1px solid $border-color;
  &:last-child {
    border-bottom: none;
  }
  padding: 10px 15px 0 0;
  &-cover {
    width: 100px;
    height: 100px;
    margin-left: 10px;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-detail {
    > div {
      padding-left: 10px;
      margin-bottom: 10px;
      > label {
        color: #909399;
      }
    }
  }
  &-eva {
    padding-left: 10px;
    padding-bottom: 10px;
    // text-align: right;
  }
  &-discountPrice {
    text-decoration: line-through;
    font-size: 0.85rem;
    color: $font-color-gray;
    margin-left: 2px;
  }
  &-numLabel {
    margin-left: 10px;
  }
  &-xjLabel {
    margin-left: 15px;
  }
  &-totalWrapper {
    border-left: 1px solid $border-color;
    padding: 0 10px;
  }
  &-totalLabel {
    text-align: center;
    font-weight: bold;
  }
  &-total {
    color: $price-color;
  }
}
</style>
