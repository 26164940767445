<template>
  <div class="news">
    <div class="news-inner flex flex-row">
      <Industry />
      <!-- 新闻模块 -->
      <div class="news-block flex-1" v-loading="typeLoading">
        <!-- 新闻分类-->
        <NewsType
          :data="newsTypes"
          v-model="typeIndex"
          @typeChange="typeChange"
        />
        <!--分类下的新闻列表-->
        <div class="flex flex-row" v-loading="newsLoading">
          <div class="flex-1 list">
            <div
              v-for="(list, index) in newsList[typeIndex]"
              :key="index"
              class="flex flex-between list-item"
            >
              <div class="ellipsis list-title pointer" @click="toDetail(list)">
                {{ list.articleTitle }}
              </div>
              <div>{{ list.publishTime }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 新闻轮播图-->
      <Carousel />
    </div>
  </div>
</template>
<script>
import {
  ARTICLE_TYPE,
  // ARTICLE_TYPE_XWTG,
  // ARTICLE_TYPE_XWZX,
  // ARTICLE_TYPE_ZHXX,
  // ARTICLE_TYPE_ZCFG,
} from "@/constant/dict";
import { queryNewsList } from "@/api/article.js";
import { parseTime } from "@/utils/index.js";
import NewsType from "./NewHead.vue";
import Carousel from "./Carousel.vue";
import Industry from "./Industry.vue";
export default {
  name: "modular_news",
  components: {
    NewsType,
    Carousel,
    Industry,
  },
  data() {
    return {
      typeIndex: 0,
      typeLoading: false,
      newsLoading: false,
      newsTypes: [],
      newsList: { 0: [], 1: [], 2: [], 3: [], 4: [] },
    };
  },
  created() {
    this.getDict();
  },
  methods: {
    toDetail(data) {
      this.$jump.push({ name: "newsDetail", query: { id: data.articleId } });
    },
    // 获取数据字典
    async getDict() {
      this.typeLoading = true;
      const data = await this.$store
        .dispatch("getDictData", ARTICLE_TYPE)
        .finally(() => {
          this.typeLoading = false;
        });
      // 文章类型（1-新闻资讯;2-展会信息;3-政策法规;4-规格介绍;5-帮助文档;6-APP新闻资讯;7-检测助手;
      // 8-业务助手;9-检测标准;10-检测方法;11-资质要求;12-平台快讯 13）
      // const types = [ARTICLE_TYPE_XWTG, ARTICLE_TYPE_XWZX, ARTICLE_TYPE_ZCFG];
      const result = data.slice(0, 3);
      this.newsTypes = result;
      this.getNewsList();
    },
    async getNewsList() {
      const news = this.newsList[this.typeIndex];
      if (news && news.length) return;
      this.newsLoading = true;
      const { list } = await this.$axios
        .post(queryNewsList, {
          articleType: this.newsTypes[this.typeIndex].dictValue,
          pageIndex: 1,
          pageSize: 5,
        })
        .finally(() => {
          this.newsLoading = false;
        });
      list.forEach((item) => {
        Object.assign(item, {
          publishTime: parseTime(item.publishTime, "{m}-{d}"),
        });
      });
      this.newsList[this.typeIndex] = list;
    },
    typeChange() {
      this.getNewsList();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
@import "@/style/common";
.news {
  margin-bottom: 16px;
  &-inner {
    width: $max-width;
    margin: 0 auto;
  }
  &-block {
    height: 276px;
    background: white;
  }
}

.list {
  margin-left: 18px;
  &-item {
    margin-bottom: 14px;
    padding-left: 23px;
    padding-right: 21px;
    font-size: 16px;
    color: #666666;
    position: relative;
    &::before {
      position: absolute;
      left: 0;
      top: 10px;
      content: "";
      display: inline-block;
      width: 5px;
      height: 5px;
      background: #adadad;
    }
  }
  &-title {
    max-width: 440px;
  }
}
</style>
