<template>
  <div class="list">
    <div v-for="(item, iIndex) in items" :key="iIndex">
      <div class="list-title">
        样品组{{ String.fromCharCode(iIndex + 65) }}：
      </div>
      <div><label>样品名称：</label>{{ item.sampleName }}</div>
      <div><label>样品数量：</label>{{ item.sampleQuantity }}</div>
      <div>检测项目：</div>
      <div
        v-for="(list, lIndex) in item.rareCheckmallSampleFirstRankExs"
        :key="lIndex"
        class="list-mall"
      >
        <div>{{ list.checkmallFirstRankName }}</div>
        <div
          v-for="(last, index) in list.rareCheckmallFirstSecondRanks"
          :key="index"
          class="flex list-rank flex-between"
        >
          <div>{{ last.checkmallSecondRankName }}</div>
          <div class="list-price">￥{{ last.price }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MoneyBarList",
  props: {
    items: {
      type: Array,
      default: () => [
        {
          name: "氨基酸的叫法奥斯卡来得及按时",
          num: 2,
          checkItem: [
            {
              checkmallFirstRankName: "热重分析仪1",
              checkmallSecondRankList: [
                {
                  checkmallFirstRankId: "20200727000100000005",
                  checkmallSecondRankName: "DTG1",
                  price: "41000",
                },
                {
                  checkmallFirstRankId: "20200727000100000006",
                  checkmallSecondRankName: "DTG阿发送到发",
                  price: "41000",
                },
              ],
            },
            {
              checkmallFirstRankName: "热重分析仪2",
              checkmallSecondRankList: [
                {
                  checkmallFirstRankId: "20200727000100000005",
                  checkmallSecondRankName: "DTG1",
                  price: "41000",
                },
                {
                  checkmallFirstRankId: "20200727000100000006",
                  checkmallSecondRankName: "DTG阿发送到发",
                  price: "41000",
                },
              ],
            },
          ],
        },
        {
          name: "阿萨德股份吉安市",
          num: 2,
          checkItem: [
            {
              checkmallFirstRankName: "2mm³",
              checkmallSecondRankList: [
                {
                  checkmallFirstRankId: "20200727000100000008",
                  checkmallSecondRankName: "2mm³",
                  price: "200",
                },
              ],
            },
          ],
        },
      ],
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.list {
  position: absolute;
  right: 0;
  bottom: 58px;
  min-height: 50px;
  line-height: initial;
  border: 1px solid $theme-color;
  width: 500px;
  padding: 10px 10px 0 10px;
  overflow: scroll;
  background: white;
  z-index: 10;
  > div {
    border-bottom: 1px dashed $theme-color;
    padding-bottom: 5px;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
  }
  &-title {
    color: $theme-color;
    font-size: 1.14rem;
    margin-bottom: 5px;
  }
  &-mall {
    padding-left: 15px;
  }
  &-rank {
    padding-left: 15px;
  }
  &-price {
    color: $theme-color;
  }
}
</style>
