<template>
  <div class="coupons">
    <div class="coupons-title">领取优惠券</div>
    <div class="flex flex-row flex-wrap coupons-items">
      <div
        v-for="(item, index) in data"
        :key="index"
        class="coupons-item"
        :class="{ isRight: (index + 1) % 2 === 0 }"
      >
        <Coupon :data="item" />
      </div>
    </div>
  </div>
</template>
<script>
import Coupon from "@/components/Coupon/index";
export default {
  components: {
    Coupon,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.coupons {
  &-title {
    font-size: 24px;
    text-align: center;
    padding-top: 20px;
    margin-bottom: 20px;
  }
  &-items {
    padding-left: 160px;
  }
  &-item {
    width: 400px;
    margin-bottom: 30px;
    &.isRight {
      margin-left: 30px;
    }
  }
}
</style>
