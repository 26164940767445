<template>
  <div>
    <HeadBar /><!--顶部bar-->
    <!--顶部logo、检索-->
    <HeadNav /><!-- 顶部导航-->

    <div class="lists">
      <el-carousel
        :interval="40000"
        arrow="always"
        height="300px"
        class="swipper"
      >
        <el-carousel-item v-for="(item, index) in banners" :key="index">
          <div class="swipper-banner">
            <img :src="item.bannerPath" @click="bannerClick(item)" />
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="lists-inner">
        <div class="lists-title">优惠活动</div>
        <div class="acts flex flex-row">
          <div
            class="acts-item pointer"
            v-for="(item, index) in lists"
            :key="index"
            :class="{ isRight: (index + 1) % 3 === 0 }"
            @click="toDetail(item)"
          >
            <div>
              <img :src="item.discountActivityImg" class="acts-cover" />
            </div>
            <div class="acts-title ellipsis">
              {{ item.discountActivityTitle }}
            </div>
            <div class="acts-time">
              <span>活动时间 </span>
              <span>{{ item.discountActivityStartTime | transformTime }}</span>
              <span> 至 </span>
              <span>{{ item.discountActivityEndTime | transformTime }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 分页-->
      <Pagination
        :total="totalItem"
        :page="page"
        @pageIndexChange="handlePageChange"
      />
    </div>

    <FootBar class="footer" />
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index";
import { homeBanner } from "@/api/home";
import { discountActs } from "@/api/activity";
import { parseTime } from "@/utils/index";
export default {
  filters: {
    transformTime(val) {
      return parseTime(val, "{y}/{m}/{d} {h}:{i}");
    },
  },
  components: {
    Pagination,
  },
  data() {
    return {
      banners: [],
      totalItem: 0,
      page: {
        pageIndex: 1,
        pageSize: 10,
      },
      lists: [],
    };
  },
  created() {
    // 获取宣传banner
    this.getBanners();
    // 获取优惠活动列表
    this.getList();
  },
  methods: {
    // 获取banner
    async getBanners() {
      const res = await this.$axios.get(homeBanner, { bannerType: "7" });
      this.banners = res;
    },
    // banner点击
    bannerClick({ bannerLink }) {
      if (bannerLink) {
        window.open(bannerLink);
      }
    },
    // 分页处理
    handlePageChange(index) {
      this.page.pageIndex = index;
      this.getList();
    },
    // 获取活动列表
    async getList() {
      const { totalItem, list } = await this.$axios.post(discountActs, {
        ...this.page,
      });
      this.totalItem = totalItem;
      this.lists = list;
    },
    // 点击活动，去详情页
    toDetail(data) {
      this.$jump.push({
        name: "discountActDetail",
        query: { id: data.discountActivityId },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.lists {
  background: $home-bg;
  padding-bottom: 30px;
  &-inner {
    min-height: 400px;
    width: $max-width;
    margin: 0 auto;
  }
  &-title {
    font-size: 20px;
    color: #4482ff;
    margin-bottom: 17px;
  }
}
.swipper {
  margin-bottom: 36px;
  &-banner {
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.acts {
  &-item {
    width: 392px;
    background: #ffffff;
    border-radius: 3px;
    padding: 20px;
    margin-right: 22px;
    &.isRight {
      margin-right: 0;
    }
  }
  &-cover {
    width: 100%;
    height: 214px;
    margin-bottom: 20px;
  }
  &-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  &-time {
    font-size: 15px;
    color: #999999;
  }
}
</style>
