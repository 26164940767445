<template>
  <div class="super">
    <HeadBar class="headBar" /><!--顶部bar-->
    <!--顶部logo、检索-->
    <HeadNav class="super-nav" /><!-- 顶部导航-->
    <div class="super-wrap">
      <div class="super-inner">
        <div class="flex flex-row super-top">
          <div class="types"><Types /></div>
          <div class="flex-1"><Banner /></div>
        </div>
        <div><SuperShop /></div>
        <div>
          <DiscountGood />
        </div>
      </div>
    </div>
    <FootBar class="footerBar" />
  </div>
</template>
<script>
import Types from "./components/Types.vue";
import Banner from "./components/Bnnner.vue";
import SuperShop from "./components/SuperShop.vue";
import DiscountGood from "./components/DiscountGood.vue";
export default {
  components: {
    Types,
    Banner,
    SuperShop,
    DiscountGood,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.super {
  background: #f0f0f8;
  &-wrap {
    padding-top: 30px;

    // min-height: 1600px;
  }

  &-inner {
    width: $max-width;
    margin: 0 auto;
  }
  &-top {
    margin-bottom: 44px;
  }
}
.types {
  width: 240px;
  height: 451px;
  background: rgb(68, 130, 255);
}
.headBar {
  background: white;
}
.footerBar {
  padding-top: 0 !important;
}
</style>
