<template>
  <div class="down">
    <el-tabs v-model="activeName">
      <el-tab-pane label="联盟资料下载" name="first">
        <div class="material" v-loading="loading">
          <template v-if="!material.length">暂无数据</template>
          <template v-else>
            <a
              v-for="(item, index) in material"
              :key="index"
              :href="resourceUrl"
              >{{ item.resourceName }}</a
            >
          </template>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { allianceMaterial } from "@/api/allianceZone";
export default {
  data() {
    return {
      activeName: "first",
      material: [],
      page: {
        pageIndex: 1,
        pageSize: 10,
      },
      loading: false,
    };
  },
  created() {
    this.getMaterial();
  },
  methods: {
    async getMaterial() {
      this.loading = true;
      const { list } = await this.$axios
        .get(allianceMaterial, { leagueId: this.$route.query.id, ...this.page })
        .finally(() => {
          this.loading = false;
        });
      this.material = list || [];
    },
  },
};
</script>
<style lang="scss" scoped>
.down {
  /deep/ .el-tabs__header .el-tabs__item {
    font-size: 1.28rem !important;
    font-weight: bold;
  }
}
.material {
  min-height: 150px;
}
</style>
