<template>
  <div class="order">
    <el-form :inline="true" :model="form" class="qForm" :size="queryFormSize">
      <el-form-item label="视频名称">
        <el-input
          v-model.trim="form.courseName"
          placeholder="请输入视频名称"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryBtnClick">查询</el-button>
      </el-form-item>
    </el-form>
    <div v-loading="tableLoading" class="list-wrapper">
      <div v-for="(item, index) in lists" :key="index" class="list-item">
        <div class="flex flex-between list-header">
          <div>
            <span class="list-orderNo">订单编号:{{ item.orderNo }}</span>
            <span>创建时间:{{ item.createTime }}</span>
          </div>
          <div>订单状态：{{ orderStatus[+item.orderStatus] }}</div>
        </div>
        <div class="flex flex-row list-con">
          <div class="flex flex-1 list-desc">
            <div class="list-cover">
              <img :src="item.courseLogo" v-if="item.courseLogo" />
              <img :src="$oss('common/default_device.png')" v-else />
            </div>
            <div class="flex-1">{{ item.courseName }}</div>
          </div>
          <div class="list-price">￥{{ item.pricePaid }}</div>
          <div class="list-btns">
            <el-button
              type="danger"
              plain
              size="mini"
              v-if="item.orderStatus === '1'"
              >去付款</el-button
            >
            <el-button
              type="success"
              plain
              size="mini"
              v-else-if="item.orderStatus === '2'"
              >去观看</el-button
            >
            <!-- <el-button
              type="danger"
              plain
              size="mini"
              v-else-if="item.orderStatus === '4'"
              >删除订单</el-button
            > -->
          </div>
        </div>
      </div>
    </div>
    <!-- 分页-->
    <Pagination
      :total="totalItem"
      :page="page"
      @pageIndexChange="handlePageChange"
    />
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index";
import { QUERY_FORM_SIZE } from "@/constant/size";
import { TRAIN_ORDER_STATUS } from "@/constant/dict";
import { myTrainClass } from "@/api/userCenter";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      queryFormSize: QUERY_FORM_SIZE,
      form: {
        courseName: "",
      },
      lists: [],
      orderStatus: {},
      totalItem: 0,
      page: {
        pageIndex: 1,
        pageSize: 10,
      },
    };
  },
  created() {
    this.getDict();
    this.getList();
  },
  methods: {
    queryBtnClick() {
      this.page.pageIndex = 1;
      this.getList();
    },
    async getList() {
      this.tableLoading = true;
      const { list, totalItem } = await this.$axios
        .post(myTrainClass, {
          ...this.page,
          ...this.form,
        })
        .finally(() => {
          this.tableLoading = false;
        });
      this.lists = list || [];
      this.totalItem = totalItem;
    },
    // 分页请求
    handlePageChange(index) {
      this.page.pageIndex = index;
      this.getList();
    },
    async getDict() {
      const data = await this.$store.dispatch(
        "getDictData",
        TRAIN_ORDER_STATUS
      );
      const map = {};
      data.forEach((item) => {
        map[item.dictValue] = item.dictLabel;
      });
      this.orderStatus = map;
      // console.log(map);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.qForm {
  /deep/.el-form-item {
    margin-bottom: 10px;
  }
}
.list {
  &-wrapper {
    min-height: 300px;
  }
  &-item {
    border: 1px solid $border-color;
    margin-bottom: 10px;
  }
  &-header {
    border-bottom: 1px solid $border-color;
    padding: 10px;
    background: #f8f8f8;
  }
  &-orderNo {
    margin-right: 40px;
  }
  &-cover {
    width: 150px;
    height: 90px;
    margin-right: 20px;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-con {
    > div {
      padding: 20px;
      border-right: 1px solid $border-color;
      &:last-child {
        border-right: none;
      }
    }
  }
  &-price {
    color: $price-color;
  }
  &-btns {
    text-align: center;
  }
}
</style>
