<template>
  <el-collapse class="specs" v-model="activeCollapse">
    <el-collapse-item v-for="(item, index) in info" :key="index" :name="index">
      <template slot="title">
        <div class="specs-head flex flex-between">
          <div>{{ item.checkmallFirstRankName }}</div>
          <div class="specs-head-price"></div>
        </div>
      </template>
      <div class="specs-table">
        <el-table
          :data="item.secondRank || []"
          size="mini"
          border
          :highlight-current-row="false"
        >
          <el-table-column prop="checkmallSecondRankName" label="项目名称">
          </el-table-column>
          <el-table-column prop="serviceCycle" label="周期(天)">
          </el-table-column>
          <el-table-column label="计价(元)/单位">
            <template slot-scope="scope">
              <span>
                <span v-if="scope.row.face || !scope.row.discountedPrice"
                  >面议</span
                >
                <span v-else>
                  {{ scope.row.discountedPrice }}/{{
                    unitMap[+scope.row.serviceUnitType]
                  }}
                </span>
              </span>
              <span v-if="scope.row.discountStatus < 10" class="specs-dis">
                <span v-if="scope.row.face || !scope.row.discountedPrice"
                  >面议</span
                >
                <span v-else
                  >{{ scope.row.discountedPrice }}/{{
                    unitMap[+scope.row.serviceUnitType]
                  }}</span
                >
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-collapse-item>
  </el-collapse>
</template>
<script>
export default {
  name: "specs",

  props: {
    info: {
      type: Array,
      default: () => [],
    },
    unitMap: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      activeCollapse: 0,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.specs {
  /deep/ .el-collapse-item__header {
    height: 40px;
    line-height: 40px;
  }
  &-head {
    width: 100%;
    padding-right: 10px;
    font-size: 0.85rem;
    &-price {
      color: $theme-color;
    }
  }
  &-table {
    /deep/ .el-table__body tr:hover > td {
      background-color: white;
    }
  }
  &-dis {
    text-decoration: line-through;
    margin-left: 10px;
    color: #b9b9b9;
  }
}
</style>
