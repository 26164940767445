<template>
  <div>
    <div v-if="[2, 3].includes(active)">
      {{ orderStatusMap[active] }}
    </div>
    <el-steps :active="stepActive" class="step" v-else>
      <el-step title="待付款" icon="el-icon-tickets"></el-step>
      <el-step title="待发货" icon="el-icon-present"></el-step>
      <el-step title="待收货" icon="el-icon-picture"></el-step>
      <el-step title="已完成" icon="el-icon-s-claim"></el-step>
    </el-steps>
  </div>
</template>
<script>
const stepMap = {
  1: 1,
  4: 2,
  6: 3,
  7: 4,
};
export default {
  props: {
    active: {
      type: Number,
      default: 1,
    },
    orderStatusMap: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    stepActive() {
      return stepMap[this.active];
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.step {
  /deep/ .el-step__title {
    font-size: 1rem !important;
  }
}
</style>
