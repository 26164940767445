<template>
  <el-table :data="data" border :size="tableSize">
    <el-table-column label="图片" width="140">
      <template slot-scope="{ row }">
        <div class="data-imgWrapper">
          <img :src="row.equipmentImage" class="data-img" />
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="equipmentName" label="设备名称"> </el-table-column>
    <el-table-column prop="equipmentModel" label="设备型号"> </el-table-column>
    <el-table-column prop="equipmentBrand" label="设备品牌"> </el-table-column>
    <el-table-column prop="equipmentCode" label="设备编码"> </el-table-column>
    <el-table-column label="状态">
      <template slot-scope="{ row }">
        <div>{{ statusMap[+row.equipmentStatus] }}</div>
      </template>
    </el-table-column>
    <el-table-column label="操作" width="130">
      <template slot-scope="scope">
        <!-- 已上架的不能编辑、删除-->
        <label v-if="scope.row.equipmentStatus == DEVICE_STATUS_UP">
          <span
            @click="downOrUpBtn(scope.row, DEVICE_STATUS_DOWN)"
            class="data-btnEdit pointer margin-right-10"
            >下架</span
          >
          <span @click="viewBtn(scope.row)" class="data-btnEdit pointer"
            >查看</span
          >
        </label>
        <label v-else>
          <span
            @click="updateBtn(scope.row)"
            class="data-btnEdit pointer margin-left-10 margin-right-10"
            >编辑</span
          >
          <span @click="delBtn(scope.row)" class="data-btnDel pointer"
            >删除</span
          >
        </label>
        <span
          v-if="
            ![DEVICE_STATUS_UP, DEVICE_STATUS_CHECK].includes(
              scope.row.equipmentStatus
            )
          "
          @click="downOrUpBtn(scope.row, DEVICE_STATUS_UP)"
          class="data-btnDel margin-left-10 pointer"
          >上架</span
        >
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
import { TABLE_SIZE } from "@/constant/size";
import { saveDevice } from "@/api/userCenter";
import {
  DEVICE_STATUS_DOWN,
  DEVICE_STATUS_UP,
  DEVICE_STATUS_CHECK,
} from "@/constant/dict.js";
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    statusMap: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      DEVICE_STATUS_DOWN,
      DEVICE_STATUS_UP,
      DEVICE_STATUS_CHECK,
      tableSize: TABLE_SIZE,
    };
  },
  methods: {
    updateBtn(data) {
      this.$emit("updateBtn", data.equipmentId);
    },
    delBtn(data) {
      this.$emit("delBtn", data.equipmentId);
    },
    viewBtn(data) {
      this.$emit("viewBtn", data.equipmentId);
    },
    async downOrUpBtn(data, flag) {
      const { equipmentId } = data;
      const tip = flag === DEVICE_STATUS_DOWN ? "下架" : "上架";
      await this.$confirm(`确定${tip}吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      await this.$axios
        .post(saveDevice, {
          equipmentId,
          equipmentStatus: flag,
        })
        .finally(() => {
          this.loading = false;
        });
      this.$emit("refresh");
      this.$message.success(`${tip}成功`);
    },
  },
};
</script>
<style lang="scss" scoped>
.data {
  &-imgWrapper {
    text-align: center;
  }
  &-img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
  &-btnEdit {
    color: #409eff;
  }
  &-btnDel {
    color: #f56c6c;
  }
}
.margin-left-10 {
  margin-left: 10px;
}
.margin-right-10 {
  margin-right: 10px;
}
</style>
