export default {
  abilityName: [
    {
      required: true,
      message: "检测能力名称不能为空",
      trigger: ["blur", "change"],
    },
  ],
  //   abilityImage: [
  //     {
  //       required: true,
  //       message: "检测能力展示图不能为空",
  //       trigger: ["blur", "change"],
  //     },
  //   ],
  laboratoryAbilityTypeId: [
    {
      required: true,
      message: "检测能力名称不能为空",
      trigger: ["change"],
    },
  ],
  checkAccord: [
    {
      required: true,
      message: "检测依据不能为空",
      trigger: ["blur", "change"],
    },
  ],
  checkMethod: [
    {
      required: true,
      message: "检测方法不能为空",
      trigger: ["blur", "change"],
    },
  ],
  checkSpeQuota: [
    {
      required: true,
      message: "具体检测指标不能为空",
      trigger: ["blur", "change"],
    },
  ],
};
