<template>
  <div class="trust" v-loading="detailLoading">
    <div class="trust-head flex flex-row flex-between">
      <div class="trust-name">{{ detail.checkmallWaresName }}</div>
      <div>
        <!-- <el-button type="primary" plain size="mini">打印委托单</el-button> -->
      </div>
    </div>
    <div>
      <el-collapse v-model="activeNames">
        <el-collapse-item title="订单信息" name="2">
          <Order :data="detail" />
        </el-collapse-item>
        <el-collapse-item title="样品信息" name="3">
          <Sample :data="detail.rareCheckmallOrderSamplesEx" />
        </el-collapse-item>
        <el-collapse-item title="接收实验室信息" name="4">
          <LabInfo :data="labInfo" v-loading="labLoading" />
        </el-collapse-item>
        <el-collapse-item title="委托用户相关信息" name="5">
          <UserInfo :data="detail" />
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>
<script>
import { deedDetail } from "@/api/userCenter";
import { labInfo } from "@/api/mall";
import Order from "./Order";
import Sample from "./Sample";
import LabInfo from "./LabInfo";
import UserInfo from "./UserInfo";
export default {
  components: {
    Order,
    Sample,
    LabInfo,
    UserInfo,
  },
  props: {
    deedId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      activeNames: ["2", "3", "4", "5"],
      detail: {},
      labInfo: {},
      detailLoading: false,
      labLoading: false,
    };
  },
  async created() {
    await this.getDetail();
    this.getLabInfo();
  },
  methods: {
    async getDetail() {
      this.detailLoading = true;
      const data = await this.$axios
        .get(deedDetail, {
          orderId: this.deedId,
        })
        .finally(() => {
          this.detailLoading = false;
        });
      this.detail = data;
    },
    async getLabInfo() {
      const { checkmallBusinessId } = this.detail;
      if (!checkmallBusinessId) return;
      this.labLoading = true;
      const data = await this.$axios
        .get(labInfo, {
          businessId: checkmallBusinessId,
        })
        .finally(() => {
          this.labLoading = false;
        });
      this.labInfo = data;
    },
  },
};
</script>
<style lang="scss" scoped>
.trust {
  padding: 0 20px;
  /deep/ .el-collapse-item__content {
    padding-bottom: 0;
  }
  /deep/ .el-collapse-item__header {
    font-weight: bold;
  }
  &-head {
    margin-bottom: 15px;
  }
  &-name {
    font-weight: bold;
  }
  &-list {
    margin-bottom: 10px;
    > label {
      color: #909399;
    }
  }
}
</style>
