<template>
  <el-form :inline="true" :model="form" class="qForm" :size="queryFormSize">
    <el-form-item label="商品名称">
      <el-input
        v-model="form.productmallWaresName"
        placeholder="请输入商品名称"
        class="title"
      ></el-input>
    </el-form-item>
    <el-form-item label="下单时间">
      <el-date-picker
        v-model="form.time"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item label="订单状态">
      <el-select
        v-model="form.productmallOrderStatus"
        placeholder="请选择状态"
        class="demandStatus"
        clearable
      >
        <el-option
          :label="item.dictLabel"
          :value="item.dictValue"
          v-for="(item, index) in orderStatus"
          :key="index"
        ></el-option>
      </el-select>
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="onSubmit" :loading="loading"
        >查询</el-button
      >
    </el-form-item>
  </el-form>
</template>
<script>
import { QUERY_FORM_SIZE } from "@/constant/size";
export default {
  props: {
    orderStatus: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      queryFormSize: QUERY_FORM_SIZE,
      form: {
        productmallWaresName: "",
        productmallOrderStatus: null,
        time: [],
      },
    };
  },
  created() {},
  methods: {
    onSubmit() {
      this.$emit("queryBtnClick", this.form);
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  width: 215px;
}
.demandStatus {
  width: 100px;
}
.qForm {
  /deep/.el-form-item {
    margin-bottom: 10px;
  }
  /deep/ .el-date-editor {
    width: 300px;
  }
}
</style>
