<template>
  <div>
    <div class="flex flex-row">
      <div class="good-left">
        <div class="good-cover" v-if="info.productmallWaresImage[showImgIndex]">
          <pic-zoom :url="info.productmallWaresImage[showImgIndex]" :scale="3">
          </pic-zoom>
        </div>
      </div>
      <div class="flex flex-column flex-between">
        <div>
          <div class="good-name">{{ info.productmallWaresName }}</div>
          <div class="good-label good-list">
            {{ info.productmallWaresSubTitle }}
          </div>
          <div class="flex flex-row">
            <div class="good-list good-marginRight">
              <label>品牌：</label>
              <span>{{ info.productmallWaresBrand || "无" }}</span>
            </div>
            <div v-if="info.isFreeMail" class="good-list">
              <label>邮费：</label>
              <span class="good-mailFree">包邮</span>
            </div>
          </div>
          <div
            class="flex flex-row flex-wrap"
            v-if="info.waresAttributeValues && info.waresAttributeValues.length"
          >
            <div
              v-for="(item, index) in info.waresAttributeValues"
              :key="index"
              class="good-marginRight"
            >
              <div v-if="item.attributeValue" class="good-list">
                <label>{{ item.attributeName }}：</label>
                <span>{{ info.attributeValue || "无" }}</span>
              </div>
            </div>
          </div>
          <div class="good-list">
            <label>规格：</label>
            <span
              v-for="(item, index) in info.waresRanks"
              :key="index"
              class="good-option"
              :class="{
                active:
                  currentOption.productmallWaresRankId ===
                  item.productmallWaresRankId,
              }"
              @click="optionClick(item)"
            >
              {{ item.waresRankName }}
            </span>
          </div>
          <div class="good-list">
            <label>价格：</label>
            <span class="good-price">
              <span v-if="!currentOption.discountPrice">面议</span>
              <template v-else>
                <span>￥</span>
                <label>{{ currentOption.discountPrice }}</label>
              </template>
            </span>
            <span
              class="good-discount"
              v-if="
                currentOption.discountPrice &&
                currentOption.discountStatus &&
                +currentOption.discountStatus < 10
              "
            >
              <span>￥</span><label>{{ currentOption.price }}</label>
            </span>
          </div>
          <div class="good-list">
            <label>数量：</label>
            <el-input-number
              v-model="num"
              controls-position="right"
              size="mini"
              :min="1"
              :max="10000"
            ></el-input-number>
          </div>
        </div>
        <div class="good-btns flex flex-row">
          <div class="good-btn1" @click="toPay">立即购买</div>
          <div class="good-btn2" @click="addCart">加入购物车</div>
          <div class="good-btn2" @click="toContact">联系客服</div>
        </div>
      </div>
    </div>
    <div class="good-imgs flex">
      <div
        v-for="(item, index) in info.productmallWaresImage"
        :key="index"
        @mouseover="mouseoverImg(index)"
        :class="{ active: showImgIndex === index }"
      >
        <img :src="item" />
      </div>
    </div>
  </div>
</template>
<script>
import PicZoom from "vue-piczoom";
import { SET_SHOOSEGOODS } from "@/store/types";
import { mapGetters } from "vuex";
import { sleep } from "@/utils/index";
import { addGoodToCart } from "@/api/mall";
import { getBusinessCustomerServer } from "@/api/userCenter";
export default {
  components: {
    PicZoom,
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showImgIndex: 0,
      currentOption: {},
      num: 1,
    };
  },
  computed: {
    ...mapGetters(["token", "userBaseInfo"]),
    // waresAttribute(){
    //   const {waresAttributeValues} = this.info
    // },
  },
  watch: {
    "info.productmallWaresId": function () {
      this.optionClick(this.info.waresRanks[0]);
    },
  },
  methods: {
    mouseoverImg(index) {
      this.showImgIndex = index;
    },
    optionClick(item) {
      this.currentOption = item;
    },
    async toPay() {
      if (!this.token) {
        this.$store.dispatch("login");
        return;
      }
      const { productmallWaresRankId } = this.currentOption;
      const store = {
        purchaseType: "0", // 购买方式（0-直接购买；1-购物车购买）
        waresRanks: [{ productmallWaresRankId, quantity: this.num }],
      };
      sessionStorage.setItem(SET_SHOOSEGOODS, JSON.stringify(store));
      await sleep(200);
      this.$jump.push({
        name: "goodsOrder",
      });
    },
    async addCart() {
      if (!this.token) {
        this.$store.dispatch("login");
        return;
      }

      const { productmallWaresRankId } = this.currentOption;
      if (!productmallWaresRankId) {
        this.$message.warning("请先选择商品规格");
        return;
      }
      this.$emit("addCartBuryPoin");
      const { productmallBusinessId, productmallWaresId } = this.info;
      const params = {
        productmallBusinessId,
        productmallWaresId,
        productmallWaresRankId,
        quantity: this.num,
      };
      await this.$axios.post(addGoodToCart, params);
      this.$message.success("添加成功");
    },
    // 联系客服
    toContact() {
      if (!this.token) {
        this.$store.dispatch("login");
        return;
      }
      this.getCustomer();
    },
    async getCustomer() {
      const { productmallBusinessId } = this.info;
      const res = await this.$axios.post(getBusinessCustomerServer, {
        businessId: productmallBusinessId,
        businessType: "2",
      });
      if (res) {
        if (res.userId === this.userBaseInfo.userId) {
          this.$message.error("不能咨询自己");
          return;
        }
        this.$emit("openConsult", res);
      } else {
        this.$message.info("该商城暂时还没有客服");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";

@mixin imgCommon() {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
$hight-color: #f60;
.good {
  &-left {
    margin-right: 20px;
  }
  &-cover {
    width: 450px;
    height: 350px;
    border: 1px solid $border-color;
    border-radius: $border-radius;
  }
  &-list {
    margin-bottom: 20px;
    // font-size: 0.85rem;
    > label {
      color: #999;
    }
  }
  &-marginRight {
    margin-right: 30px;
  }
  &-name {
    font-size: 1.42rem;
    font-weight: 700;
    margin-bottom: 5px;
  }
  &-label {
    color: $theme-color;
  }
  &-mailFree {
    color: $hight-color !important;
  }
  &-priceFlag {
    font-size: 0.85rem;
  }
  &-option {
    border: 1px solid $border-color;
    padding: 2px 10px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: $border-radius;

    &:hover {
      border: 1px solid #afafaf;
    }
    &.active {
      border: 1px solid $theme-color !important;
    }
  }
  &-price {
    color: $hight-color;
    > label {
      font-size: 1.21rem;
      font-weight: bold;
    }
  }
  &-discount {
    font-size: 0.85rem;
    margin-left: 10px;
    text-decoration: line-through;
  }
  &-imgs {
    padding-top: 10px;
    margin-bottom: 20px;
    > div {
      width: 50px;
      height: 40px;
      margin-right: 10px;
      border: 1px solid $border-color;
      padding: 3px;
      cursor: pointer;
      border-radius: $border-radius;
      &:hover {
        border: 1px solid #afafaf;
      }
      &.active {
        border: 1px solid #afafaf;
      }
      > img {
        @include imgCommon;
      }
    }
  }
  &-btns {
    > div {
      padding: 6px 20px;
      border-radius: $border-radius;
      cursor: pointer;
    }
  }
  &-btn1 {
    border: 1px solid $theme-color;
    color: #ffffff;
    background-color: $theme-color;
    margin-right: 20px;
  }
  &-btn2 {
    color: $theme-color;
    background: #ebf3fb;
    border: 1px solid #b0cdee;
    margin-right: 20px;
  }
}
</style>
