export default [
  {
    title: "商城管理",
    auth: "mallManage",
    child: [
      // {
      //   title: "发票管理",
      //   pathName: "mallBillManage",
      //   auth: "mallBillManage",
      //   parentAuth: "mallManage",
      // },
      {
        title: "商城委托单管理",
        pathName: "strictMallDeed",
        auth: "mallDeedManage",
        parentAuth: "mallManage",
      },
      {
        title: "商城服务管理",
        pathName: "strictServiceManage",
        auth: "mallServiceManage",
        parentAuth: "mallManage",
      },
      // {
      //   title: "服务商城标准管理",
      //   pathName: "standardManage",
      //   auth: "mallStandardManage",
      //   parentAuth: "mallManage",
      // },
      {
        title: "商城订单管理",
        pathName: "superMallOrderManage",
        auth: "mallOrderdManage",
        parentAuth: "mallManage",
      },
      {
        title: "商城商品管理",
        pathName: "superMallGoodsManage",
        auth: "mallGoodsManage",
        parentAuth: "mallManage",
      },
    ],
  },
  {
    title: "实验室管理",
    auth: "labManage",
    child: [
      {
        title: "实验室账号管理",
        pathName: "labAccountManage",
        auth: "labAccountManage",
        parentAuth: "labManage",
      },
      {
        title: "实验室信息管理",
        pathName: "labInfoManage",
        auth: "labInfoManage",
        parentAuth: "labManage",
      },
      {
        title: "实验室能力维护",
        pathName: "labAbilityManage",
        auth: "labAbilityManage",
        parentAuth: "labManage",
      },
      {
        title: "实验室设备维护",
        pathName: "labDeviceManage",
        auth: "labDeviceManage",
        parentAuth: "labManage",
      },
    ],
  },
  {
    title: "企业中心",
    auth: "enterpriseCenter",
    child: [
      {
        title: "企业信息管理",
        pathName: "enterpriseInfo",
        auth: "enterpriseInfo",
        parentAuth: "enterpriseCenter",
      },
      {
        title: "企业账号管理",
        pathName: "enterpriseAccount",
        auth: "enterpriseAccount",
        parentAuth: "enterpriseCenter",
      },
    ],
  },
];
