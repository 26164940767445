<template>
  <div class="demand-item">
    <div class="demand-title flex flex-between">
      <div>
        {{ data.demandTitle }}
      </div>
      <div>
        <el-button type="primary" plain size="mini" @click="editBtnClick(data)"
          >修改</el-button
        >
      </div>
    </div>
    <div class="demand-con">
      <div>
        <span>需求状态：</span><span>{{ statusMap[data.demandStatus] }}</span>
      </div>
      <div>
        <span>发布时间：</span><span>{{ data.createTime }}</span>
      </div>
      <div>
        <span>检测项目：</span><span>{{ data.demandProjectName }}</span>
      </div>
      <div>
        <span>样品信息：</span><span>{{ data.demandSampleInfo }}</span>
      </div>
      <div>
        <span>资质要求：</span>
        <span
          v-for="(qua, qIndex) in data.qualificationList"
          :key="qIndex"
          class="demand-qua"
        >
          <img :src="qua.qualificationIco" />
        </span>
      </div>
      <div class="flex">
        <span>附件：</span>
        <div class="demand-image" v-if="data.demandImage">
          <img :src="data.demandImage" />
        </div>
        <span v-else>无</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    statusMap: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  methods: {
    editBtnClick({ demandId }) {
      this.$emit("showDetail", demandId);
      // this.$jump.push({ name: "releaseReq", query: { id: demandId } });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.demand {
  &-item {
    border: 1px solid $border-color;
    margin-bottom: 10px;
  }
  &-con {
    padding: 10px 15px;
    > div {
      margin-bottom: 10px;
    }
  }
  &-title {
    height: 35px;
    line-height: 35px;
    padding: 0 15px;
    background: #f5f5f5;
    border-bottom: 1px solid $border-color;
  }
  &-image {
    width: 160px;
    height: 160px;
    border: 1px solid $border-color;
    > img {
      width: 160px;
      height: 160px;
      object-fit: contain;
    }
  }
  &-qua {
    border: 1px solid $border-color;
    display: inline-block;
    width: 70px;
    height: 35px;
    margin-right: 10px;
    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
