var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cate"},[_c('div',{staticClass:"cate-type flex flex-row"},[_c('div',{staticClass:"cate-label"},[_vm._v("类别")]),_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"cate-item cate-parent flex flex-row flex-wrap pointer"},_vm._l((_vm.parentItem),function(item,index){return _c('div',{key:index,class:{
            active:
              item.laboratoryAbilityTypeId ===
              _vm.currentParent.laboratoryAbilityTypeId,
          },on:{"click":function($event){return _vm.pItemClick(item)}}},[_vm._v(" "+_vm._s(item.laboratoryAbilityTypeName)+" ")])}),0),_c('div',{staticClass:"cate-item cate-child flex flex-row flex-wrap pointer"},_vm._l((_vm.childItem[
            _vm.currentParent.laboratoryAbilityTypeId
          ]),function(item,index){return _c('div',{key:index,class:{
            active:
              item.laboratoryAbilityTypeId ===
              _vm.currentChild.laboratoryAbilityTypeId,
          },on:{"click":function($event){return _vm.cItemClick(item)}}},[_vm._v(" "+_vm._s(item.laboratoryAbilityTypeName)+" ")])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }