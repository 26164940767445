<template>
  <div>
    <HeadBar /><!--顶部bar-->
    <!--顶部logo、检索-->
    <HeadNav /><!-- 顶部导航-->
    <div class="detail flex flex-center">
      <div class="detail-inner">
        <div class="flex">
          <div class="detail-cont flex-1" v-loading="detailLoading">
            <Service
              :info="serveDetail"
              class="detail-more"
              :unitMap="unitMap"
              @openConsult="openConsult"
            />
            <div class="detail-intro">
              <img :src="$oss('deviceDetail/intro.png')" />
            </div>
            <div class="tabDetail">
              <div class="tabDetail-title">
                <label
                  v-for="(item, index) in tabNav"
                  :key="index"
                  :class="{ active: tabNavIndex === index }"
                  @click="tabNavIndex = index"
                  class="pointer"
                >
                  {{ item }}
                </label>
              </div>
              <ServiceDetail
                :info="serveDetail"
                :lab="labDetail"
                v-show="tabNavIndex === 0"
              />
              <EvaluateList v-show="tabNavIndex === 1" mallType="0" />
            </div>

            <div class="detail-intro super">
              <img :src="$oss('deviceDetail/super.png')" />
            </div>
          </div>
          <div>
            <!-- 实验室信息 -->
            <Laboratory :data="labDetail" />
            <!-- 最新服务 -->
            <NewService :goods="newServices" :unitMap="unitMap" />
            <!-- 热门推荐-->
            <HotService :goods="hotServices" :unitMap="unitMap" />
          </div>
        </div>
      </div>
    </div>
    <FootBar />
    <ConsultDialog
      v-model="consultVisible"
      v-if="consultVisible"
      :customer="customer"
    />
  </div>
</template>
<script>
import { deviceDetail, mallList, labInfo } from "@/api/mall";
import Service from "./components/Service";
import Laboratory from "./components/Laboratory";
import ServiceDetail from "./components/ServiceDetail";
import NewService from "./components/NewService";
import HotService from "./components/HotService";
import { SERVICE_UNIT_TYPE } from "@/constant/dict";
import ConsultDialog from "@/components/ConsultDialog/index";
import EvaluateList from "@/components/EvaluateList/index";
export default {
  components: {
    ConsultDialog,
    Service,
    Laboratory,
    ServiceDetail,
    NewService,
    HotService,
    EvaluateList,
  },
  data() {
    return {
      serveDetail: {},
      detailLoading: false,
      newServices: [], // 最新服务
      hotServices: [], // 最热服务
      labDetail: {}, // 商户信息
      unitMap: {},
      consultVisible: false,
      customer: {}, // 客服信息
      tabNav: ["基本信息", "用户评价"],
      tabNavIndex: 0,
    };
  },
  async created() {
    this.getDicts();
    await this.getDetail();
    this.getNewService();
    this.getHotService();
    this.getBusinessInfo(); // 服务方信息
  },
  methods: {
    // 打开咨询客服弹窗
    openConsult(customer) {
      this.customer = customer;
      this.consultVisible = true;
    },
    async getDicts() {
      const units = await this.$store.dispatch(
        "getDictData",
        SERVICE_UNIT_TYPE
      );
      const unitMap = {};
      units.forEach((item) => {
        unitMap[item.dictValue] = item.dictLabel;
      });
      this.unitMap = unitMap;
    },
    async getDetail() {
      this.detailLoading = true;
      const data = await this.$axios
        .get(deviceDetail, {
          id: this.$route.query.id,
        })
        .finally(() => {
          this.detailLoading = false;
        });
      this.serveDetail = data;
    },
    // 服务方信息
    async getBusinessInfo() {
      const data = await this.$axios.get(labInfo, {
        businessId: this.serveDetail.checkmallBusinessId,
      });
      this.labDetail = data;
    },
    // 最新服务
    async getNewService() {
      const { list } = await this.$axios.post(mallList, {
        laboratoryId: this.serveDetail.checkmallBusinessId,
        pageIndex: 1,
        pageSize: 6,
      });
      this.newServices = list;
    },
    // 热门服务
    async getHotService() {
      const { list } = await this.$axios.post(mallList, {
        serviceCategoryIds: [this.serveDetail.serviceCategoryId],
        pageIndex: 1,
        pageSize: 6,
      });
      this.hotServices = list;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.tabDetail {
  border: 1px solid $border-color;
  margin-bottom: 15px;
  &-title {
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid $border-color;
    padding-left: 15px;
    font-size: 20px;
    label {
      margin-right: 70px;
      &.active {
        color: $theme-color;
      }
    }
  }
}
.detail {
  padding-top: 20px;
  &-inner {
    width: $max-width;
  }
  &-cont {
    margin-right: 25px;
  }
  &-more {
    margin-bottom: 20px;
  }
  &-intro {
    // border: 1px solid $border-color;
    border-radius: $border-radius;
    margin-bottom: 20px;
    &.super {
      margin-bottom: 40px;
    }
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
}
</style>
