<template>
  <div>
    <HeadBar /><!--顶部bar-->
    <HeadNav /><!-- 顶部导航-->
    <div class="con">
      <div
        class="con-bg"
        :style="{
          background: `url(${$oss('contactUs/banner.jpg')})`,
          'background-size': '100% 100%',
        }"
      ></div>
      <div class="con-inner con-mapWrapper">
        <Bread />
        <div class="con-map" v-if="center.lng">
          <baidu-map
            class="map"
            :center="center"
            :zoom="18"
            ak="hB0gDuQFvlegcFgiqwiX3kf9"
          >
            <bm-marker
              :position="center"
              :dragging="true"
              animation="BMAP_ANIMATION_BOUNCE"
            >
            </bm-marker>
          </baidu-map>
        </div>
      </div>
      <div class="con-info flex flex-row flex-between flex-wrap">
        <div class="contact flex flex-row">
          <div class="contact-imgWrapper">
            <img src="@/assets/contactUs/tel.png" />
          </div>
          <div class="contact-con">
            <div class="contact-label">联 系 电 话</div>
            <div class="contact-text">
              {{ base.subCenterContactsPhone }}
            </div>
          </div>
        </div>
        <div class="contact flex flex-row">
          <div class="contact-imgWrapper">
            <img src="@/assets/contactUs/address.png" />
          </div>
          <div class="contact-con">
            <div class="contact-label">地&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;址</div>
            <div class="contact-text">{{ base.subCenterAddress }}</div>
          </div>
        </div>
        <div class="contact flex flex-row">
          <div class="contact-imgWrapper">
            <img src="@/assets/contactUs/email.png" />
          </div>
          <div class="contact-con">
            <div class="contact-label">电 子 邮 箱</div>
            <div class="contact-text">{{ base.subCenterEmail }}</div>
          </div>
        </div>
        <div class="contact flex flex-row">
          <div class="contact-imgWrapper">
            <img src="@/assets/contactUs/network.png" />
          </div>
          <div class="contact-con">
            <div class="contact-label">网&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;址</div>
            <div class="contact-text">{{ base.subCenterWebsite }}</div>
          </div>
        </div>
      </div>
    </div>
    <FootBar class="footer" />
  </div>
</template>
<script>
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import BmMarker from "vue-baidu-map/components/overlays/Marker.vue";
// import BmLabel from "vue-baidu-map/components/overlays/Label.vue";
import Bread from "./components/Breads.vue";

import { baseInfo } from "@/api/baseIntroduction";
export default {
  components: {
    BaiduMap,
    Bread,
    BmMarker,
    // BmLabel,
  },
  data() {
    return {
      center: { lng: 0, lat: 0 },
      base: {},
    };
  },
  created() {
    this.getBaseInfo();
  },
  methods: {
    async getBaseInfo() {
      const res = await this.$axios.get(baseInfo);
      this.base = res;
      const { subCenterLatitude, subCenterLongitude } = res;
      Object.assign(this.center, {
        lng: subCenterLongitude,
        lat: subCenterLatitude,
      });
    },
    handler({ BMap, map }) {
      console.log(BMap, map);
      this.zoom = 15;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.con {
  background: $home-bg;
  padding-bottom: 60px;
  &-bg {
    height: 320px;
  }
  &-inner {
    width: $max-width;
    margin: 0 auto;
    margin-top: -68px;
    background: white;
    z-index: 1;
  }
  &-map {
    padding: 0 26px 26px 26px;
  }
  &-mapWrapper {
    margin-bottom: 20px;
  }
  &-info {
    width: $max-width;
    margin: 0 auto;
  }
}
.map {
  width: 100%;
  height: 400px;
}
.contact {
  width: 600px;
  height: 156px;
  background: #ffffff;
  margin-bottom: 20px;
  &-imgWrapper {
    width: 132px;
    text-align: center;
    padding-top: 38px;
    padding-left: 30px;
  }
  &-con {
    padding-top: 40px;
    padding-left: 45px;
  }
  &-label {
    font-size: 20px;
    color: #888888;
    margin-bottom: 10px;
    font-weight: 400;
  }
  &-text {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
  }
}
</style>
