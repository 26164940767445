<template>
  <div class="goods">
    <el-form :inline="true" :model="form" class="qForm" :size="queryFormSize">
      <el-form-item label="商品名称">
        <el-input
          v-model="form.productmallWaresName"
          placeholder="请输入商品名称"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="商品状态">
        <el-select v-model="form.mallWaresStatus" clearable>
          <el-option
            v-for="dict in mallWaresStatusOptions"
            :key="dict.dictValue"
            :label="dict.dictLabel"
            :value="parseInt(dict.dictValue)"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" plain size="mini" @click="queryBtnClick"
          >查询</el-button
        >
        <el-button type="primary" plain size="mini" @click="addBtnClick"
          >新增</el-button
        >
      </el-form-item>
    </el-form>
    <div class="con">
      <el-table
        :data="tableData"
        border
        :size="tableSize"
        v-loading="tableLoading"
      >
        <el-table-column label="商品图片">
          <template slot-scope="scope">
            <img :src="scope.row.productmallWaresImage" class="goods-img" />
          </template>
        </el-table-column>
        <el-table-column prop="productmallWaresName" label="商品名称">
        </el-table-column>
        <el-table-column prop="serviceCategory" label="商品类别">
          <template slot-scope="scope">
            <span>{{ scope.row.serviceCategoryFirstName }}/</span>
            <span>{{ scope.row.serviceCategorySecondName }}/</span>
            <span>{{ scope.row.serviceCategoryThridName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="productmallWaresStock" label="商品库存">
        </el-table-column>
        <el-table-column prop="checkmallWaresSaleCount" label="成交量">
        </el-table-column>
        <el-table-column prop="mallWaresStatus" label="商品状态">
          <template slot-scope="scope">
            {{ statusMap[+scope.row.mallWaresStatus] }}
          </template>
        </el-table-column>
        <el-table-column prop="createTimeStr" label="创建时间">
        </el-table-column>
        <el-table-column label="操作" width="120px">
          <template slot-scope="scope">
            <template v-if="scope.row.mallWaresStatus !== '1'">
              <el-button type="text" size="small" @click="editBtn(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="delBtn(scope.row)"
                class="con-del"
                >删除</el-button
              >
            </template>
            <template v-if="scope.row.mallWaresStatus === '1'">
              <el-button
                type="text"
                size="small"
                @click="shelfBtn(scope.row, '0')"
                >下架</el-button
              >
              <el-button type="text" size="small" @click="viewBtn(scope.row)"
                >查看</el-button
              >
            </template>

            <el-button
              type="text"
              size="small"
              @click="shelfBtn(scope.row, '1')"
              v-if="scope.row.mallWaresStatus === '0'"
              >上架</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页-->
    <Pagination
      :total="totalItem"
      :page="page"
      @pageIndexChange="handlePageChange"
    />
    <el-drawer
      title="商品维护"
      :visible.sync="showDrawer"
      size="950px"
      class="drawer"
      :wrapperClosable="false"
    >
      <GoodsDetail
        :isEdit="drawerIsEdit"
        :curId="curId"
        v-model="showDrawer"
        v-if="showDrawer"
        :businessInfo="businessInfo"
        @saveSuccess="queryBtnClick"
      />
    </el-drawer>
  </div>
</template>
<script>
import GoodsDetail from "./components/GoodsDetail";
import { TABLE_SIZE, QUERY_FORM_SIZE } from "@/constant/size";
import Pagination from "@/components/Pagination/index";
import {
  changeGoodStatus,
  delGood,
  getMallBusinessInfo,
  goodsList,
  getUserLabInfo,
} from "@/api/userCenter";
import { queryCurrentEnt } from "@/api/enterprise";
import { mapGetters } from "vuex";
import { GOODS_SHELF_STATUS } from "@/constant/dict";
export default {
  components: {
    GoodsDetail,
    Pagination,
  },
  data() {
    return {
      queryFormSize: QUERY_FORM_SIZE,
      tableSize: TABLE_SIZE,
      form: {
        productmallWaresName: "",
        mallWaresStatus: "",
      },
      mallWaresStatusOptions: [], // 商品状态下拉数据
      statusMap: {},
      drawerIsEdit: null, // 弹窗是否可编辑
      showDrawer: false, // 是否展示弹窗
      curId: null,
      tableData: [],
      page: {
        pageIndex: 1,
        pageSize: 10,
      },
      totalItem: 0,
      businessInfo: {}, // 商城信息
      tableLoading: false,
      orgInfo: {}, // 机构信息，实验室/企业
    };
  },
  computed: {
    ...mapGetters(["userBaseInfo"]),
  },
  created() {
    this.getDicts();
    this.getBusinessInfo();
    this.getList();
  },
  methods: {
    // 商家信息
    async getBusinessInfo() {
      const { userJurisdictionList } = this.userBaseInfo;
      if (userJurisdictionList && userJurisdictionList.length) {
        const { enterpriseId, laboratoryId } = userJurisdictionList[0];
        if (enterpriseId) {
          const res = await this.$axios.post(queryCurrentEnt);
          this.orgInfo = res;
        } else if (laboratoryId) {
          const res = await this.$axios.get(getUserLabInfo);
          this.orgInfo = res;
        }
        console.log(this.orgInfo);
        const busRes = await this.$axios.get(getMallBusinessInfo, {
          applyOrgId:
            this.orgInfo[enterpriseId ? "enterpriseId" : "laboratoryId"],
        });
        this.businessInfo = busRes;
        console.log(busRes);
      }
    },
    // 获取数据字典数据
    getDicts() {
      this.$store.dispatch("getDictData", GOODS_SHELF_STATUS).then((res) => {
        this.mallWaresStatusOptions = res;
        const statusMap = {};
        res.forEach(({ dictValue, dictLabel }) => {
          statusMap[dictValue] = dictLabel;
        });
        this.statusMap = statusMap;
      });
    },
    queryBtnClick() {
      this.page.pageIndex = 1;
      this.getList();
    },
    // 列表查询
    async getList() {
      this.tableLoading = true;
      const params = {
        ...this.page,
        ...this.form,
      };
      const res = await this.$axios.post(goodsList, params).finally(() => {
        this.tableLoading = false;
      });
      const { list, totalItem } = res || {};
      this.tableData = list || [];
      this.totalItem = totalItem || 0;
    },
    editBtn(row) {
      this.drawerIsEdit = true;
      this.curId = row.productmallWaresId;
      this.showDrawer = true;
    },
    viewBtn(row) {
      this.drawerIsEdit = false;
      this.curId = row.productmallWaresId;
      this.showDrawer = true;
    },
    // 新增按钮
    addBtnClick() {
      this.drawerIsEdit = false;
      this.curId = null;
      this.showDrawer = true;
    },
    // 删除按钮
    async delBtn(data) {
      await this.$confirm("确认删除该数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      await this.$axios.post(delGood, {
        productmallWaresId: data.productmallWaresId,
      });
      this.$message.success("删除成功");
      this.handlePageChange();
    },

    handlePageChange(index) {
      this.page.pageIndex = index;
      this.getList();
    },
    // 上下架
    async shelfBtn(data, status) {
      await this.$confirm(
        `确认执行${+status ? "上架" : "下架"}操作吗`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      );
      this.tableLoading = true;
      await this.$axios
        .post(changeGoodStatus, {
          productmallWaresId: data.productmallWaresId,
          mallWaresStatus: status,
        })
        .finally(() => {
          this.tableLoading = false;
        });
      this.$message.success(`${+status ? "上架" : "下架"}成功`);
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.drawer {
  /deep/ .el-drawer__header span {
    font-weight: bold;
    font-size: 1.07rem;
  }
}
.qForm {
  /deep/.el-form-item {
    margin-bottom: 10px;
  }
}
.goods {
  padding: 10px 0 10px 10px;
  &-img {
    width: 100px;
    height: 100px;
  }
}
.con {
  margin-bottom: 20px;
  min-height: 450px;
  &-del {
    color: #f56c6c;
  }
}
.drawer {
  /deep/ .el-drawer__header {
    margin-bottom: 5px;
  }
}
</style>
