<template>
  <el-dialog
    title="分配权限"
    :visible.sync="dialogVisible"
    width="600px"
    class="dialog"
  >
    <el-tree
      v-loading="treeLoading"
      :data="menu"
      show-checkbox
      default-expand-all
      :props="treeProps"
      node-key="auth"
      ref="menuTree"
      highlight-current
    >
    </el-tree>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false" size="small">取 消</el-button>
      <el-button
        type="primary"
        @click="confirmBtn"
        size="small"
        :loading="saveLoading"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import { getMemberAuth, setUserAuth } from "@/api/userCenter";
import { filterAuth } from "@/utils/auth";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      menu: [],
      treeProps: {
        label: "title",
        children: "child",
      },
      saveLoading: false,
      treeLoading: false,
    };
  },
  computed: {
    ...mapGetters(["userBaseInfo"]),
    dialogVisible: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        this.$emit("input", val);
      },
    },
  },
  created() {
    // 获取可分配菜单
    this.filterAuths();
    this.getUserAuth();
  },
  mounted() {},
  methods: {
    async getUserAuth() {
      const { userId } = this.data;
      this.treeLoading = true;
      const data = await this.$axios
        .get(getMemberAuth, { userId })
        .finally(() => {
          this.treeLoading = false;
        });
      if (data && data.length) {
        // 过滤出二级菜单
        const filterData = data.filter((item) => {
          return item.secondaryJurisdiction;
        });
        const authKeys = filterData.map((item) => {
          return item.secondaryJurisdiction;
        });
        this.$refs.menuTree.setCheckedKeys(authKeys);
      }
    },
    async confirmBtn() {
      this.saveLoading = true;
      const allCheckNodes = this.$refs.menuTree.getCheckedNodes();
      const authNode = allCheckNodes.filter((item) => !item.child);
      const { laboratoryId, userId } = this.data;
      const transferNode = authNode.map(({ auth, parentAuth }) => {
        return {
          firstJurisdiction: parentAuth,
          secondaryJurisdiction: auth,
          laboratoryId,
          userid: userId,
        };
      });
      await this.$axios
        .post(setUserAuth, {
          userJurisdictionRequestList: transferNode,
        })
        .finally(() => {
          this.saveLoading = false;
        });
      this.$message.success("分配权限成功");
      this.dialogVisible = true;
    },
    // 获取可分配菜单
    filterAuths() {
      const { userJurisdictionList } = this.userBaseInfo;
      if (userJurisdictionList.length) {
        const result = filterAuth(userJurisdictionList);
        result.forEach((item) => {
          item.child.forEach((child) => {
            // 实验室账号管理不允许分配
            if (child.auth === "labAccountManage") {
              Object.assign(child, { disabled: true });
            }
          });
        });
        this.menu.push(...result);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  /deep/ .el-dialog__body {
    padding: 10px 20px 0 20px;
  }
}
</style>
