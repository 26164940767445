<template>
  <el-table :data="data" border>
    <el-table-column prop="orderNo" label="订单编号"> </el-table-column>
    <el-table-column prop="price" label="发票金额" width="90px">
    </el-table-column>
    <el-table-column prop="attr" label="发票性质" width="90px">
    </el-table-column>
    <el-table-column prop="type" label="发票类型"> </el-table-column>
    <el-table-column label="发票状态" width="100px">
      <template slot-scope="scope">
        {{ statusMap[scope.row.status] }}
      </template>
    </el-table-column>
    <el-table-column prop="getType" label="寄送方式" width="90px">
    </el-table-column>
    <el-table-column label="操作" width="135px">
      <template slot-scope="scope">
        <span class="btn btn-primary" @click="viewBtnClick(row)"> 查看</span>
        <template v-if="scope.row.status === 2">
          <span class="btn btn-primary" @click="passClick(scope.row)">
            通过</span
          >
          <span class="btn btn-danger" @click="rejectClick(scope.row)">
            驳回</span
          >
        </template>
        <template v-else-if="scope.row.status === 1">
          <span class="btn btn-primary"> 快递信息</span>
        </template>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      statusMap: {
        1: "已通过",
        2: "待审核",
        3: "已驳回",
      },
    };
  },
  methods: {
    viewBtnClick(row) {
      this.$emit("viewDetail", row);
    },
    // 通过按钮
    passClick(row) {
      this.$emit("pass", row);
    },
    // 驳回按钮
    rejectClick(row) {
      this.$emit("reject", row);
    },
  },
};
</script>
<style lang="scss" scoped>
.btn {
  margin-right: 10px;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
  &-primary {
    color: #409eff;
  }
  &-danger {
    color: #f56c6c;
  }
}
</style>
