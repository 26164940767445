<template>
  <div
    class="good pointer"
    :class="{ isRight: index % 4 == 0 }"
    @click="toDetail"
  >
    <div class="good-cover">
      <img :src="info.checkmallWaresLogo" v-if="info.checkmallWaresLogo" />
      <img v-else :src="$oss('common/default_device.png')" />
    </div>
    <div class="good-deviceName ellipsis">{{ info.checkmallWaresName }}</div>
    <div class="good-btn">
      <span @click.stop="toPay" class="pointer">在线下单</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    index: {
      type: Number,
      default: null,
    },
    info: {
      type: Object,
      default: () => ({}),
    },
    unitMap: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  methods: {
    toDetail() {
      this.$jump.push({
        name: "deviceDetail",
        query: { id: this.info.checkmallWaresId },
      });
    },
    toPay() {
      this.toDetail();
    },
  },
};
</script>
<style lang="scss" scoped>
.good {
  width: 290px;
  background: #ffffff;
  border: 1px solid #f4eeee;
  margin-right: 20px;
  margin-bottom: 20px;
  &.isRight {
    margin-right: 0;
  }
  &-cover {
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
    padding-top: 32px;
    img {
      width: 214px;
      height: 177px;
      object-fit: cover;
    }
  }
  &-deviceName {
    padding: 0 33px;
    font-size: 18px;
    font-weight: bold;
    color: #202020;
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
  }
  &-btn {
    text-align: center;
    margin-bottom: 30px;
    span {
      display: inline-block;
      padding: 8px 27px;
      color: white;
      background: #4483ff;
      border-radius: 2px;
      font-weight: bold;
      font-size: 16px;
    }
  }
}
</style>
