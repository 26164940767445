var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"good-left"},[(_vm.info.productmallWaresImage[_vm.showImgIndex])?_c('div',{staticClass:"good-cover"},[_c('pic-zoom',{attrs:{"url":_vm.info.productmallWaresImage[_vm.showImgIndex],"scale":3}})],1):_vm._e()]),_c('div',{staticClass:"flex flex-column flex-between"},[_c('div',[_c('div',{staticClass:"good-name"},[_vm._v(_vm._s(_vm.info.productmallWaresName))]),_c('div',{staticClass:"good-label good-list"},[_vm._v(" "+_vm._s(_vm.info.productmallWaresSubTitle)+" ")]),_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"good-list good-marginRight"},[_c('label',[_vm._v("品牌：")]),_c('span',[_vm._v(_vm._s(_vm.info.productmallWaresBrand || "无"))])]),(_vm.info.isFreeMail)?_c('div',{staticClass:"good-list"},[_c('label',[_vm._v("邮费：")]),_c('span',{staticClass:"good-mailFree"},[_vm._v("包邮")])]):_vm._e()]),(_vm.info.waresAttributeValues && _vm.info.waresAttributeValues.length)?_c('div',{staticClass:"flex flex-row flex-wrap"},_vm._l((_vm.info.waresAttributeValues),function(item,index){return _c('div',{key:index,staticClass:"good-marginRight"},[(item.attributeValue)?_c('div',{staticClass:"good-list"},[_c('label',[_vm._v(_vm._s(item.attributeName)+"：")]),_c('span',[_vm._v(_vm._s(_vm.info.attributeValue || "无"))])]):_vm._e()])}),0):_vm._e(),_c('div',{staticClass:"good-list"},[_c('label',[_vm._v("规格：")]),_vm._l((_vm.info.waresRanks),function(item,index){return _c('span',{key:index,staticClass:"good-option",class:{
              active:
                _vm.currentOption.productmallWaresRankId ===
                item.productmallWaresRankId,
            },on:{"click":function($event){return _vm.optionClick(item)}}},[_vm._v(" "+_vm._s(item.waresRankName)+" ")])})],2),_c('div',{staticClass:"good-list"},[_c('label',[_vm._v("价格：")]),_c('span',{staticClass:"good-price"},[(!_vm.currentOption.discountPrice)?_c('span',[_vm._v("面议")]):[_c('span',[_vm._v("￥")]),_c('label',[_vm._v(_vm._s(_vm.currentOption.discountPrice))])]],2),(
              _vm.currentOption.discountPrice &&
              _vm.currentOption.discountStatus &&
              +_vm.currentOption.discountStatus < 10
            )?_c('span',{staticClass:"good-discount"},[_c('span',[_vm._v("￥")]),_c('label',[_vm._v(_vm._s(_vm.currentOption.price))])]):_vm._e()]),_c('div',{staticClass:"good-list"},[_c('label',[_vm._v("数量：")]),_c('el-input-number',{attrs:{"controls-position":"right","size":"mini","min":1,"max":10000},model:{value:(_vm.num),callback:function ($$v) {_vm.num=$$v},expression:"num"}})],1)]),_c('div',{staticClass:"good-btns flex flex-row"},[_c('div',{staticClass:"good-btn1",on:{"click":_vm.toPay}},[_vm._v("立即购买")]),_c('div',{staticClass:"good-btn2",on:{"click":_vm.addCart}},[_vm._v("加入购物车")]),_c('div',{staticClass:"good-btn2",on:{"click":_vm.toContact}},[_vm._v("联系客服")])])])]),_c('div',{staticClass:"good-imgs flex"},_vm._l((_vm.info.productmallWaresImage),function(item,index){return _c('div',{key:index,class:{ active: _vm.showImgIndex === index },on:{"mouseover":function($event){return _vm.mouseoverImg(index)}}},[_c('img',{attrs:{"src":item}})])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }