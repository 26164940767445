<template>
  <div class="cate" v-loading="loading">
    <div v-for="(menu, index) in cateOptions" :key="index">
      <div class="cate-head pointer" @click="cateClick(menu.serviceCategoryId)">
        {{ menu.serviceCategoryName }}
      </div>
      <div class="cate-body">
        <el-menu
          :collapse="true"
          v-for="(cates, cIndex) in menu.children"
          :key="cIndex"
          text-color="white"
          background-color="#4482FF"
        >
          <el-submenu
            :index="`${cates.serviceCategoryId}`"
            popper-class="homeSubmenu"
          >
            <template slot="title">
              <div
                @click="
                  cateClick(menu.serviceCategoryId, cates.serviceCategoryId)
                "
              >
                {{ cates.serviceCategoryName }}
              </div>
            </template>
            <div class="menu-expend">
              <div class="menu-lTitle">{{ cates.serviceCategoryName }}：</div>
              <span
                v-for="(cate, aIndex) in cates.children"
                :key="aIndex"
                @click="
                  cateClick(
                    menu.serviceCategoryId,
                    cates.serviceCategoryId,
                    cate.serviceCategoryId
                  )
                "
              >
                {{ cate.serviceCategoryName }}
              </span>
            </div>
          </el-submenu>
        </el-menu>
      </div>
    </div>
  </div>
</template>
<script>
import { getGoodCate } from "@/api/userCenter";
export default {
  data() {
    return {
      cateOptions: [],
      loading: false,
    };
  },
  created() {
    this.getCate();
  },
  methods: {
    async getCate() {
      this.loading = true;
      const cate = await this.$axios.post(getGoodCate, {}).finally(() => {
        this.loading = false;
      });
      this.cateOptions = cate;
    },
    cateClick(firstId, secondId, thirdId) {
      const query = {};
      if (firstId) {
        Object.assign(query, { firstId });
      }
      if (secondId) {
        Object.assign(query, { secondId });
      }
      if (thirdId) {
        Object.assign(query, { thirdId });
      }
      this.$jump.push({ name: "mallSuper", query });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
$menu-theme: $theme-color;
$line-h: 56px;
.cate {
  width: 100%;
  height: 100%;
  background: #4482ff;
  max-height: 527px;
  overflow: auto;
  &-head {
    height: 44px;
    line-height: 44px;
    background: #1f66f6;
    padding: 0 31px;
    color: white;
  }
  &-body {
    background: #4482ff;
    /deep/ .el-menu {
      border: none;
    }
    /deep/ .el-menu--collapse {
      width: 100% !important;
    }
    /deep/.el-submenu__title {
      height: $line-h;
      line-height: $line-h;
    }
  }
}
.menu {
  &-expend {
    padding: 15px 30px 20px 30px;
    max-width: 600px;
    min-width: 140px;
    background: white;
    display: inline-block;
    > span {
      float: left;
      padding: 0 10px;
      height: 16px;
      line-height: 16px;
      border-left: 1px solid #e0e0e0;
      white-space: nowrap;
      margin-bottom: 15px;
      font-size: 0.85rem;
      cursor: pointer;
      &:hover {
        color: $menu-theme;
      }
    }
  }
  &-lTitle {
    margin-bottom: 10px;
    font-weight: bold;
  }
  &-leafActive {
    color: $menu-theme;
  }
}
</style>
<style lang="scss">
.homeSubmenu {
  .el-menu--popup {
    padding: 0 !important;
  }
}
</style>
