<template>
  <div class="good">
    <div class="line">
      <span>热门推荐</span>
    </div>
    <div
      v-for="(good, index) in goods"
      :key="index"
      class="good-item pointer"
      @click="toDetail(good)"
    >
      <img :src="good.productmallWaresImage" />
      <div class="good-cover">
        <div class="ellipsis">{{ good.productmallWaresName }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { hotGoodRecommend } from "@/api/mall";
export default {
  data() {
    return {
      goods: [],
    };
  },
  created() {
    this.getHot();
  },
  methods: {
    async getHot() {
      const { list } = await this.$axios.post(hotGoodRecommend, {
        pageIndex: 1,
        pageSize: 5,
      });
      this.goods = list;
    },
    toDetail(good) {
      this.$jump.push({
        name: "goodsDetail",
        query: { id: good.productmallWaresId },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
$max-width: 160px;
.line {
  border-bottom: 1px dotted #9c9c9c;
  text-align: center;
  margin-bottom: 30px;
  > span {
    padding: 0 15px;
    background: white;
    position: relative;
    font-size: 1.14rem;
    top: 9px;
    color: $font-color-gray;
  }
}
.good {
  width: $max-width;
  &-item {
    margin-bottom: 25px;
    width: $max-width;
    height: $max-width;
    border-radius: $border-radius;
    overflow: hidden;
    position: relative;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-cover {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding: 3px 5px;
  }
}
</style>
