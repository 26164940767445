<template>
  <div>
    <div class="bar">
      <label>已选金额: </label>
      <span class="bar-price">¥{{ selectMoney }}</span>
      <el-button type="primary" size="mini" @click="askBtnClick"
        >索取发票</el-button
      >
    </div>
    <div class="myTable">
      <AskTable
        :data="tableData"
        :money.sync="selectMoney"
        :selection.sync="selectionData"
      />
    </div>
    <!-- 分页-->
    <Pagination
      :total="totalItem"
      :page="page"
      @pageIndexChange="handlePageChange"
    />
    <el-drawer
      title="设置发票类型及备注"
      :visible.sync="showDrawer"
      :wrapperClosable="false"
      size="700px"
      class="drawer"
    >
      <div class="drawer-form">
        <BillForm />
        <div class="drawer-btn">
          <el-button type="primary" plain size="small">确认提交</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index";
import AskTable from "./AskBillTable.vue";
import BillForm from "./ReserveBillForm.vue";
export default {
  components: {
    Pagination,
    AskTable,
    BillForm,
  },
  data() {
    return {
      showDrawer: false, // 索取发票弹层
      totalItem: 0, // 总数量
      selectMoney: 0, // 表格选中数据的总钱数
      // 分页数据
      page: {
        pageIndex: 1,
        pageSize: 5,
      },
      selectionData: [], // 选中的订单
      // 表格数据
      tableData: [
        {
          id: 1,
          orderNo: "2009270303530100",
          price: "1600.0",
          deedNo: "202009230009",
          labName: "深圳市倍测科技有限公司",
          type: "严选商城",
          time: "2020-09-23",
        },
        {
          id: 2,
          orderNo: "2009270303530100",
          price: "1600.0",
          deedNo: "202009230009",
          labName: "深圳市倍测科技有限公司",
          type: "严选商城",
          time: "2020-09-23",
        },
        {
          id: 3,
          orderNo: "2009270303530100",
          price: "1600.0",
          deedNo: "202009230009",
          labName: "深圳市倍测科技有限公司",
          type: "严选商城",
          time: "2020-09-23",
        },
      ],
    };
  },
  methods: {
    // 翻页处理
    handlePageChange(index) {
      this.page.pageIndex = index;
      //   this.getList();
    },

    // 索取发票按钮
    askBtnClick() {
      if (!this.selectionData.length) {
        this.$message.warning("请先选择订单");
        return;
      }
      this.showDrawer = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.bar {
  padding: 10px 10px;
  background: #eaf1fe;
  text-align: right;
  &-price {
    margin-right: 10px;
    color: $theme-color;
  }
}
.myTable {
  margin-bottom: 10px;
  min-height: 500px;
}
.drawer {
  // /deep/ .el-drawer__header {
  //   margin-bottom: 10px;
  // }
  &-form {
    padding: 0 15px;
  }
  &-btn {
    padding-left: 150px;
  }
}
</style>
