<template>
  <div class="cou">
    <img
      class="cou-bg"
      v-if="data.couponUseStatus === COUPON_STATUS_EXPIRED"
      :src="$oss('discountAct/couponBgExpired.png')"
    />
    <img
      class="cou-bg"
      v-else-if="data.couponUseStatus === COUPON_STATUS_USED"
      :src="$oss('discountAct/couponBgUsed.png')"
    />

    <img
      v-else-if="data.couponUseMall === 0"
      class="cou-bg"
      :src="$oss('discountAct/couponBg0.png')"
    />
    <img
      v-else-if="data.couponUseMall === 1"
      class="cou-bg"
      :src="$oss('discountAct/couponBg1.png')"
    />
    <img v-else class="cou-bg" :src="$oss('discountAct/couponBg2.png')" />
    <div class="cou-con flex flex-row">
      <div class="price flex flex-center flex-middle">
        <label class="price-label">￥</label>
        <span class="price-num">{{ data.couponDenomination }}</span>
      </div>
      <div class="flex-1 cou-detail">
        <div class="flex flex-row flex-between">
          <div>
            <div class="cou-requirement">
              <span>满</span>
              <span>{{ data.couponUseRequirement }}</span>
              <span>减</span>
              <span>{{ data.couponDenomination }}</span>
            </div>
            <div class="cou-tip">{{ typeMap[data.couponUseMall] }}</div>
          </div>
          <div
            class="cou-btnWrap"
            v-if="range === 'act' || data.couponUseStatus === '0'"
          >
            <span
              class="cou-btn pointer"
              :class="{
                type0: data.couponUseMall == 0,
                type1: data.couponUseMall == 1,
                type2: data.couponUseMall == 2,
              }"
              @click="btnClick"
            >
              <template v-if="range === 'act'">{{
                data.isGetStatus ? "已领取" : "领 取"
              }}</template>
              <template v-else> 去使用 </template>
            </span>
          </div>
        </div>
        <div class="cou-endTime">
          有效期至{{ data.couponEndTime | transformTime }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { useReceiveCoupon } from "@/api/activity";
import { parseTime } from "@/utils/index";

import { COUPON_STATUS_EXPIRED, COUPON_STATUS_USED } from "@/constant/dict";
export default {
  filters: {
    transformTime(val) {
      return parseTime(val);
    },
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    range: {
      type: String,
      default: "act",
    },
  },
  data() {
    return {
      COUPON_STATUS_EXPIRED,
      COUPON_STATUS_USED,
      typeMap: {
        0: "全平通用",
        1: "仅服务商城使用",
        2: "仅商品商城使用",
      },
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  methods: {
    // 按钮点击分发
    btnClick() {
      if (this.range === "act") {
        this.receiveBtnClick();
      } else {
        this.toUseClick();
      }
    },
    // 点击领取按钮
    async receiveBtnClick() {
      if (this.token) {
        const { couponId, isGetStatus } = this.data;
        if (isGetStatus) {
          this.$message.info("不能重复领取");
          return;
        }
        await this.$axios.post(useReceiveCoupon, { couponId });
        this.$message.success("领取成功");
        this.data.isGetStatus = 1;
      } else {
        this.$store.dispatch("login");
      }
    },
    // 点击去使用
    toUseClick() {
      const { couponUseMall } = this.data;
      const pathMap = {
        0: "",
        1: "mallStrictHome",
        2: "mallSuperHome",
      };
      // this.$myRoute.navigateTo({ url: pathMap[+couponUseMall] });
      this.$jump.push({ name: pathMap[+couponUseMall] });
    },
  },
};
</script>
<style lang="scss" scoped>
.price {
  font-weight: bold;
  color: #ffffff;
  width: 96px;
  &-label {
    font-size: 14px;
    position: relative;
    top: 8px;
  }
  &-num {
    font-size: 32px;
    font-weight: bold;
    color: #ffffff;
  }
}
.cou {
  border-radius: 5px;
  position: relative;
  width: 100%;
  height: 104px;
  &-bg {
    width: 100%;
    height: 100%;
  }
  &-con {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
  }
  &-detail {
    padding: 16px 16px 0 32px;
    color: white;
  }
  &-requirement {
    font-size: 19px;
    color: #ffffff;
    margin-bottom: 2px;
  }
  &-tip {
    font-size: 14px;
    margin-bottom: 6px;
  }
  &-endTime {
    font-size: 12px;
  }
  &-btnWrap {
    padding-top: 25px;
  }
  &-btn {
    font-size: 14px;
    font-weight: bold;
    background: white;
    padding: 4px 16px;
    border-radius: 15px;
    &.type0 {
      color: #ff8295;
    }
    &.type1 {
      color: #7e64f9;
    }
    &.type2 {
      color: #feab07;
    }
  }
}
</style>
