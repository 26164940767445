<template>
  <div class="attr-table">
    <el-table border :data="attrbutes" size="mini">
      <el-table-column
        label="属性名称"
        key="attributeName"
        prop="attributeName"
      />
      <el-table-column label="属性值" prop="attributeValue">
        <template slot-scope="scope">
          <el-input size="mini" v-model="scope.row.attributeValue"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="展示状态">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.isShow"
            placeholder="请选择"
            size="mini"
          >
            <el-option
              v-for="item in attributeShowFlags"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  props: {
    attrbutes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      attributeShowFlags: [
        { value: "1", label: "显示" },
        { value: "0", label: "隐藏" },
      ],
    };
  },
};
</script>
