<template>
  <div class="prod flex flex-row pointer" @click="toProDetail">
    <div class="prod-cover">
      <img :src="data.goodsImage" class="prod-img" />
    </div>
    <div class="flex-1">
      <div class="prod-title">{{ data.goodsName }}</div>
      <div class="prod-text">{{ data.goodsBrief }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    toProDetail() {
      this.$jump.push({
        name: "journalDetail",
        query: { id: this.data.goodsId },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.prod {
  background: white;
  height: 158px;
  padding: 12px;
  &-cover {
    width: 100px;
    height: 131px;
    margin-right: 20px;
  }
  &-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: middle;
  }
  &-title {
    font-size: 16px;
    margin-bottom: 15px;
  }
  &-text {
    font-size: 14px;
    color: #666666;
    line-height: 24px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}
</style>
