<template>
  <div class="form">
    <el-form
      :model="form"
      :rules="rules"
      ref="ruleForm"
      label-width="110px"
      v-loading="detailLoading"
    >
      <el-form-item label="商城服务名称" prop="checkmallWaresName">
        <el-input
          v-model.trim="form.checkmallWaresName"
          placeholder="请输入检测商城服务名称"
          :maxlength="80"
        ></el-input>
      </el-form-item>
      <el-row :gutter="20">
        <el-col :span="9">
          <el-form-item label="检测服务logo">
            <LogoUpload
              :imgs="form.checkmallWaresLogo"
              @onSuccess="checkmallWaresLogoSuccess"
            />
          </el-form-item>
        </el-col>
        <el-col :span="15" class="form-short">
          <el-form-item label="服务分类" prop="serviceCategoryId">
            <el-cascader
              v-model="form.serviceCategoryId"
              :props="catesProps"
              :options="serviceCateOption"
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="品牌">
            <el-input
              v-model.trim="form.checkmallWaresBrand"
              placeholder="请输入商品品牌"
              clearable
              :maxlength="40"
            />
          </el-form-item>
          <el-form-item label="型号">
            <el-input
              v-model.trim="form.checkmallWaresModel"
              placeholder="请输入商品型号"
              clearable
              :maxlength="40"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="检测模式" prop="checkmallWaresPattern">
        <el-input
          v-model.trim="form.checkmallWaresPattern"
          placeholder="请输入检测模式"
          clearable
          :maxlength="30"
        />
      </el-form-item>
      <el-form-item label="商城服务功能" prop="checkmallWaresFunctions">
        <el-input
          v-model.trim="form.checkmallWaresFunctions"
          placeholder="请输入检测商城服务功能"
          clearable
          :maxlength="100"
        />
      </el-form-item>
      <el-form-item label="服务项目">
        <ServiceProjects
          :data="form.serviceProject"
          ref="projects"
          :isEdit="isEdit"
        />
      </el-form-item>
      <el-form-item label="服务简介" prop="waresServiceBrief">
        <el-input
          v-model.trim="form.waresServiceBrief"
          type="textarea"
          placeholder="请输入服务简介"
          :maxlength="400"
          :rows="rows"
        />
      </el-form-item>
      <el-form-item label="服务参数" prop="waresServiceParam">
        <Editor v-model="form.waresServiceParam" />
      </el-form-item>
      <el-form-item label="样品要求" prop="waresSampleRequirements">
        <Editor v-model="form.waresSampleRequirements" />
      </el-form-item>
      <el-form-item label="检测依据/方法" prop="checkmallWaresMethod">
        <el-input
          v-model.trim="form.checkmallWaresMethod"
          type="textarea"
          placeholder="请输入内容"
          :rows="rows"
          :maxlength="400"
        />
      </el-form-item>
      <el-form-item label="检测使用设备" prop="checkmallWaresEquipment">
        <el-input
          v-model.trim="form.checkmallWaresEquipment"
          type="textarea"
          placeholder="请输入检测使用设备"
          :rows="rows"
          :maxlength="400"
        />
      </el-form-item>
      <div class="flex flex-row">
        <div class="flex-1">
          <el-form-item label="是否包邮" prop="isFreeMail">
            <el-radio-group v-model="form.isFreeMail">
              <el-radio
                v-for="dict in isFreeMailOptions"
                :key="dict.dictValue"
                :label="dict.dictValue"
                >{{ dict.dictLabel }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <!-- <div class="flex-1">
          <el-form-item label="服务状态" prop="mallWaresStatus">
            <el-select
              v-model="form.mallWaresStatus"
              placeholder="请选择服务状态"
            >
              <el-option
                v-for="dict in mallWaresStatusOptions"
                :key="dict.dictValue"
                :label="dict.dictLabel"
                :value="dict.dictValue"
              ></el-option>
            </el-select>
          </el-form-item>
        </div> -->
      </div>
      <!-- <el-form-item label="备注" prop="remark">
        <el-input
          v-model="form.remark"
          type="textarea"
          placeholder="请输入内容"
          :rows="rows"
        />
      </el-form-item> -->
      <el-form-item label="" v-if="isEdit || !data.id">
        <el-button
          type="primary"
          plain
          size="small"
          @click="saveBtn"
          :loading="saveLoading"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { deviceDetail, queryKind } from "@/api/mall";
import { editService, addService } from "@/api/userCenter";
import { FREE_MAIL, SERVICE_STATUS } from "@/constant/dict";
import { axiosRequest } from "@/plugins/request";
import ServiceProjects from "./ServiceProjects.vue";
import Editor from "@/components/Editor/index";
import LogoUpload from "@/components/ImgUpload/index";
import RULES from "./rules";
export default {
  components: {
    LogoUpload,
    ServiceProjects,
    Editor,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rows: 3,
      detailLoading: false,
      saveLoading: false,
      form: {
        checkmallWaresName: "", // 商城服务名称
        serviceCategoryId: null, // 服务分类
        checkmallWaresBrand: "", // 品牌
        checkmallWaresModel: "", // 型号
        checkmallWaresPattern: "", // 检测模式
        checkmallWaresFunctions: "", // 商城服务功能
        waresServiceBrief: "", // 服务简介
        waresServiceParam: "", // 服务参数
        waresSampleRequirements: "", // 样品要求
        checkmallWaresMethod: "", // 检测依据/方法
        checkmallWaresEquipment: "", // 检检测使用设备
        isFreeMail: null, // 是否包邮
        mallWaresStatus: null, // 服务状态
        // remark: "", // 备注
      },
      rules: RULES,
      catesProps: {
        value: "serviceCategoryId",
        label: "serviceCategoryName",
      },
      isFreeMailOptions: [],
      mallWaresStatusOptions: [],
      serviceCateOption: [], // 服务分类
    };
  },
  async created() {
    this.getDicts();
    await this.getServiceCate();
    if (this.data.id) this.getDetail();
  },
  methods: {
    async getServiceCate() {
      this.detailLoading = true;
      const pCate = await this.getCateById(0);
      await pCate.reduce((pre, cur, index) => {
        return pre.then(async () => {
          return await this.getCateById(cur.serviceCategoryId).then((res) => {
            pCate[index].children = res;
          });
        });
      }, Promise.resolve());
      this.detailLoading = false;
      this.serviceCateOption = pCate;
    },
    async getCateById(id) {
      const res = await axiosRequest.get(queryKind, {
        superCategoryId: id,
      });
      return res;
    },
    async getDicts() {
      const mail = await this.$store.dispatch("getDictData", FREE_MAIL);
      this.isFreeMailOptions = mail;
      const status = await this.$store.dispatch("getDictData", SERVICE_STATUS);
      this.mallWaresStatusOptions = status;
    },
    async getDetail() {
      this.detailLoading = true;
      const data = await this.$axios.get(deviceDetail, {
        id: this.data.id,
      });
      const { serviceCategorySuperId, serviceCategoryId } = data;
      Object.assign(data, {
        serviceCategoryId: [+serviceCategorySuperId, +serviceCategoryId],
      });
      this.form = data;
      await this.$nextTick();
      this.$refs.ruleForm.clearValidate();
      this.$parent.$el.querySelector(`.el-drawer__body`).scrollTop = 0;
      this.detailLoading = false;
    },
    checkmallWaresLogoSuccess(fileUrls) {
      Object.assign(this.form, { checkmallWaresLogo: fileUrls });
    },
    async saveBtn() {
      await this.$refs.ruleForm.validate();
      const { serviceCategoryId, ...rest } = this.form;
      const proData = this.$refs.projects.getData();
      if (!proData) return;
      const { project, deleteFirstRankIds, deleteSecondRankIds } = proData;
      const params = {
        ...rest,
        serviceCategorySuperId: serviceCategoryId[0],
        serviceCategoryId: serviceCategoryId[1],
        serviceProject: project,
      };
      this.saveLoading = true;
      if (this.isEdit) {
        Object.assign(params, {
          deleteFirstRankIds,
          deleteSecondRankIds,
        });
        await this.$axios.post(editService, params).finally(() => {
          this.saveLoading = false;
        });
      } else {
        await this.$axios.post(addService, params).finally(() => {
          this.saveLoading = false;
        });
      }

      this.$emit("refresh");
      this.$message.success("保存成功");
    },
  },
};
</script>
<style lang="scss" scoped>
.form {
  padding: 0 15px;
  /deep/ textarea {
    resize: none;
  }
  &-short {
    /deep/ .el-form-item {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
