<template>
  <div class="demand">
    <div class="demand-head flex flex-row flex-between">
      <div class="demand-name">{{ data.demandTitle }}</div>
      <div class="demand-time">{{ data.createTime }}</div>
    </div>
    <div class="demand-con flex flex-row">
      <div class="flex-1">
        <div class="demand-list demand-pro">
          <label>检测项：</label>{{ data.demandProjectName }}
        </div>
        <div class="demand-list">
          <label>地&nbsp;&nbsp;&nbsp;址：</label>
          <span>{{ data.demandPublishProvince }}</span>
          <span>{{ data.demandPublishCity }}</span>
          <span>{{ data.demandPublishCounty }}</span>
          <span>{{ data.demandPublishAddress }}</span>
        </div>
      </div>
      <div class="demand-btn flex flex-right flex-middle">
        <span class="pointer" @click="toDetail">查看详情</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  methods: {
    // 表格行点击事件
    toDetail() {
      this.$jump.push({
        name: "demandDetail",
        query: { id: this.data.demandId },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.demand {
  margin-bottom: 23px;
  &-head {
    height: 39px;
    line-height: 39px;
    background: #f7faff;
    padding-left: 24px;
    padding-right: 22px;
  }
  &-name {
    font-size: 18px;
    font-weight: bold;
    color: #3b3b3b;
  }
  &-time {
    color: #4482ff;
  }
  &-con {
    background: white;
    padding: 15px 23px;
  }
  &-list {
    color: #9c9c9c;
  }
  &-pro {
    margin-bottom: 15px;
  }
  &-btn {
    width: 190px;
    span {
      display: inline-block;
      border: 1px solid #4483ff;
      color: #4483ff;
      padding: 4px 14px;
    }
  }
}
</style>
