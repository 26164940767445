<template>
  <div class="online">
    <el-tabs v-model="activeName">
      <el-tab-pane label="参与的培训" name="first">
        <ClassList />
      </el-tab-pane>
      <el-tab-pane label="我的订单" name="second">
        <OrderList />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import ClassList from "./components/ClassList.vue";
import OrderList from "./components/OrderList.vue";
export default {
  components: {
    ClassList,
    OrderList,
  },
  data() {
    return {
      activeName: "first",
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.online {
  padding: 0 10px;
}
</style>
