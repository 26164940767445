<template>
  <div>
    <HeadBar /><!--顶部bar-->
    <HeadNav /><!-- 顶部导航-->
    <div class="con">
      <div
        class="con-bg"
        :style="{
          background: `url(${$oss('baseIntroduction/banner.jpg')})`,
          'background-size': '100% 100%',
        }"
      ></div>
      <div class="con-inner flex flex-row">
        <div class="con-menu"><Menu @menuClick="menuClick" /></div>
        <div class="flex-1">
          <Bread :title="menuName" />
          <div class="con-html">
            <EditorView
              :data="base.subCenterIntroduce"
              v-show="menuKey == 'subCenterIntroduce'"
            />
            <div v-show="menuKey == 'subCenterOrganization'" class="con-org">
              <img :src="base.subCenterOrganization" />
            </div>
            <ExpertList v-show="menuKey == 'expert'" />
            <Unit v-show="menuKey == 'unit'" />
          </div>
        </div>
      </div>
    </div>
    <FootBar class="footer" />
  </div>
</template>
<script>
import Menu from "./components/Menus.vue";
import Bread from "./components/Bread.vue";
import { baseInfo } from "@/api/baseIntroduction";
import ExpertList from "./components/ExpertList.vue";
import Unit from "./components/Unit.vue";
export default {
  components: {
    Menu,
    Bread,
    ExpertList,
    Unit,
  },
  data() {
    return {
      menuName: "",
      base: {},
      htmlContent: "",
      menuKey: null,
    };
  },
  created() {
    this.getBaseInfo();
  },
  methods: {
    menuClick({ title, field }) {
      this.menuName = title;
      this.menuKey = field;
    },
    async getBaseInfo() {
      const res = await this.$axios.get(baseInfo);
      this.base = res;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.con {
  background: $home-bg;
  padding-bottom: 60px;
  &-bg {
    height: 328px;
  }
  &-inner {
    width: $max-width;
    margin: 0 auto;
    min-height: 500px;
    margin-top: -68px;
    background: white;
  }
  &-menu {
    width: 220px;
    background: #e3ecff;
  }
  &-html {
    padding: 30px 60px;
    line-height: 30px;
    word-break: break-word;
    img {
      max-width: 880px;
    }
    /deep/ img {
      max-width: 100%;
    }
  }
  &-org {
    text-align: center;
  }
}
</style>
