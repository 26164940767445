<template>
  <div class="base">
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="用户名称">
        {{ form.userLoginName }}
      </el-form-item>
      <!-- <el-form-item label="可用余额">
        {{ form.availableBalance }}
      </el-form-item> -->
      <el-form-item label="联系电话">
        <span> {{ form.userPhone }}</span>
        <span class="base-updateTel pointer" @click="updateTelClick"
          >修改手机号码</span
        >
      </el-form-item>
      <el-form-item label="电子邮箱">
        <el-input v-model="form.userEmail" class="width-middle"></el-input>
      </el-form-item>
      <el-form-item label="真实名称">
        <el-input v-model="form.userRealName" class="width-middle"></el-input>
      </el-form-item>
      <el-form-item label="性别">
        <el-radio-group v-model="form.userSex">
          <el-radio
            :label="item.dictValue"
            v-for="(item, index) in sexOption"
            :key="index"
            >{{ item.dictLabel }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="生日">
        <el-date-picker
          v-model="form.userBirthday"
          type="date"
          placeholder="选择日期"
          class="width-middle"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <div class="flex">
        <el-form-item label="所在地区">
          <el-cascader
            v-model="form.location"
            :options="cityOptions"
            filterable
            clearable
            placeholder="请选择省、市、区"
            class="width-middle"
            ref="cityCascader"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="" label-width="5px" class="flex-1">
          <el-input
            v-model="form.userAddress"
            placeholder="请输入详细地址"
            class="base-address"
          ></el-input>
        </el-form-item>
      </div>

      <el-form-item label="">
        <el-button type="primary" plain size="small" @click="submitBtnClick">
          提&nbsp;交
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { regionData } from "element-china-area-data";
import { updateUserInfo } from "@/api/login";
import { parseTime } from "@/utils/index";
import { USER_SEX } from "@/constant/dict";
import { Base64 } from "js-base64";
export default {
  data() {
    return {
      form: {
        userLoginName: "",
        userPhone: "",
        userEmail: "",
        userRealName: "",
        userSex: null,
        userBirthday: "",
        location: [],
        userAddress: "",
      },
      cityOptions: regionData,
      sexOption: [],
      pickerOptions: {
        disabledDate(time) {
          //选择今天以及今天之前的日期
          return time.getTime() > Date.now(); //如果没有后面的-8.64e7就是不可以选择今天的
        },
      },
    };
  },
  created() {
    const {
      userAddressProvinceCode,
      userAddressCityCode,
      userAddressCountyCode,
      ...rest
    } = this.$store.getters.userBaseInfo;
    Object.assign(this.form, { ...rest });
    if (
      userAddressProvinceCode &&
      userAddressCityCode &&
      userAddressCountyCode
    ) {
      Object.assign(this.form, {
        location: [
          userAddressProvinceCode,
          userAddressCityCode,
          userAddressCountyCode,
        ],
      });
    }

    this.getDict();
  },
  methods: {
    async getDict() {
      const data = await this.$store.dispatch("getDictData", USER_SEX);
      this.sexOption = data;
    },
    async submitBtnClick() {
      const {
        location,
        userLoginName,
        userPhone,
        userEmail,
        userSex,
        userBirthday,
        userAddress,
        userRealName,
      } = this.form;
      const params = {
        userLoginName: Base64.toBase64(userLoginName),
        userPhone: Base64.toBase64(userPhone),
        userEmail: Base64.toBase64(userEmail),
        userSex: `${userSex}`,
        userAddress,
        modifyType: "0", //(0-修改基本信息；1-修改密码；2-修改手机号)
        userRealName,
      };
      if (userBirthday) {
        Object.assign(params, {
          userBirthday: parseTime(userBirthday, "{y}-{m}-{d} {h}:{i}"),
        });
      }

      if (location && location.length) {
        const [userAddressProvince, userAddressCity, userAddressCounty] =
          this.$refs.cityCascader.getCheckedNodes()[0].pathLabels;
        Object.assign(params, {
          userAddressProvinceCode: location[0],
          userAddressCityCode: location[1],
          userAddressCountyCode: location[2],
          userAddressProvince,
          userAddressCity,
          userAddressCounty,
        });
      }
      await this.$axios.post(updateUserInfo, params);
      this.$message.success("修改成功");
    },
    updateTelClick() {
      this.$message.warning("请联系平台管理员修改手机号码");
    },
  },
};
</script>
<style lang="scss" scoped>
.base {
  width: 800px;
  &-updateTel {
    margin-left: 20px;
    color: #409eff;
  }
}
.width-middle {
  width: 300px;
}
</style>
