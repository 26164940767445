<template>
  <div class="device">
    <el-form :inline="true" :model="form" class="qForm" :size="queryFormSize">
      <el-form-item label="服务名称">
        <el-input
          v-model.trim="form.keyword"
          placeholder="请输入服务名称"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          plain
          @click="queryBtn"
          :loading="tableLoading"
          >查询</el-button
        >
        <el-button type="primary" plain @click="addBtnClick"> 新增 </el-button>
      </el-form-item>
    </el-form>

    <div class="con" v-loading="tableLoading">
      <el-table :data="tableData" border :size="tableSize">
        <el-table-column label="服务图片" width="122px">
          <template slot-scope="scope">
            <div class="con-logo">
              <el-image
                v-if="scope.row.imgsrc"
                :src="scope.row.imgsrc"
                :preview-src-list="[scope.row.imgsrc]"
              >
              </el-image>
              <img v-else :src="$oss('common/default_device.png')" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="devicename" label="服务名称">
          <template slot-scope="scope">
            <div class="con-devicename pointer" @click="nameClick(scope.row)">
              {{ scope.row.devicename }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="serviceCategoryName" label="服务类别">
        </el-table-column>
        <el-table-column prop="status" label="服务状态">
          <template slot-scope="scope">
            <div>
              {{ statusMap[scope.row.mallWaresStatus] }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间"> </el-table-column>
        <el-table-column label="操作" width="160px">
          <template slot-scope="scope">
            <template v-if="scope.row.mallWaresStatus !== '1'">
              <el-button type="text" size="small" @click="editBtn(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="delBtn(scope.row)"
                class="con-del"
                >删除</el-button
              >
            </template>

            <el-button
              v-if="scope.row.mallWaresStatus === '1'"
              type="text"
              size="small"
              @click="shelfBtn(scope.row, '0')"
              >下架</el-button
            >
            <el-button type="text" size="small" @click="viewBtn(scope.row)"
              >查看</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="shelfBtn(scope.row, '1')"
              v-if="scope.row.mallWaresStatus === '0'"
              >上架</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页-->
    <Pagination
      :total="totalItem"
      :page="page"
      @pageIndexChange="handlePageChange"
    />
    <el-drawer
      title="服务维护"
      :visible.sync="showDrawer"
      :wrapperClosable="false"
      size="950px"
      class="drawer"
    >
      <ServiceDetail
        :data="selectRow"
        :isEdit="drawerIsEdit"
        v-if="showDrawer"
        @refresh="refresh"
      />
    </el-drawer>
  </div>
</template>
<script>
import ServiceDetail from "./components/ServiceDetail";
import {
  scrictDeviceList,
  delService,
  serveceChangeStatus,
} from "@/api/userCenter";
import Pagination from "@/components/Pagination/index";
import { TABLE_SIZE, QUERY_FORM_SIZE } from "@/constant/size";
import { GOODS_SHELF_STATUS } from "@/constant/dict";

export default {
  components: {
    ServiceDetail,
    Pagination,
  },
  data() {
    return {
      queryFormSize: QUERY_FORM_SIZE,
      tableSize: TABLE_SIZE,
      drawerIsEdit: null,
      selectRow: {},
      showDrawer: false,
      tableLoading: true,
      form: {
        keyword: "",
      },
      tableData: [],
      page: {
        pageIndex: 1,
        pageSize: 10,
      },
      totalItem: 0,
      statusMap: {},
    };
  },
  created() {
    this.getDicts();
    this.getList();
  },
  methods: {
    // 获取数据字典数据
    async getDicts() {
      const res = await this.$store.dispatch("getDictData", GOODS_SHELF_STATUS);
      const statusMap = {};
      res.forEach((item) => {
        statusMap[item.dictValue] = item.dictLabel;
      });
      this.statusMap = statusMap;
    },
    // 关闭弹窗并刷新表格
    refresh() {
      this.showDrawer = false;
      this.getList();
    },
    // 查询按钮
    queryBtn() {
      this.page.pageIndex = 1;
      this.getList();
    },
    // 分页查询数据
    handlePageChange(index) {
      this.page.pageIndex = index;
      this.getList();
    },
    // 查询表格数据
    async getList() {
      this.tableLoading = true;
      const { list, totalItem } = await this.$axios
        .post(scrictDeviceList, {
          ...this.page,
          ...this.form,
        })
        .finally(() => {
          this.tableLoading = false;
        });
      this.tableData = list;
      this.totalItem = totalItem;
    },
    // 删除按钮
    async delBtn(row) {
      await this.$confirm("确认删除该服务？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      await this.$axios.post(delService, { checkmallWaresId: row.id });
      this.$message.success("删除服务成功");
      this.queryBtn();
    },
    // 编辑按钮
    editBtn(row) {
      this.selectRow = row;
      this.drawerIsEdit = true;
      this.showDrawer = true;
    },
    // 查看按钮
    viewBtn(row) {
      this.selectRow = row;
      this.drawerIsEdit = false;
      this.showDrawer = true;
    },
    // 新增按钮
    addBtnClick() {
      this.drawerIsEdit = false;
      this.selectRow = {};
      this.showDrawer = true;
    },
    // 点击名称跳转页面
    nameClick(row) {
      this.$jump.push({ name: "deviceDetail", query: { id: row.id } });
    },
    async shelfBtn(row, status) {
      await this.$confirm(
        `确认执行${+status ? "上架" : "下架"}操作吗`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      );
      this.tableLoading = true;
      await this.$axios
        .post(serveceChangeStatus, {
          id: row.id,
          mallWaresStatus: status,
        })
        .finally(() => {
          this.tableLoading = true;
        });
      this.$message.success(`${+status ? "上架" : "下架"}成功`);
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable.scss";
.drawer {
  /deep/ .el-drawer__header span {
    font-weight: bold;
    font-size: 1.07rem;
  }
}
$img-w: 100px;
.device {
  padding: 10px;
}
.qForm {
  height: 42px;
  /deep/.el-form-item {
    margin-bottom: 10px;
  }
}
.con {
  min-height: 650px;
  margin-bottom: 20px;
  &-logo {
    width: $img-w;
    height: $img-w;
    /deep/ img {
      width: $img-w;
      height: $img-w;
      object-fit: contain;
    }
  }
  &-del {
    color: #f56c6c;
  }
  &-devicename {
    color: #3073f4;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
