<template>
  <div class="user">
    <div class="flex">
      <div class="user-list">
        <label>联系人：</label><span>{{ data.linkMan }}</span>
      </div>
      <div class="user-list margin-l">
        <label>联系电话：</label><span>{{ data.linkPhone }}</span>
      </div>
    </div>
    <div class="user-list">
      <label>收货地址：</label>
      <span>{{ data.addressProvince }}</span>
      <span>{{ data.addressCity }}</span
      ><span>{{ data.addressDistrict }}</span
      ><span>{{ data.addressDetailed }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
<style lang="scss" scoped>
.user {
  &-list {
    margin-bottom: 10px;
    > label {
      color: #909399;
    }
  }
}
.margin-l {
  margin-left: 40px;
}
</style>
