<template>
  <div class="notice flex flex-row">
    <div class="notice-title">联盟公告</div>
    <div>
      <div v-for="(item, index) in data" :key="index">
        {{ item.noticeTitle }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.notice {
  border: 1px solid #b2d6ea;
  border-radius: 5px;
  background-color: #dff1fb;
  margin-bottom: 15px;
  padding: 15px;
  &-title {
    width: 100px;
  }
}
</style>
