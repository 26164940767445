<template>
  <el-dialog title="查看物流信息" :visible.sync="dialogVisible" width="40%">
    <div class="lists" v-loading="loading">
      <el-timeline v-if="lists.length">
        <el-timeline-item
          v-for="(item, index) in lists"
          :key="index"
          :timestamp="item.ftime"
        >
          {{ item.context }}
        </el-timeline-item>
      </el-timeline>
      <div v-else>没有物流信息</div>
    </div>
  </el-dialog>
</template>
<script>
import { getOrderKuaidiData } from "@/api/userCenter";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {
      lists: [],
      loading: false,
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    async getDetail() {
      this.loading = true;
      const { orderExpressCompany, orderExpressNum } = this.order;
      const { data } = await this.$axios
        .post(getOrderKuaidiData, {
          orderExpressCompany,
          orderExpressNum,
        })
        .finally(() => {
          this.loading = false;
        });
      this.lists = data;
    },
  },
};
</script>
<style lang="scss" scoped>
.lists {
  min-height: 200px;
  max-height: 400px;
  overflow: scroll;
  padding: 0 20px;
}
</style>
