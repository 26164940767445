<template>
  <div class="cor">
    <div class="cor-inner">
      <BlockHeader title="培训资源" />
      <div class="flex flex-row">
        <div class="flex-1">
          <div class="cor-title">培训课件</div>
          <div>
            <div class="flex flex-row flex-wrap">
              <CourseItem
                :class="{
                  isRight: (index + 1) % 3 === 0,
                  isTop: index + 1 < 4,
                }"
                v-for="(course, index) in courses"
                class="cor-item"
                :key="index"
                :data="course"
              />
            </div>
          </div>
        </div>
        <div class="cor-pro">
          <div class="cor-title center">培训书籍</div>
          <Product
            class="cor-product"
            v-for="(item, index) in superProduct"
            :key="index"
            :data="item"
            :class="{ isBottom: index + 1 == 3 }"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { trainList } from "@/api/onlineTrain";
import CourseItem from "@/components/CourseItem/index.vue";
import Product from "./Product";
import { superProductList } from "@/api/home";
import BlockHeader from "../BlockHeader.vue";
export default {
  components: {
    CourseItem,
    Product,
    BlockHeader,
  },
  data() {
    return {
      coursePage: {
        pageIndex: 1,
        pageSize: 6,
      },
      courses: [],
      superProduct: [],
      superPage: {
        pageIndex: 1,
        pageSize: 3,
      },
    };
  },
  created() {
    this.getClasses();
    this.getProduct();
  },
  methods: {
    // 培训课程
    async getClasses() {
      const data = await this.$axios.post(trainList, this.coursePage);
      this.courses = data[0].onlineTrainCourseListResponds.splice(0, 6);
    },
    // 优质产品
    async getProduct() {
      const { list } = await this.$axios.post(superProductList, this.superPage);
      this.superProduct = list;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
@import "@/style/common";
.cor {
  padding-top: 10px;
  padding-bottom: 30px;
  background: #f0f0f8;
  &-inner {
    width: $max-width;
    margin: 0 auto;
  }
  &-pro {
    width: 294px;
    margin-left: 18px;
  }
  &-title {
    font-size: 18px;
    color: #383838;
    margin-bottom: 17px;
    &.center {
      text-align: center;
    }
  }
  &-item {
    margin-right: 18px;
    &.isTop {
      margin-bottom: 17px;
    }
    &.isRight {
      margin-right: 0px;
    }
  }
  &-product {
    margin-bottom: 16px;
    &.isBottom {
      margin-bottom: 0;
    }
  }
}
</style>
