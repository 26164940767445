<template>
  <div class="form">
    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
      <div class="form-label flex flex-between">
        <div class="form-baseTitle">实验室基本信息</div>
        <div>
          <el-button
            type="primary"
            plain
            size="small"
            @click="savaBtn"
            :loading="btnLoading"
            >保存</el-button
          >
        </div>
      </div>
      <div class="form-block">
        <el-form-item label="单位名称 " prop="laboratoryName">
          <el-input
            v-model="form.laboratoryName"
            placeholder="请输入单位名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业邮箱" prop="laboratoryEmail">
          <el-input
            v-model="form.laboratoryEmail"
            placeholder="请输入企业邮箱"
          ></el-input>
        </el-form-item>
        <div class="flex">
          <el-form-item
            label="详细地址"
            prop="laboratoryLocation"
            class="address"
          >
            <el-cascader
              v-model="form.laboratoryLocation"
              :options="cityOptions"
              filterable
              clearable
              placeholder="请选择省、市、区"
              ref="cityCascader"
            ></el-cascader>
          </el-form-item>
          <el-form-item
            label=""
            prop="laboratoryAddress"
            label-width="0"
            class="flex-1"
          >
            <el-input
              v-model="form.laboratoryAddress"
              placeholder="请输入详细地址"
            ></el-input>
          </el-form-item>
        </div>
      </div>
      <el-form-item label="实验室logo" prop="laboratoryLogo">
        <div class="flex">
          <div>
            <ImgUpload
              @onSuccess="logoUploadSuccess"
              :imgs="form.laboratoryLogo"
            />
          </div>
          <div class="form-logoTip flex flex-column flex-right">
            <div>建议图片上传尺寸95*95，后缀为JPG/JEPG/PNG格式的图片</div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="公司图片">
        <ImgUpload
          :limit="4"
          :imgs="form.laboratoryBanner"
          @onSuccess="bannerUploadSuccess"
        />
        <!-- <div class="form-imgTip">建议图片上传尺寸95*95，后缀为JPG/JEPG/PNG格式的图片,做多</div> -->
      </el-form-item>
      <el-form-item label="实验室简介" prop="laboratoryBrief">
        <el-input
          v-model="form.laboratoryBrief"
          type="textarea"
          rows="5"
          placeholder="请输入实验室简介"
        ></el-input>
      </el-form-item>
      <el-form-item label="特色检测能力情况说明" prop="specialAbility">
        <el-input
          v-model="form.specialAbility"
          type="textarea"
          rows="5"
          placeholder="请输入特色检测能力情况"
        ></el-input>
      </el-form-item>
      <el-form-item label="实验室检测业务情况说明" prop="laboratoryCheckInfo">
        <el-input
          v-model="form.laboratoryCheckInfo"
          type="textarea"
          rows="5"
          placeholder="请输入实验室检测业务情况"
        ></el-input>
      </el-form-item>
      <div class="form-label">业务联系人信息</div>
      <div class="flex flex-row">
        <el-form-item
          label="联系人姓名"
          prop="laboratoryContactsName"
          class="flex-1"
        >
          <el-input
            v-model="form.laboratoryContactsName"
            placeholder="请输入业务联系人"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="联系电话"
          prop="laboratoryContactsPhone"
          class="flex-1"
        >
          <el-input
            v-model="form.laboratoryContactsPhone"
            placeholder="请输入联系电话"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>
<script>
import ImgUpload from "@/components/ImgUpload/index";
import { regionData } from "element-china-area-data";
import { getUserLabInfo } from "@/api/userCenter";
import { updateLaboratory } from "@/api/lab";
export default {
  components: {
    ImgUpload,
  },
  data() {
    return {
      cityOptions: regionData,
      btnLoading: false,
      form: {
        laboratoryName: "",
        laboratoryEmail: "",
        laboratoryLocation: [],
        laboratoryAddress: "",
        laboratoryLogo: "",
        laboratoryBanner: "",
        laboratoryBrief: "",
        specialAbility: "",
        laboratoryCheckInfo: "",
        laboratoryContactsName: "",
        laboratoryContactsPhone: "",
        laboratoryId: null,
      },
      rules: {},
    };
  },
  created() {
    this.getUserLabDetail();
  },
  methods: {
    async getUserLabDetail() {
      const data = await this.$axios.get(getUserLabInfo);
      const {
        laboratoryLocationProvinceCode,
        laboratoryLocationCityCode,
        laboratoryLocationCountyCode,
      } = data;
      Object.assign(data, {
        laboratoryLocation: [
          laboratoryLocationProvinceCode,
          laboratoryLocationCityCode,
          laboratoryLocationCountyCode,
        ],
      });
      this.form = data;
    },
    logoUploadSuccess(file) {
      this.form.laboratoryLogo = file;
    },
    bannerUploadSuccess(file) {
      this.form.laboratoryBanner = file;
    },
    async savaBtn() {
      const { laboratoryLocation, ...rest } = this.form;
      const [
        laboratoryLocationProvinceCode,
        laboratoryLocationCityCode,
        laboratoryLocationCountyCode,
      ] = laboratoryLocation;
      const [
        laboratoryLocationProvince,
        laboratoryLocationCity,
        laboratoryLocationCounty,
      ] = this.$refs.cityCascader.getCheckedNodes()[0].pathLabels;
      const params = {
        ...rest,
        laboratoryLocationProvinceCode,
        laboratoryLocationCityCode,
        laboratoryLocationCountyCode,
        laboratoryLocationProvince,
        laboratoryLocationCity,
        laboratoryLocationCounty,
        modifyType: "0", // 修改类型(0-修改实验室信息；1-修改资质) 修改时必填
      };
      this.btnLoading = true;
      await this.$axios.post(updateLaboratory, params).finally(() => {
        this.btnLoading = false;
      });
      this.$message.success("保存成功");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable.scss";
.form {
  &-label {
    border-bottom: 1px solid $border-color;
    padding-bottom: 8px;
    margin: 0 20px 20px 20px;
    font-size: 1.14rem;
    font-weight: 600;
  }
  &-baseTitle {
    padding-top: 10px;
  }
  &-block {
    width: 80%;
  }
  &-logoTip {
    color: $font-color-gray;
    font-size: 0.85rem;
    margin-left: 10px;
    height: 148px;
  }
}
</style>
