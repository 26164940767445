import AuthMenu from "@/constant/userAuth";
export function filterAuth(auths) {
  const resultAuth = [];
  if (auths.length) {
    auths.forEach((item) => {
      const { firstJurisdiction, secondaryJurisdiction } = item;
      let inResultIndex = null;
      inResultIndex = resultAuth.findIndex(({ auth }) => {
        return auth === firstJurisdiction;
      });
      let pAuthIndex = null;
      pAuthIndex = AuthMenu.findIndex(({ auth }) => {
        return auth === firstJurisdiction;
      });
      if (pAuthIndex > -1) {
        const { title, auth } = AuthMenu[pAuthIndex];
        if (inResultIndex < 0) {
          resultAuth.push({ title, auth, child: [] });
          inResultIndex = resultAuth.length - 1;
        }
        const childIndex = AuthMenu[pAuthIndex].child.findIndex(({ auth }) => {
          return auth === secondaryJurisdiction;
        });
        if (childIndex > -1) {
          resultAuth[inResultIndex].child.push(
            AuthMenu[pAuthIndex].child[childIndex]
          );
        }
      }
    });
  }
  return resultAuth;
}
