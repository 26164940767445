<template>
  <div>
    <HeadBar /><!--顶部bar-->
    <!--顶部logo、检索-->
    <HeadNav /><!-- 顶部导航-->
    <div class="lab">
      <div class="lab-inner flex flex-row">
        <div class="list flex-1" v-loading="loading">
          <div class="flex flex-row flex-wrap">
            <div
              class="list-item flex flex-column flex-between pointer"
              :class="{ isRight: (index + 1) % 3 === 0 }"
              v-for="(item, index) in lists"
              :key="index"
              @click="toDetail(item)"
            >
              <div>
                <div class="list-logo">
                  <img :src="item.laboratoryLogo" />
                </div>
                <div class="list-name ellipsis">
                  {{ item.laboratoryName }}
                </div>
                <div class="list-address ellipsis">
                  <label>地址：</label>
                  <span>{{ item.laboratoryLocationProvince }}</span>
                  <span>{{ item.laboratoryLocationCity }}</span>
                  <span>{{ item.laboratoryLocationCounty }}</span>
                  <span>{{ item.laboratoryAddress }}</span>
                </div>
              </div>
              <div class="data">
                <div class="data-inner flex flex-row">
                  <div class="flex-1">
                    <div class="data-num">{{ item.qualificationSum }}</div>
                    <div class="date-label">资质</div>
                  </div>
                  <div class="flex-1 data-line">
                    <div class="data-num">{{ item.waresSum }}</div>
                    <div>设备</div>
                  </div>
                  <div class="flex-1">
                    <div class="data-num">{{ item.abilitySum }}</div>
                    <div>能力</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hot">
          <HotLab />
        </div>
      </div>
      <!-- 分页-->
      <Pagination
        :total="totalItem"
        :page="page"
        @pageIndexChange="handlePageChange"
      />
    </div>

    <FootBar class="footer" />
  </div>
</template>
<script>
import { labList } from "@/api/lab";
import Pagination from "@/components/Pagination/index";
import HotLab from "./components/HotLab.vue";
export default {
  components: {
    Pagination,
    HotLab,
  },
  data() {
    return {
      totalItem: 0,
      lists: [],
      page: {
        pageIndex: 1,
        pageSize: 9,
      },
      loading: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handlePageChange(index) {
      this.page.pageIndex = index;
      this.getList();
    },
    async getList() {
      this.loading = true;
      const { list, totalItem } = await this.$axios
        .get(labList, this.page)
        .finally(() => {
          this.loading = false;
        });
      this.lists = list;
      this.totalItem = totalItem;
    },
    toDetail({ laboratoryId }) {
      this.$jump.push({ name: "labDetail", query: { id: laboratoryId } });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
$item-h: 120px;
.lab {
  background: #ebeef4;
  padding-bottom: 20px;
  padding-top: 33px;
  &-inner {
    width: $max-width;
    margin: 0 auto;
    min-height: 400px;
    margin-bottom: 20px;
  }
}
.hot {
  width: 340px;
}

.footer {
  padding-top: 0;
}
.list {
  &-item {
    width: 276px;
    height: 246px;
    background: #ffffff;
    border-radius: 3px;
    margin-bottom: 16px;
    margin-right: 17px;
    &.isRight {
      margin-right: 0;
    }
  }
  &-logo {
    text-align: center;
    img {
      width: 184px;
      height: 90px;
      object-fit: contain;
    }
  }
  &-name {
    font-size: 14px;
    color: #424242;
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
  }
  &-address {
    font-size: 12px;
    color: #7c7c7c;
    text-align: center;
    width: 100%;
    padding: 0 20px;
  }
}
.data {
  padding: 8px 10px;
  &-inner {
    border-top: 1px solid #e6e6e6;
    text-align: center;
    padding-top: 10px;
  }
  &-num {
    font-size: 13px;
    font-weight: bold;
    color: #1f66f6;
  }
  &-label {
    font-size: 12px;
    color: #333333;
  }
  &-line {
    position: relative;
    &::before {
      position: absolute;
      left: 0;
      top: 10%;
      content: "";
      display: inline-block;
      width: 1px;
      height: 80%;
      background: #e6e6e6;
    }
    &::after {
      position: absolute;
      right: 0;
      top: 10%;
      content: "";
      display: inline-block;
      width: 1px;
      height: 80%;
      background: #e6e6e6;
    }
  }
}
</style>
