<template>
  <div class="new">
    <div class="new-head">最新服务</div>
    <div
      v-for="(item, index) in goods"
      :key="index"
      class="new-list flex flex-row pointer"
      @click="serviceClick(item)"
    >
      <div class="new-cover">
        <img :src="item.imgsrc" />
      </div>
      <div class="flex-1">
        <div class="ellipsis new-name">{{ item.devicename }}</div>
        <div class="new-brand">
          <label class="new-pre">品牌:</label>
          <span>{{ item.brand }}</span>
        </div>
        <div class="flex flex-row" v-if="item.minPrince">
          <div class="new-price">
            <span
              v-if="
                item.face ||
                !item[item.discount ? 'preferentialPrice' : 'minPrince']
              "
              >面议</span
            >
            <template v-else>
              <span>
                ￥{{ item[item.discount ? "preferentialPrice" : "minPrince"] }}
              </span>
              <span> / {{ unitMap[+item.unit] }}</span>
            </template>
          </div>
        </div>
        <div v-else class="new-face">面议</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "newService",
  props: {
    goods: {
      type: Array,
      default: () => [],
    },
    unitMap: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  methods: {
    serviceClick(data) {
      this.$jump.push({ name: "deviceDetail", query: { id: data.id } });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.new {
  width: 290px;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  margin-bottom: 15px;
  overflow: hidden;
  &-head {
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid $border-color;
    padding-left: 15px;
    font-size: 1.14rem;
    font-weight: bold;
  }
  &-list {
    padding: 15px;
  }
  &-cover {
    width: 80px;
    height: 80px;
    border-radius: $border-radius;
    margin-right: 10px;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-name {
    font-size: 15px;
    max-width: 160px;
  }
  &-brand {
    margin-bottom: 10px;
  }
  &-price {
    color: $price-color;
    text-align: right;
    > span:last-child {
      font-size: 0.85rem;
    }
  }
  &-face {
    color: $price-color;
  }
  &-pre {
    color: #737373;
    margin-right: 10px;
  }
}
</style>
