<template>
  <div>
    <div
      v-for="(pro, index) in projects"
      :key="index"
      class="pro"
      :class="{ last: index == projects.length - 1 }"
    >
      <div class="pro-head flex flex-between">
        <div class="flex">
          <div>项目名称：</div>
          <div>
            <el-input
              v-model.trim="pro.checkmallFirstRankName"
              placeholder="请输入项目名称"
              size="small"
              class="pro-name"
              :maxlength="50"
            />
          </div>
        </div>
        <div>
          <el-button
            type="danger"
            plain
            size="mini"
            @click="delServiceClick(index)"
            :disabled="projects.length == 1"
            >删除服务项目</el-button
          >
          <el-button
            type="primary"
            plain
            size="mini"
            @click="addChildProjectClick(index)"
            >新增子项目</el-button
          >
        </div>
      </div>
      <div class="pro-table">
        <el-table :data="pro.secondRank" border>
          <el-table-column prop="checkmallSecondRankName" label="子项目名称">
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model.trim="scope.row.checkmallSecondRankName"
                :maxlength="50"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="serviceCycle"
            label="服务周期(天)"
            width="110px"
          >
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model.trim="scope.row.serviceCycle"
                :maxlength="5"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="serviceUnitType"
            label="计价单位"
            width="110px"
          >
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.serviceUnitType"
                clearable
                size="mini"
              >
                <el-option
                  v-for="dict in serverUnitTypeOptions"
                  :key="dict.dictValue"
                  :label="dict.dictLabel"
                  :value="dict.dictValue"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            prop="price"
            label="是否面议"
            width="80px"
            align="center"
          >
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.face" size="mini"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="price" label="规格价格(元)" width="120px">
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model.trim="scope.row.price"
                :maxlength="9"
                v-if="!scope.row.face"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="discountStatus" label="折扣率" width="110px">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.discountStatus"
                clearable
                size="mini"
                v-if="!scope.row.face"
              >
                <el-option
                  v-for="dict in discountStatusOptions"
                  :key="dict.dictValue"
                  :label="dict.dictLabel"
                  :value="dict.dictValue"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="55px">
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="delChildProject(index, scope)"
                class="pro-delChild"
                :disabled="pro.secondRank.length == 1"
                >移除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="pro-add">
      <el-button type="primary" plain size="mini" @click="addServiceClick"
        >新增服务项目</el-button
      >
    </div>
  </div>
</template>
<script>
import { SERVICE_UNIT_TYPE, SERVICE_DISCOUNT_TYPE } from "@/constant/dict";
const defaultModel_Rank = {
  checkmallSecondRankName: "",
  price: "",
  serviceCycle: "",
  serviceUnitType: "",
  discountStatus: "",
};
const defaultModel = {
  checkmallFirstRankName: "",
};

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      projects: [{ ...defaultModel, secondRank: [{ ...defaultModel_Rank }] }],
      serverUnitTypeOptions: [],
      discountStatusOptions: [],
      delPids: [],
      delChildPids: [],
    };
  },
  watch: {
    data(val) {
      this.projects = val;
    },
  },
  created() {
    this.getDicts();
    // if (this.data && this.data.length) {
    //   this.projects = this.data;
    // }
    // console.log(this.projects);
  },
  methods: {
    async getDicts() {
      const units = await this.$store.dispatch(
        "getDictData",
        SERVICE_UNIT_TYPE
      );
      this.serverUnitTypeOptions = units;
      const discounts = await this.$store.dispatch(
        "getDictData",
        SERVICE_DISCOUNT_TYPE
      );
      this.discountStatusOptions = discounts;
    },
    // 新增服务
    addServiceClick() {
      console.log(this.validate());
      if (!this.validate()) return;
      this.projects.push({
        ...defaultModel,
        secondRank: [{ ...defaultModel_Rank }],
      });
    },
    validate() {
      const pro = this.projects;
      const len = pro.length;
      let flag = true;
      for (let j = 0; j < len; j++) {
        if (!pro[j].checkmallFirstRankName) {
          this.$message.error(`项目名称不能为空！`);
          flag = false;
          break;
        }
        const rank = pro[j].secondRank;
        const rankLen = rank.length;
        for (let i = 0; i < rankLen; i++) {
          const {
            checkmallSecondRankName,
            price,
            serviceCycle,
            serviceUnitType,
            face,
          } = rank[i];
          if (!checkmallSecondRankName) {
            this.$message.error(`子项目名称不能为空！`);
            flag = false;
            break;
          }
          if (!/^\+?[1-9][0-9]{0,4}$/.test(serviceCycle)) {
            this.$message.error(`服务周期为正整数`);
            flag = false;
            break;
          }
          if (!serviceUnitType) {
            this.$message.error(`计价单位不能为空`);
            flag = false;
            break;
          }

          if (!face && !/^[0-9]+(.[0-9]{1,2})?$/.test(price)) {
            this.$message.error(`规格价格为数字，且最多两位小数`);
            flag = false;
            break;
          }
        }
      }
      return flag;
    },
    // 删除服务
    delServiceClick(tableIndex) {
      const { checkmallFirstRankId, secondRank } = this.projects[tableIndex];
      if (checkmallFirstRankId && this.isEdit) {
        this.delPids.push(checkmallFirstRankId);
        secondRank.forEach((item) => {
          if (item.checkmallSecondRankId) {
            this.delChildPids.push(item.checkmallSecondRankId);
          }
        });
      }
      this.projects.splice(tableIndex, 1);
    },
    // 新增子项目
    addChildProjectClick(tableIndex) {
      this.projects[tableIndex].secondRank.push({ ...defaultModel_Rank });
    },
    delChildProject(tableIndex, scope) {
      const child = this.projects[tableIndex].secondRank[scope.$index];
      if (this.isEdit && child.checkmallSecondRankId) {
        this.delChildPids.push(child.checkmallSecondRankId);
      }
      this.projects[tableIndex].secondRank.splice(scope.$index, 1);
    },
    // 父组件调用获取数据的方法
    getData() {
      // 校验数据
      if (this.validate()) {
        return {
          project: this.projects,
          deleteFirstRankIds: this.delPids,
          deleteSecondRankIds: this.delChildPids,
        };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.pro {
  border-radius: $border-radius;
  margin-bottom: 10px;
  &.last {
    margin-bottom: 0px;
  }
  &-head {
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    border-top: 1px solid $border-color;
    background: #f5f5f5;
    padding: 0px 10px;
  }
  &-table {
    /deep/ .el-table thead th {
      padding: 0;
    }
    /deep/ .el-table td {
      padding: 5px 0;
    }
    /deep/ .el-table__body tr:hover > td {
      background-color: white;
    }
  }
  &-name {
    width: 400px;
  }
  &-delChild {
    color: #f56c6c;
  }
}
</style>
