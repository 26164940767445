<template>
  <div class="mall">
    <QueryForm
      :orderStatus="orderStatus"
      @queryBtnClick="queryBtnClick"
      :loading="listLoading"
    />
    <div class="mall-itemWrapper" v-loading="listLoading">
      <div v-for="(item, index) in lists" :key="index" class="flex mall-item">
        <div class="mall-left flex-1">
          <div class="mall-title">
            <span class="mall-time">{{ item.createTime }}</span>
            <label>订单编号：</label>
            <span>{{ item.productmallOrderCode }}</span>
          </div>
          <div class="mall-good">
            <Goods :goods="item.orderRanks" :totalMoney="item.orderPrice" />
          </div>
        </div>
        <div class="mall-center">
          <div class="mall-title">订单状态</div>
          <div class="mall-step">
            <OrderFlow
              :active="+item.productmallOrderStatus"
              :orderStatusMap="orderStatusMap"
            />
          </div>
        </div>
        <div class="mall-right">
          <div class="mall-title">操作</div>
          <div class="mall-btns">
            <!--1: "待支付",2: "已取消",3: "已过期",4: "已支付",6: "待收货",7: "已完成"-->
            <!-- <div v-else-if="item.status == 3">
            <el-button size="mini" type="success" plain>查看物流</el-button>
          </div> -->
            <div>
              <el-button
                size="mini"
                type="primary"
                plain
                @click="orderView(item)"
                >查看订单</el-button
              >
            </div>
            <div v-if="+item.productmallOrderStatus == 4">
              <el-button
                size="mini"
                type="danger"
                plain
                @click="addLogistics(item)"
                >添加物流信息</el-button
              >
            </div>
            <div v-if="+item.productmallOrderStatus >= 4">
              <el-button
                size="mini"
                type="success"
                plain
                @click="logisticsViewBtnClick(item)"
                >查看物流信息</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="mall-null" v-if="!lists.length">未查到数据</div>
    </div>
    <!-- 分页-->
    <Pagination
      :total="totalItem"
      :page="page"
      @pageIndexChange="handlePageChange"
    />
    <el-drawer
      title="订单详情"
      :visible.sync="showDrawer"
      size="800px"
      class="drawer"
      :wrapperClosable="false"
    >
      <OrderDetail
        :isLab="true"
        :orderId="currentData.productmallOrderId"
        v-if="showDrawer"
      />
    </el-drawer>
    <AddLogisticsDialog
      v-model="addLogisticsVisible"
      v-if="addLogisticsVisible"
      :order="currentData"
      :orderId="currentData.productmallOrderId"
    />
    <ViewLogisticsDialog
      v-model="viewLogisticsVisible"
      v-if="viewLogisticsVisible"
      :order="currentData"
    />
  </div>
</template>
<script>
import { GOODS_ORDER_STATUS } from "@/constant/dict";
import OrderDetail from "@/components/OrderDetail";
import Goods from "@/components/OrderDetail/Goods";
import OrderFlow from "@/components/OrderFlow/Order";
import QueryForm from "./components/QueryForm";
import { mallGoodOrderList } from "@/api/userCenter";
import Pagination from "@/components/Pagination/index";
import AddLogisticsDialog from "@/components/Logistics/AddLogistics";
import ViewLogisticsDialog from "@/components/Logistics/ViewLogistics";
export default {
  components: {
    QueryForm,
    OrderDetail,
    OrderFlow,
    Goods,
    Pagination,
    AddLogisticsDialog,
    ViewLogisticsDialog,
  },
  data() {
    return {
      showDrawer: false, // 订单详情弹窗的显示与隐藏
      addLogisticsVisible: false, // 增加物流信息弹窗的显示与隐藏
      viewLogisticsVisible: false, // 查看物流弹窗的显示与隐藏
      lists: [],
      totalItem: 0,
      page: {
        pageIndex: 1,
        pageSize: 4,
      },
      orderStatus: [], // 订单状态数据字典数据
      orderStatusMap: {}, // 订单状态数据字典map数据
      queryForm: {}, // 列表条件查询参数
      currentData: {}, // 当前选择的表格中的某行数据
      listLoading: false,
    };
  },
  created() {
    this.getDcits();
    this.getList();
  },
  methods: {
    queryBtnClick(params) {
      this.queryForm = params;
      this.page.pageIndex = 1;
      this.getList();
    },
    // 分页查询
    handlePageChange(index) {
      this.page.pageIndex = index;
      this.getList();
    },
    // 获取数据字典
    async getDcits() {
      const data = await this.$store.dispatch(
        "getDictData",
        GOODS_ORDER_STATUS
      );
      const statusMap = {};
      data.forEach((item) => {
        statusMap[item.dictValue] = item.dictLabel;
      });
      this.orderStatusMap = statusMap;
      this.orderStatus = data;
      // console.log(statusMap);
    },
    // 获取列表
    async getList() {
      this.listLoading = true;
      const { time, ...rest } = this.queryForm;
      const params = {
        ...this.page,
        ...rest,
      };
      if (time && time.length) {
        Object.assign(params, {
          queryStartTime: time[0],
          queryEndTime: time[1],
        });
      }
      const { list, totalItem } = await this.$axios
        .post(mallGoodOrderList, params)
        .finally(() => {
          this.listLoading = false;
        });
      this.lists = list || [];
      this.totalItem = totalItem;
    },
    // 查看订单
    orderView(item) {
      this.currentData = item;
      this.showDrawer = true;
    },
    // 添加物流信息
    addLogistics(item) {
      this.currentData = item;
      this.addLogisticsVisible = true;
    },
    // 点击查看物流信息接口
    logisticsViewBtnClick(item) {
      this.currentData = item;
      this.viewLogisticsVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.drawer {
  /deep/ .el-drawer__header {
    margin-bottom: 20px;
  }
}
.mall {
  padding: 10px;
  &-null {
    text-align: center;
    padding-top: 20px;
    color: $font-color-gray;
  }
  &-itemWrapper {
    min-height: 450px;
  }
  &-item {
    border: 1px solid $border-color;
    margin-bottom: 10px;
  }
  &-title {
    height: 40px;
    line-height: 40px;
    background: $head-bar-bg;
    padding-left: 10px;
    border-bottom: 1px solid $border-color;
  }
  &-time {
    font-weight: bold;
    margin-right: 15px;
  }
  &-left {
    border-right: 1px solid $border-color;
  }
  &-center {
    border-right: 1px solid $border-color;
    width: 320px;
  }
  &-step {
    padding: 15px;
  }
  &-right {
    width: 120px;
  }
  &-btns {
    padding-top: 10px;
    text-align: center;
    /deep/ .el-button {
      width: 100px;
    }
    > div {
      margin-bottom: 10px;
    }
  }
}
.good {
  border-bottom: 1px solid $border-color;
  padding: 10px;
  &-cover {
    width: 100px;
    height: 100px;
    // border: 1px solid $border-color;
    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &-detail {
    > div {
      padding-left: 10px;
      margin-bottom: 10px;
      > label {
        color: #909399;
      }
    }
  }
  &-spec {
    margin-right: 15px;
  }
  &-price {
    color: #f56c6c;
  }
}
</style>
