<template>
  <div class="dizhi">
    <div class="dizhi-operation">
      <el-button type="primary" plain size="small" @click="addAddress"
        >新增</el-button
      >
    </div>
    <div>
      <el-table :data="lists" border v-loading="tableLoading">
        <el-table-column prop="linkMan" label="姓名" width="80px">
        </el-table-column>
        <el-table-column prop="linkPhone" label="手机号码" width="120px">
        </el-table-column>
        <el-table-column prop="linkEmail" label="邮箱"> </el-table-column>
        <el-table-column prop="linkPostcode" label="邮编" width="80px">
        </el-table-column>
        <el-table-column prop="companyName" label="单位名称"> </el-table-column>
        <el-table-column label="地址" min-width="180px">
          <template slot-scope="scope">
            <div>
              <span>{{ scope.row.addressProvince }}</span>
              <span>{{ scope.row.addressCity }}</span>
              <span>{{ scope.row.addressDistrict }}</span>
              <span>{{ scope.row.addressDetailed }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="90px">
          <template slot-scope="scope">
            <div>
              <span @click="updateAddress(scope.row)" class="btn-update pointer"
                >修改</span
              >
              <span @click="delAddress(scope.row)" class="btn-del pointer"
                >删除</span
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Dialog
      v-model="dialogVisible"
      :is-edit="dialogIsEdit"
      :cur-id="selectAddressId"
      @refreshAddress="getAddressList"
    />
  </div>
</template>
<script>
import Dialog from "@/components/AddressDialog/index";
import { addressList, delAddress } from "@/api/userCenter";
export default {
  components: {
    Dialog,
  },
  data() {
    return {
      lists: [],
      dialogVisible: false,
      dialogIsEdit: false,
      selectAddressId: null,
      tableLoading: false,
    };
  },
  created() {
    this.getAddressList();
  },
  methods: {
    async getAddressList() {
      this.tableLoading = true;
      const data = await this.$axios.get(addressList).finally(() => {
        this.tableLoading = false;
      });
      this.lists = data;
    },
    // 新增地址
    addAddress() {
      this.dialogIsEdit = false;
      this.dialogVisible = true;
    },
    // 修改地址
    updateAddress(row) {
      this.dialogIsEdit = true;
      this.selectAddressId = row.userAddressId;
      this.dialogVisible = true;
    },
    // 删除
    async delAddress({ userAddressId }) {
      await this.$confirm("确认删除该地址？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      await this.$axios.post(delAddress, { userAddressIds: [userAddressId] });
      this.$message.success("删除成功");
      this.getAddressList();
    },
  },
};
</script>
<style lang="scss" scoped>
.dizhi {
  padding: 10px;
  &-operation {
    text-align: right;
    margin-bottom: 10px;
  }
}
.btn {
  &-update {
    color: #409eff;
    margin-right: 10px;
  }
  &-del {
    color: #f56c6c;
  }
}
</style>
