<template>
  <div class="ext">
    <div class="title title-big">
      国家新材料测试评价平台-稀土行业中心专家咨询委员会名单
    </div>
    <!-- <div class="title title-small">专家委员会名单</div> -->
    <el-table :data="tableData" border class="ext-table"
      ><el-table-column
        type="index"
        width="50"
        label="序号"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="expertName"
        label="姓名"
        width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="expertProfessionalField"
        label="从事专业领域"
        width="250"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="expertCompany" label="工作单位" align="center">
      </el-table-column>
      <el-table-column
        prop="expertPostTypeStr"
        label="本会职务"
        width="100"
        align="center"
      >
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { selectCommittees } from "@/api/baseIntroduction";
export default {
  data() {
    return {
      tableData: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      const res = await this.$axios.get(selectCommittees);
      this.tableData = res;
    },
  },
};
</script>
<style lang="scss" scoped>
.ext {
  &-table {
    width: 100%;
  }
}
.title {
  text-align: center;
  &-big {
    font-size: 22px;
    margin-bottom: 20px;
  }
  &-small {
    font-size: 16px;
    margin-bottom: 5px;
  }
}
</style>
