<template>
  <el-dialog
    :title="`${isEdit ? '修改' : '新增'}收货地址`"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="600px"
    class="form"
    @open="dialogOpen"
  >
    <el-form :model="form" label-width="110px" :rules="rules" ref="ruleForm">
      <el-form-item
        label="地址信息"
        prop="laboratoryLocation"
        class="form-address"
      >
        <el-cascader
          v-model="form.laboratoryLocation"
          :options="cityOptions"
          @change="handleAddressChange"
          filterable
          clearable
          placeholder="请选择省、市、区"
          ref="cityCascader"
        ></el-cascader>
      </el-form-item>
      <el-form-item
        label="详细地址"
        prop="addressDetailed"
        class="form-detailAddress"
      >
        <el-input
          v-model="form.addressDetailed"
          placeholder="请输入详细地址"
          type="textarea"
          :rows="2"
          :maxlength="100"
        ></el-input>
      </el-form-item>
      <el-form-item label="订单下单单位" prop="companyName">
        <el-input
          v-model="form.companyName"
          placeholder="请输入订单下单单位"
          :maxlength="30"
        ></el-input>
      </el-form-item>
      <el-form-item label="下单人姓名" prop="linkMan">
        <el-input
          v-model.trim="form.linkMan"
          placeholder="请输入下单人姓名"
          :maxlength="15"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号码" prop="linkPhone">
        <el-input
          v-model.trim="form.linkPhone"
          placeholder="请输入手机号码"
        ></el-input>
      </el-form-item>
      <el-form-item label="下单联系邮箱" prop="linkEmail">
        <el-input
          v-model.trim="form.linkEmail"
          placeholder="请输入下单联系邮箱"
        ></el-input>
      </el-form-item>
      <el-form-item label="下单联系邮编" prop="linkPostcode">
        <el-input
          v-model.trim="form.linkPostcode"
          placeholder="请输入下单联系邮编"
          :maxlength="6"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="saveAddress">保 存</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { regionData } from "element-china-area-data";
import { addressDetail, saveAddress } from "@/api/userCenter";
const DefaultModel = {
  laboratoryLocation: [],
  addressDetailed: "",
  companyName: "",
  linkMan: "",
  linkPhone: "",
  linkEmail: "",
  linkPostcode: "",
};

export default {
  name: "addressDialog",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    curId: {
      type: Number,
      default: null,
    },
  },
  data() {
    // const validateEmail = (rule, value, callback) => {
    //   const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    //   if (!reg.test(value)) {
    //     callback(new Error("请输入正确的邮箱地址"));
    //   }
    //   callback();
    // };
    const validatePhone = (rule, value, callback) => {
      const reg =
        /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入正确的手机号码"));
      }
      callback();
    };
    return {
      form: { ...DefaultModel },
      rules: {
        laboratoryLocation: [
          {
            required: true,
            message: "请选择省、市、区",
            trigger: "change",
          },
        ],
        addressDetailed: [
          {
            required: true,
            message: "请输入详细地址",
            trigger: ["blur", "change"],
          },
        ],
        companyName: [
          {
            required: true,
            message: "请输入订单下单单位",
            trigger: ["blur", "change"],
          },
        ],
        linkMan: [
          {
            required: true,
            message: "请输入下单人姓名",
            trigger: ["blur", "change"],
          },
        ],
        linkPhone: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: ["blur", "change"],
          },
          { validator: validatePhone, trigger: "blur" },
        ],
        linkEmail: [
          {
            required: true,
            message: "请输入下单联系邮箱",
            trigger: ["blur", "change"],
          },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: "blur",
          },
          // { validator: validateEmail, trigger: "blur" },
        ],
        linkPostcode: [
          {
            required: false,
            pattern: /^\d{6}$/,
            message: "请输入正确的邮政编码",
            trigger: ["blur", "change"],
          },
          // { validator: validatePostCode, trigger: ["blur", "change"] },
        ],
      },
      cityOptions: regionData,
    };
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created() {},
  methods: {
    handleAddressChange() {},
    async dialogOpen() {
      if (this.isEdit) {
        const data = await this.$axios.get(addressDetail, {
          userAddressId: this.curId,
        });
        const { provinceCode, cityCode, districtCode, ...rest } = data;
        Object.assign(this.form, {
          laboratoryLocation: [
            `${provinceCode}`,
            `${cityCode}`,
            `${districtCode}`,
          ],
          ...rest,
        });
      } else {
        this.form = DefaultModel;
      }
    },
    async saveAddress() {
      await this.$refs.ruleForm.validate();
      const { laboratoryLocation, ...rest } = this.form;
      const [provinceCode, cityCode, districtCode] = laboratoryLocation;
      const [addressProvince, addressCity, addressDistrict] =
        this.$refs.cityCascader.getCheckedNodes()[0].pathLabels;
      const params = {
        ...rest,
        provinceCode,
        cityCode,
        districtCode,
        addressProvince,
        addressCity,
        addressDistrict,
        userRealName: "",
      };
      await this.$axios.post(saveAddress, params);
      this.$message.success("保存成功");
      this.$emit("refreshAddress");
      this.$emit("input", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.form {
  /deep/ .el-dialog__body {
    padding: 10px 20px 0 20px;
  }
  &-address {
    /deep/ .el-cascader {
      width: 300px;
    }
  }
  &-detailAddress {
    /deep/ textarea {
      resize: none;
    }
  }
}
</style>
