<template>
  <div class="deed">
    <QueryForm
      @toQuery="queryBtnClick"
      :load="loading"
      @refresh="refreshList"
    />
    <div class="tableWrapper" v-loading="loading">
      <div
        v-for="(item, index) in tableData"
        :key="index"
        class="list flex flex-row"
      >
        <div class="list-left flex-1">
          <div class="list-title first">
            <div>
              <label>委托单编号：</label>
              <span>{{ item.orderCode }}</span>
            </div>
          </div>
          <OrderBase :row="item" />
        </div>
        <div class="list-center flex flex-column">
          <div class="list-title">委托单流程&状态信息</div>
          <div class="flex-1 flex flex-middle list-flow">
            <div>
              <OrderFlow :row="item" />
              <Status :row="item" :payTypeMap="payTypeMap" />
            </div>
          </div>
        </div>
        <div class="list-right">
          <div class="list-title">操作</div>
          <Operation
            :row="item"
            @viewDeed="viewDeed(item)"
            @showResult="showResult(item)"
            @askFor="askFor(item)"
            @askForResult="askForResult(item)"
            @evaluate="evaluate(item)"
            @addLogistics="addLogistics(item)"
            @viewLogistics="viewLogistics(item)"
          />
        </div>
      </div>
    </div>
    <!-- 分页-->
    <Pagination
      :total="totalItem"
      :page="page"
      @pageIndexChange="handlePageChange"
    />

    <el-drawer
      title="委托单详情"
      :visible.sync="showDrawer"
      size="800px"
      class="drawer"
    >
      <DeedDetail
        v-if="showDrawer"
        :deedId="currentRowData.checkmallOrderMainId"
      />
    </el-drawer>
    <el-drawer
      title="委托单结果查看"
      :visible.sync="showResultDrawer"
      size="800px"
      class="drawer"
    >
      <DeedResult
        v-if="showResultDrawer"
        :deedId="currentRowData.checkmallOrderMainId"
      />
    </el-drawer>
    <!-- 申请申述弹窗 -->
    <AskforDialog v-model="showAskforDialog" :data="currentRowData" />
    <!-- 申请申述结果 -->
    <AskforResultDrawer
      v-model="showAskforResultDialog"
      v-if="showAskforResultDialog"
      :deedId="currentRowData.checkmallOrderMainId"
    />
    <!--去评价弹窗-->
    <EvaluateDialog
      v-model="evaluateVisible"
      v-if="evaluateVisible"
      :data="currentRowData"
      mallOrderType="0"
    />
    <AddLogisticsDialog
      v-model="addLogisticsVisible"
      v-if="addLogisticsVisible"
      :deedId="currentRowData.checkmallOrderMainId"
      :order="currentRowData"
      @addLogSuccess="addLogSuccess"
    />
    <!-- 物流弹窗-->
    <ViewLogisticsDialog
      v-model="viewLogisticsVisible"
      v-if="viewLogisticsVisible"
      :order="orderLogis"
    />
  </div>
</template>
<script>
import { PAY_TYPE } from "@/constant/dict";
import { deedList } from "@/api/userCenter";
import Pagination from "@/components/Pagination/index";
import OrderFlow from "@/components/OrderFlow/Deed";
import DeedDetail from "@/components/DeedDetail"; // 委托单详情
import DeedResult from "@/components/DeedDetail/DeedResult"; // 委托单详情
import EvaluateDialog from "@/components/EvaluateDialog/index";
import AddLogisticsDialog from "@/components/Logistics/AddLogistics";
import ViewLogisticsDialog from "@/components/Logistics/ViewLogistics";
import QueryForm from "./components/QueryForm";
import OrderBase from "./components/OrderBase";
import AskforDialog from "./components/AskforDialog.vue";
import AskforResultDrawer from "./components/AskForResult.vue";

import Operation from "./components/Operation";
import Status from "@/components/DeedStatus/index";

export default {
  components: {
    Pagination,
    QueryForm,
    OrderBase,
    OrderFlow,
    Operation,
    Status,
    DeedDetail,
    DeedResult,
    AskforDialog,
    AskforResultDrawer,
    EvaluateDialog,
    AddLogisticsDialog,
    ViewLogisticsDialog,
  },
  data() {
    return {
      totalItem: 0,
      page: {
        pageIndex: 1,
        pageSize: 5,
      },
      tableData: [],
      payTypeMap: {},
      loading: false,
      currentRowData: {},
      queryParams: {},
      showDrawer: false, // 委托单详情弹窗
      showResultDrawer: false, // 委托单结果弹窗
      showAskforDialog: false, // 申请申述弹窗
      showAskforResultDialog: false, // 申请申述结果
      evaluateVisible: false, // 去评价弹窗
      addLogisticsVisible: false, // 添加物流信息弹窗
      viewLogisticsVisible: false,
      orderLogis: {},
    };
  },
  async created() {
    this.getPayTayeDict();
    this.getDeedList();
  },
  methods: {
    // 获取数据字典数据
    async getPayTayeDict() {
      const data = await this.$store.dispatch("getDictData", PAY_TYPE);
      const payMap = {};
      data.forEach((item) => {
        payMap[item.dictValue] =
          item.dictLabel === "未选择" ? "未选择支付方式" : item.dictLabel;
      });
      this.payTypeMap = payMap;
    },
    // 委托单列表
    async getDeedList() {
      this.loading = true;
      const { time, ...rest } = this.queryParams;
      const _params = {
        ...this.page,
        orderListType: 0, // 列表类型 0：用户列表 1：实验室列表
        ...rest,
      };
      if (time && time.length) {
        Object.assign(_params, {
          beginTime: time[0],
          endTime: time[1],
        });
      }

      const { list, totalItem } = await this.$axios
        .post(deedList, _params)
        .finally(() => {
          this.loading = false;
        });
      this.tableData = list;
      this.totalItem = totalItem;
    },
    handlePageChange(index) {
      this.page.pageIndex = index;
      this.getDeedList();
    },
    // 查询按钮的点击
    queryBtnClick(params) {
      this.queryParams = params;
      this.getDeedList();
    },
    // 刷新列表
    refreshList() {
      this.getDeedList();
    },

    // 查看委托单详情
    viewDeed(item) {
      this.currentRowData = item; // .checkmallOrderMainId
      this.showDrawer = true;
    },
    // 查看检查结果
    showResult(item) {
      this.currentRowData = item;
      this.showResultDrawer = true;
    },
    // 申请申述
    askFor(item) {
      this.currentRowData = item;
      this.showAskforDialog = true;
    },
    // 查看申请申述
    askForResult(item) {
      this.currentRowData = item;
      this.showAskforResultDialog = true;
    },
    // 去评价
    evaluate(item) {
      this.currentRowData = item;
      this.evaluateVisible = true;
    },
    // 添加物流信息
    addLogistics(item) {
      this.currentRowData = item;
      this.addLogisticsVisible = true;
    },
    // 添加物流信息成功，刷新列表
    addLogSuccess() {
      this.getDeedList();
    },
    // 查看物流信息
    viewLogistics(item) {
      this.currentRowData = item;
      const { sampleMailExpressCompany, sampleMailNumber } =
        item.checkmallOrdeListMailExpress;
      this.orderLogis = {
        orderExpressCompany: sampleMailExpressCompany,
        orderExpressNum: sampleMailNumber,
      };
      this.viewLogisticsVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.drawer {
  /deep/ .el-drawer__header {
    margin-bottom: 15px;
  }
  /deep/ .el-drawer__header span {
    font-weight: bold;
    font-size: 1.07rem;
  }
}
.deed {
  padding: 10px;
}
.tableWrapper {
  min-height: 400px;
  margin-bottom: 10px;
}
.list {
  border: 1px solid $border-color;
  margin-bottom: 10px;
  &-title {
    height: 40px;
    line-height: 40px;
    background: $head-bar-bg;
    padding-left: 10px;
    border-bottom: 1px solid $border-color;
    text-align: center;
    &.first {
      text-align: left;
    }
  }
  &-left {
    border-right: 1px solid $border-color;
  }
  &-center {
    width: 470px;
    border-right: 1px solid $border-color;
  }
  &-right {
    width: 150px;
  }
  &-time {
    font-weight: bold;
    margin-right: 15px;
  }
  &-flow {
    > div {
      width: 100%;
    }
  }
}
</style>
