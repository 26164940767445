var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{attrs:{"data":_vm.data,"border":"","size":_vm.tableSize}},[_c('el-table-column',{attrs:{"label":"图片","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"data-imgWrapper"},[_c('img',{staticClass:"data-img",attrs:{"src":row.equipmentImage}})])]}}])}),_c('el-table-column',{attrs:{"prop":"equipmentName","label":"设备名称"}}),_c('el-table-column',{attrs:{"prop":"equipmentModel","label":"设备型号"}}),_c('el-table-column',{attrs:{"prop":"equipmentBrand","label":"设备品牌"}}),_c('el-table-column',{attrs:{"prop":"equipmentCode","label":"设备编码"}}),_c('el-table-column',{attrs:{"label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(_vm.statusMap[+row.equipmentStatus]))])]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.equipmentStatus == _vm.DEVICE_STATUS_UP)?_c('label',[_c('span',{staticClass:"data-btnEdit pointer margin-right-10",on:{"click":function($event){return _vm.downOrUpBtn(scope.row, _vm.DEVICE_STATUS_DOWN)}}},[_vm._v("下架")]),_c('span',{staticClass:"data-btnEdit pointer",on:{"click":function($event){return _vm.viewBtn(scope.row)}}},[_vm._v("查看")])]):_c('label',[_c('span',{staticClass:"data-btnEdit pointer margin-left-10 margin-right-10",on:{"click":function($event){return _vm.updateBtn(scope.row)}}},[_vm._v("编辑")]),_c('span',{staticClass:"data-btnDel pointer",on:{"click":function($event){return _vm.delBtn(scope.row)}}},[_vm._v("删除")])]),(
          ![_vm.DEVICE_STATUS_UP, _vm.DEVICE_STATUS_CHECK].includes(
            scope.row.equipmentStatus
          )
        )?_c('span',{staticClass:"data-btnDel margin-left-10 pointer",on:{"click":function($event){return _vm.downOrUpBtn(scope.row, _vm.DEVICE_STATUS_UP)}}},[_vm._v("上架")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }