<template>
  <div>
    <HeadBar /><!--顶部bar-->
    <!--顶部logo、检索-->
    <HeadNav /><!-- 顶部导航-->

    <div class="detail flex flex-center">
      <div class="detail-inner flex flex-row flex-between">
        <div class="detail-left flex-1" v-loading="loading">
          <GoodMain
            :info="good"
            @addCartBuryPoin="addCartBuryPoin"
            @openConsult="openConsult"
          />
          <div class="tabDetail">
            <div class="tabDetail-title">
              <label
                v-for="(item, index) in tabNav"
                :key="index"
                :class="{ active: tabNavIndex === index }"
                @click="tabNavIndex = index"
                class="pointer"
              >
                {{ item }}
              </label>
            </div>
            <GoodIntroduce :info="good" v-show="tabNavIndex === 0" />
            <EvaluateList v-show="tabNavIndex === 1" mallType="1" />
          </div>
        </div>
        <HotGoods />
      </div>
    </div>
    <FootBar />
    <ConsultDialog
      v-model="consultVisible"
      v-if="consultVisible"
      :customer="customer"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import GoodMain from "./components/GoodMain";
import GoodIntroduce from "./components/GoodIntroduce";
import HotGoods from "./components/HotGoods";
import { finaMallGoodDetail } from "@/api/mall";
import { buryPoint } from "@/api/common";
import Referrer from "@/mixins/referrer";
import ConsultDialog from "@/components/ConsultDialog/index";
import EvaluateList from "@/components/EvaluateList/index";
export default {
  components: {
    GoodMain,
    GoodIntroduce,
    HotGoods,
    ConsultDialog,
    EvaluateList,
  },
  data() {
    return {
      good: {
        productmallWaresImage: [],
      },
      loading: false,
      consultVisible: false,
      customer: {}, // 客服信息
      tabNav: ["基本信息", "用户评价"],
      tabNavIndex: 0,
    };
  },
  computed: {
    ...mapGetters(["userBaseInfo"]),
  },
  created() {
    this.getGood();
  },
  mixins: [Referrer],
  methods: {
    // 打开咨询客服弹窗
    openConsult(customer) {
      this.customer = customer;
      this.consultVisible = true;
    },
    // 添加进入购物车时，埋点
    addCartBuryPoin() {
      this.$axios.post(buryPoint, {
        eventId: "W-S-8-E1",
        eventName: "添加购物车",
        module: "商品商城",
        currentUrl: this.$route.fullPath,
        currentUrlAlias: this.$route.name,
        referrerUrl: this.routeFrom.fullPath,
        referrerUrlAlias: this.routeFrom.name,
        userId: this.userBaseInfo.userId,
      });
    },
    async getGood() {
      this.loading = true;
      const data = await this.$axios
        .get(finaMallGoodDetail, {
          productmallWaresId: this.$route.query.id,
        })
        .finally(() => {
          this.loading = false;
        });
      Object.assign(data, {
        productmallWaresImage: data.productmallWaresImage.split(";"),
      });
      this.good = data;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.tabDetail {
  border: 1px solid $border-color;
  margin-bottom: 15px;
  &-title {
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid $border-color;
    padding-left: 15px;
    font-size: 20px;
    label {
      margin-right: 70px;
      &.active {
        color: $theme-color;
      }
    }
  }
}
.detail {
  padding-top: 20px;
  &-inner {
    width: $max-width;
  }
  &-left {
    border-right: 1px solid $border-color;
    margin-right: 30px;
    padding-right: 30px;
  }
}
</style>
