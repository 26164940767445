const validateId = (rule, value, callback) => {
  const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  if (!reg.test(value)) {
    callback(new Error("请输入正确的身份证号"));
  }
  callback();
};
const validatePostcode = (rule, value, callback) => {
  const reg = /^[1-9]\d{5}(?!\d)$/g;
  if (value && !reg.test(value)) {
    callback(new Error("请输入正确的邮编"));
  }
  callback();
};

export default {
  location: [
    { required: true, message: "请选择省、市、区", trigger: "change" },
  ],
  expertNation: [
    {
      required: true,
      message: "请输入民族",
      trigger: ["blur", "change"],
    },
  ],
  politicalOutlook: [
    { required: true, message: "请选择政治面貌", trigger: "change" },
  ],
  expertUniversity: [
    {
      required: true,
      message: "请输入毕业院校",
      trigger: ["blur", "change"],
    },
  ],
  expertGraduationTime: [
    { required: true, message: "请选择毕业时间", trigger: "change" },
  ],
  expertEducation: [
    { required: true, message: "请选择学历", trigger: "change" },
  ],
  expertAcademicDegree: [
    { required: true, message: "请选择学位", trigger: "change" },
  ],
  expertProfessional: [
    {
      required: true,
      message: "请输入从事专业",
      trigger: ["blur", "change"],
    },
  ],
  expertIdNumber: [
    {
      required: true,
      message: "请输入身份证号",
      trigger: ["blur", "change"],
    },
    { validator: validateId, trigger: "blur" },
  ],
  expertPostcode: [{ validator: validatePostcode, trigger: "blur" }],
  //   expertFields: [
  //     { required: true, message: "请选择专业领域", trigger: "change" },
  //   ],
  expertBrief: [
    {
      required: true,
      message: "请输入专家介绍",
      trigger: ["blur", "change"],
    },
  ],
};
