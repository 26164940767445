<template>
  <div>
    <HeadBar /><!--顶部bar-->
    <!--顶部logo、检索-->
    <HeadNav /><!-- 顶部导航-->
    <div class="regis flex flex-center">
      <div class="regis-inner">
        <el-card shadow="hover" class="regis-card">
          <div class="regis-title">
            <label>
              <span>联盟申请</span>
              <em></em>
            </label>
          </div>
          <el-form
            :model="form"
            :rules="rules"
            ref="ruleForm"
            label-width="120px"
          >
            <div class="regis-label">单位信息</div>
            <el-form-item label="联盟名称 ">
              {{ allianceDetail.leagueName }}
            </el-form-item>
            <el-form-item label="单位名称" prop="memberName">
              <el-input
                v-model="form.memberName"
                placeholder="请输入单位名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="详细地址" prop="memberAddr">
              <el-input
                v-model="form.memberAddr"
                placeholder="请输入详细地址"
              ></el-input>
            </el-form-item>
            <div class="flex">
              <el-form-item label="邮政编码" class="flex-1">
                <el-input
                  v-model="form.memberPostcode"
                  placeholder="请输入邮政编码"
                ></el-input>
              </el-form-item>
              <el-form-item label="电子邮箱" class="flex-1">
                <el-input
                  v-model="form.memberEmail"
                  placeholder="请输入电子邮箱"
                ></el-input>
              </el-form-item>
            </div>
            <div class="flex">
              <el-form-item
                label="企业性质"
                prop="memberCompanyNature"
                class="flex-1"
              >
                <el-select
                  v-model="form.memberCompanyNature"
                  placeholder="请选择企业性质"
                >
                  <el-option
                    v-for="item in natureOptions"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="成立日期"
                prop="memberCompanyRegisterDate"
                class="flex-1"
              >
                <el-date-picker
                  v-model="form.memberCompanyRegisterDate"
                  type="date"
                  placeholder="选择成立日期"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="flex">
              <el-form-item
                label="上年销售额 (元)"
                prop="lastYearSales"
                class="flex-1"
              >
                <el-input
                  v-model="form.lastYearSales"
                  placeholder="请输入上年销售额"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="注册资金 (元)"
                prop="registeredCapital"
                class="flex-1"
              >
                <el-input
                  v-model="form.registeredCapital"
                  placeholder="请输入注册资金"
                ></el-input>
              </el-form-item>
            </div>
            <div class="flex">
              <el-form-item label="员工人数" prop="staffNumber">
                <el-input
                  v-model="form.staffNumber"
                  placeholder="请输入员工人数"
                ></el-input>
              </el-form-item>
            </div>
            <div class="regis-label">法人代表</div>
            <div class="flex">
              <el-form-item label="姓名" prop="corporationName" class="flex-1">
                <el-input
                  v-model="form.corporationName"
                  placeholder="请输入姓名"
                ></el-input>
              </el-form-item>
              <el-form-item label="手机" prop="corporationPhone" class="flex-1">
                <el-input
                  v-model="form.corporationPhone"
                  placeholder="请输入手机"
                ></el-input>
              </el-form-item>
            </div>
            <div class="flex">
              <el-form-item label="座机">
                <el-input
                  v-model="form.corporationTelephone"
                  placeholder="请输入座机"
                ></el-input>
              </el-form-item>
            </div>
            <div class="regis-label">联系人信息</div>
            <div class="flex">
              <el-form-item label="姓名" prop="contactsName" class="flex-1">
                <el-input
                  v-model="form.contactsName"
                  placeholder="请输入姓名"
                ></el-input>
              </el-form-item>
              <el-form-item label="手机" prop="contactsPhone" class="flex-1">
                <el-input
                  v-model="form.contactsPhone"
                  placeholder="请输入手机"
                ></el-input>
              </el-form-item>
            </div>
            <div class="flex">
              <el-form-item label="座机" prop="contactsTelephone">
                <el-input
                  v-model="form.contactsTelephone"
                  placeholder="请输入座机"
                ></el-input>
              </el-form-item>
            </div>
            <el-form-item label="企业经验范围" prop="bussinessScope">
              <el-input
                v-model="form.bussinessScope"
                type="textarea"
                :rows="5"
                placeholder="请输入企业经验范围"
              ></el-input>
            </el-form-item>
            <el-form-item label="单位简介及主要业绩" prop="memberCompanyBrief">
              <el-input
                v-model="form.memberCompanyBrief"
                type="textarea"
                :rows="5"
                placeholder="请输入单位简介及主要业绩"
              ></el-input>
            </el-form-item>
            <div class="regis-protocol">
              <el-checkbox v-model="protocolCheck">我已阅读</el-checkbox>
              <span>联盟申明</span>并认可此协议
            </div>
          </el-form>
          <div class="regis-btn">
            <el-button
              type="primary"
              size="small"
              plain
              @click="register"
              :loading="btnLoading"
              >提&nbsp;&nbsp;交</el-button
            >
          </div>
        </el-card>
      </div>
    </div>
    <FootBar />
  </div>
</template>
<script>
import { allianceDetail, joinAlliance } from "@/api/allianceZone";
import { COMPANY_NATURE } from "@/constant/dict";
import RULES from "./rule";
import { sleep } from "@/utils/index";
export default {
  components: {},
  data() {
    return {
      allianceDetail: {},
      btnLoading: false,
      form: {
        leagueName: "",
        memberName: "",
        memberAddr: "",
        memberPostcode: "",
        memberEmail: "",
        memberCompanyNature: null,
        memberCompanyRegisterDate: null,
        lastYearSales: null,
        registeredCapital: null,
        staffNumber: null,
        corporationName: "",
        corporationPhone: "",
        corporationTelephone: "",
        contactsName: "",
        contactsPhone: "",
        contactsTelephone: "",
        bussinessScope: "",
        memberCompanyBrief: "",
      },
      rules: RULES,
      protocolCheck: false,
      natureOptions: [],
    };
  },
  async created() {
    await sleep();
    this.leagueId = this.$route.query.allianceId;
    this.getAllianceInfo();
    this.getDict();
  },
  methods: {
    handleChange() {},
    async getDict() {
      const res = await this.$store.dispatch("getDictData", COMPANY_NATURE);
      this.natureOptions = res;
    },
    async getAllianceInfo() {
      const data = await this.$axios.get(allianceDetail, {
        leagueId: this.leagueId,
      });
      this.allianceDetail = data;
    },
    async register() {
      await this.$refs.ruleForm.validate();
      if (!this.protocolCheck) {
        this.$message.warning("请勾选协议");
        return;
      }
      this.btnLoading = true;
      await this.$axios
        .post(joinAlliance, {
          ...this.form,
          leagueId: this.leagueId,
        })
        .finally(() => {
          this.btnLoading = false;
        });
      await sleep();
      this.$message.success("提交成功，请等待审核");
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
$width: 800px;
.regis {
  &-inner {
    width: $width;
    max-width: $width;
    padding-top: 30px;
    min-height: 500px;
    /deep/ textarea {
      resize: none;
    }
    /deep/ .el-select {
      width: 100%;
    }
    /deep/ .el-date-picker {
      width: 100%;
    }
  }
  &-title {
    margin-bottom: 20px;
    text-align: center;
    > label {
      position: relative;
      span {
        position: relative;
        font-size: 1.57rem;
        font-weight: 500;
        z-index: 10;
      }
      em {
        width: 100%;
        height: 12px;
        background: #b7d6ff;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 0;
      }
    }
  }
  &-label {
    border-bottom: 1px solid $border-color;
    padding-bottom: 10px;
    margin-bottom: 20px;
    font-size: 1.14rem;
    font-weight: 600;
  }
  &-protocol {
    margin-bottom: 15px;
    > span {
      color: $theme-color;
      margin: 0 5px;
    }
  }
  &-btn {
    text-align: center;
    > .el-button {
      width: 100px;
    }
  }
}
.width-big {
  width: 310px;
}
</style>
