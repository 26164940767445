export default {
  memberName: [
    {
      required: true,
      message: "请输入单位名称",
      trigger: ["blur", "change"],
    },
  ],
  memberAddr: [
    {
      required: true,
      message: "请输入详细地址",
      trigger: ["blur", "change"],
    },
  ],
  memberCompanyNature: [
    {
      required: true,
      message: "请选择企业性质",
      trigger: ["change"],
    },
  ],
  memberCompanyRegisterDate: [
    {
      required: true,
      message: "选择成立日期",
      trigger: ["change"],
    },
  ],
  lastYearSales: [
    {
      required: true,
      message: "请输入上年销售额",
      trigger: ["blur", "change"],
    },
  ],
  registeredCapital: [
    {
      required: true,
      message: "请输入注册资金",
      trigger: ["blur", "change"],
    },
  ],
  staffNumber: [
    {
      required: true,
      message: "请输入员工人数",
      trigger: ["blur", "change"],
    },
  ],
  corporationName: [
    {
      required: true,
      message: "请输入姓名",
      trigger: ["blur", "change"],
    },
  ],
  corporationPhone: [
    {
      required: true,
      message: "请输入手机",
      trigger: ["blur", "change"],
    },
  ],
  contactsName: [
    {
      required: true,
      message: "请输入姓名",
      trigger: ["blur", "change"],
    },
  ],
  contactsPhone: [
    {
      required: true,
      message: "请输入手机",
      trigger: ["blur", "change"],
    },
  ],
  bussinessScope: [
    {
      required: true,
      message: "请输入企业经验范围",
      trigger: ["blur", "change"],
    },
  ],
  memberCompanyBrief: [
    {
      required: true,
      message: "请输入单位简介及主要业绩",
      trigger: ["blur", "change"],
    },
  ],
};
