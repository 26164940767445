<template>
  <div class="sam">
    <div v-for="(item, index) in data" :key="index" class="sam-item">
      <div class="sam-index">样品组{{ String.fromCharCode(index + 65) }}</div>
      <div class="sam-list">
        <label>样品名称：</label><span>{{ item.sampleName }}</span>
      </div>
      <div class="sam-list">
        <label>样品数量：</label><span>{{ item.sampleQuantity }}</span>
      </div>
      <div class="sam-list">
        <label>主要成分：</label><span>{{ item.sampleBasis }}</span>
      </div>
      <div class="sam-list">
        <label>标号/批号：</label><span>{{ item.sampleLabel }}</span>
      </div>
      <div class="sam-list">
        <label>检测目的：</label><span>{{ item.orderCheckPurpose }}</span>
      </div>
      <div class="sam-list">
        <label>样品送样方式：</label
        ><span>{{ mailTypeMap[item.sampleMailType] }}</span>
      </div>
      <div class="sam-list">
        <label>依据/技术要求：</label
        ><span>{{ item.checkBasicTechnology }}</span>
      </div>
      <div class="sam-list"><label>检查项目：</label></div>
      <div class="sam-check">
        <div
          v-for="(check, cIndex) in item.rareCheckmallSampleFirstRankExs"
          :key="cIndex"
        >
          <div>{{ check.checkmallFirstRankName }}</div>
          <div
            v-for="(last, lIndex) in check.rareCheckmallFirstSecondRanks"
            :key="lIndex"
            class="sam-last"
          >
            <div class="flex flex-row flex-between">
              <div>{{ last.checkmallSecondRankName }}</div>
              <div class="last">
                <label>服务周期：</label>{{ last.serviceCycle }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SAMPLE_MAIL_TYPE } from "@/constant/dict";
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mailTypeMap: {},
    };
  },
  created() {
    this.getDict();
  },
  methods: {
    async getDict() {
      const data = await this.$store.dispatch("getDictData", SAMPLE_MAIL_TYPE);
      const map = {};
      data.forEach((item) => {
        map[item.dictValue] = item.dictLabel;
      });
      this.mailTypeMap = map;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.sam {
  &-item {
    border-bottom: 1px dashed #3073f4;
    margin-bottom: 10px;
    padding-bottom: 5px;
    &:last-child {
      border-bottom: none;
    }
  }
  &-index {
    color: #3073f4;
  }
  &-list {
    > label {
      color: #909399;
    }
  }
  &-check {
    padding-left: 15px;
    width: 80%;
  }
  &-last {
    padding-left: 15px;
  }
}
.last {
  > label {
    color: #909399;
  }
}
</style>
