var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('MyTable',{attrs:{"columns":_vm.columns,"data":_vm.data,"border":""},scopedSlots:_vm._u([{key:"startTime",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("transformTime")((row.startTime * 1000)))+" ")])}},{key:"status",fn:function(ref){
var _obj;

var row = ref.row;return _c('div',{},[_c('span',{class:( _obj = {}, _obj[("status" + (row.status))] = true, _obj )},[_vm._v(_vm._s(_vm.statusMap[row.status]))])])}},{key:"action",fn:function(ref){
var row = ref.row;
return _c('div',{},[(row.status === '1')?_c('el-button',{attrs:{"type":"success","plain":"","size":"mini"},on:{"click":function($event){return _vm.joinMeetting(row)}}},[_vm._v("立即进入")]):_vm._e()],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }