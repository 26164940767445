<template>
  <div class="bill">
    <el-tabs v-model="activeName">
      <el-tab-pane label="商城发票索取" name="first"><AskBill /> </el-tab-pane>
      <el-tab-pane label="已开发票" name="second">
        <PublishedBill />
      </el-tab-pane>
      <el-tab-pane label="设置发票预留信息" name="third">
        <ReserveBill />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import AskBill from "./components/AskBill";
import PublishedBill from "./components/PublishedBill";
import ReserveBill from "./components/ReserveBill";
export default {
  components: {
    AskBill,
    PublishedBill,
    ReserveBill,
  },
  data() {
    return {
      activeName: "first",
    };
  },
};
</script>
<style lang="scss" scoped>
.bill {
  padding: 0 10px 10px 10px;
}
</style>
