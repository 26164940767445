<template>
  <div class="mall">
    <HeadBar /><!--顶部bar-->
    <!--顶部logo、检索-->
    <HeadNav /><!-- 顶部导航-->
    <!-- 筛选条件-->
    <TypeSelect
      @toSearch="typeSelectChange"
      :parentCates="parentCates"
      v-if="parentCates.length"
    />
    <div class="searchBar">
      <div class="searchBar-inner flex flex-right">
        <div class="flex">
          <SearchBar
            :num="resultNum"
            v-model="keyword"
            @btnClick="searchBarClick"
            class="searchBar-search"
          />
          <div
            class="layout-grid"
            :class="{ active: layout == 'grid' }"
            @click="layout = 'grid'"
          >
            <i class="el-icon-s-grid"></i>
          </div>
          <div
            class="layout-line"
            :class="{ active: layout == 'line' }"
            @click="layout = 'line'"
          >
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
    <!--商品列表-->
    <div class="mall-good">
      <div v-loading="listLoading">
        <template v-if="goods.length && layout == 'line'">
          <Good
            v-for="(good, index) in goods"
            :key="index"
            :info="good"
            :unitMap="unitMap"
          />
        </template>
        <template v-else-if="goods.length && layout == 'grid'">
          <div class="flex flex-row flex-wrap">
            <ServiceItem
              v-for="(good, index) in goods"
              :key="index"
              :info="good"
              :index="index + 1"
              :unitMap="unitMap"
            />
          </div>
        </template>
        <div v-else class="no-data">暂无数据</div>
      </div>
    </div>
    <!-- 分页-->
    <Pagination
      :total="totalItem"
      :page="page"
      @pageIndexChange="handlePageChange"
    />
    <FootBar />
  </div>
</template>

<script>
import { mallList, queryKind } from "@/api/mall";
import SearchBar from "@/components/SearchBar/index";
import Pagination from "@/components/Pagination/index";
import TypeSelect from "./components/TypeSelect";
import { SERVICE_UNIT_TYPE } from "@/constant/dict";
import ServiceItem from "@/components/ServiceItem/index.vue";
import Good from "./components/Good";

export default {
  name: "Home",
  components: {
    TypeSelect,
    SearchBar,
    Good,
    ServiceItem,
    Pagination,
  },
  data() {
    return {
      listLoading: false,
      resultNum: 0,
      goods: [],
      totalItem: 0,
      page: {
        pageIndex: 1,
        pageSize: 12,
      },
      keyword: "",
      parentCates: [], // 左边fixBar的类别数据
      unitMap: {},
      layout: "grid",
      queryParams: {},
    };
  },
  async created() {
    await this.getCategory();
    this.toSearch();
    this.getDicts();
  },
  methods: {
    async getDicts() {
      const units = await this.$store.dispatch(
        "getDictData",
        SERVICE_UNIT_TYPE
      );
      const unitMap = {};
      units.forEach((item) => {
        unitMap[item.dictValue] = item.dictLabel;
      });
      this.unitMap = unitMap;
    },
    handlePageChange(index) {
      this.page.pageIndex = index;
      this.toSearch();
    },
    // 获取类别
    async getCategory() {
      // 获取顶级分类
      const pData = await this.$axios.get(queryKind, {
        superCategoryId: 0,
      });
      this.parentCates = pData;
    },
    typeSelectChange(params) {
      this.page.pageIndex = 1;
      this.queryParams = params;
      this.toSearch();
    },
    async toSearch() {
      await this.$nextTick();
      const { types, parentType } = this.queryParams;
      // console.log(this.queryParams);
      const _params = {};
      if (types && types.length) {
        const brands = [];
        const citys = [];
        const serviceCategoryIds = [];
        types.forEach(({ title, id, name }) => {
          if (title === "类别") serviceCategoryIds.push(id);
          else if (title === "品牌") brands.push(name);
          else if (title === "地区") citys.push(name);
        });
        Object.assign(_params, { brands, citys, serviceCategoryIds });
      }
      if (!parentType) {
        const { cateId } = this.$route.query;
        const { serviceCategoryId } = this.parentCates[0];
        Object.assign(_params, {
          serviceCategorySuperId: cateId || serviceCategoryId,
        });
      } else {
        Object.assign(_params, {
          serviceCategorySuperId: parentType,
        });
      }
      this.listLoading = true;
      const { list, totalItem } = await this.$axios
        .post(mallList, {
          ...this.page,
          ..._params,
          keyword: this.keyword,
        })
        .finally(() => {
          this.listLoading = false;
        });

      this.goods = list;
      this.totalItem = totalItem;
      this.resultNum = list.length;
    },
    searchBarClick() {
      this.toSearch();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.mall {
  &-good {
    // background: #f0f0f8;
    min-height: 500px;
    > div {
      margin: auto;
      width: $max-width;
    }
  }
}
.searchBar {
  margin-bottom: 30px;
  // padding: 5px 5px 5px 0;
  &-inner {
    box-sizing: border-box;
    margin: auto;
    width: $max-width;
    background: #f9f9f9;
    border-right: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    border-bottom: 1px solid #ebeef5;
    /deep/ .el-input__inner {
      line-height: 37px;
      height: 37px;
    }
  }
  &-search {
    margin: 5px 70px 5px 0;
  }

  > div {
    width: $max-width;
  }
}
.layout {
  &-grid {
    background: #ffffff;
    padding: 0 12px;
    font-size: 20px;
    border-right: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    color: #858585;
    &.active {
      i {
        color: #4483ff;
      }
    }
    i {
      margin-top: 15px;
      cursor: pointer;
      &:hover {
        color: #4483ff;
      }
    }
  }
  &-line {
    padding: 17px 12px 0 12px;
    cursor: pointer;
    &:hover,
    &.active {
      div {
        background: #4483ff;
      }
    }
    div {
      height: 3px;
      width: 18px;
      background: #858585;
      border-radius: 20px;

      &:nth-child(2) {
        margin: 2px 0;
      }
    }
  }
}
</style>
