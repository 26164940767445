<template>
  <el-form
    :model="form"
    :rules="rules"
    ref="ruleForm"
    label-width="100px"
    v-loading="loading"
    class="form"
  >
    <el-form-item label="需求标题 " prop="demandTitle">
      <el-input
        v-model="form.demandTitle"
        placeholder="请输入需求标题"
      ></el-input>
    </el-form-item>
    <el-form-item label="检测项目" prop="demandProjectName">
      <el-input
        v-model="form.demandProjectName"
        placeholder="请输入检测项目"
      ></el-input>
    </el-form-item>
    <el-form-item label="检测分类" prop="laboratoryAbilityTypeId">
      <el-select
        v-model="form.laboratoryAbilityTypeId"
        placeholder="请选择检测分类"
      >
        <el-option
          v-for="item in abilityTypeOptions"
          :key="item.laboratoryAbilityTypeId"
          :label="item.laboratoryAbilityTypeName"
          :value="item.laboratoryAbilityTypeId"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="样品信息" prop="demandSampleInfo">
      <el-input
        v-model="form.demandSampleInfo"
        placeholder="请输入样品信息"
      ></el-input>
    </el-form-item>
    <el-form-item label="资质要求" prop="qualificationId">
      <el-checkbox-group v-model="form.qualificationId" class="qua">
        <el-checkbox
          v-for="(item, index) in qualificationOptions"
          :label="item.qualificationId"
          :key="index"
        >
          <div class="qua-icon" :class="{ isIco: !!item.qualificationIco }">
            <img :src="item.qualificationIco" v-if="item.qualificationIco" />
            <span v-else>{{ item.qualificationName }}</span>
          </div>
        </el-checkbox>
      </el-checkbox-group>
    </el-form-item>
    <el-form-item label="检测要求" prop="demandRequirements">
      <el-input
        v-model="form.demandRequirements"
        type="textarea"
        rows="5"
        placeholder="请输入检测方法、标准、样品处理要求"
      ></el-input>
    </el-form-item>
    <el-form-item label="检测样品图片">
      <Upload :imgs="form.demandImage" @onSuccess="demandImageSuccess" />
    </el-form-item>
    <el-form-item label="发布位置" prop="demandPublishAddress">
      <div class="flex address">
        <el-cascader
          v-model="form.demandPublishAddress"
          :options="cityOptions"
          :props="{ expandTrigger: 'hover' }"
          placeholder="请选择城市"
          ref="cityCascader"
        ></el-cascader>
      </div>
    </el-form-item>
    <el-form-item label="联系电话" prop="demandContactsPhone">
      <el-input
        v-model="form.demandContactsPhone"
        placeholder="请输入联系电话"
      ></el-input>
    </el-form-item>
    <div class="form-protocol">
      <el-checkbox v-model="protocolCheck">我已阅读</el-checkbox>
      <a :href="$oss('用户协议.html', 'protocols')" target="blank"
        >《平台用户协议》</a
      >
      <span>并认可此协议</span>
    </div>
    <div class="form-btn">
      <el-button
        type="primary"
        size="small"
        plain
        @click="register"
        v-loading="btnLoading"
        >{{ demandId ? "保存" : "发布需求" }}</el-button
      >
    </div>
  </el-form>
</template>
<script>
import {
  abilityType,
  qualificationList,
  addDemand,
  demandDetail,
} from "@/api/hall";
import { editDemand } from "@/api/userCenter";
import { regionData } from "element-china-area-data";
import { mapGetters } from "vuex";
import Rules from "./rule";
import Upload from "@/components/ImgUpload/index";
export default {
  components: {
    Upload,
  },
  props: {
    demandId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      form: {
        userId: null, // 需求发布用户ID
        demandTitle: "", //需求标题
        demandProjectName: "", // 检测项目名称
        laboratoryAbilityTypeId: null, //需求检测能力分类ID
        demandSampleInfo: "", // 需求样品信息
        qualificationId: [], //要求检测资质编号
        demandRequirements: "", // 需求检测要求
        demandPublishAddress: [],
        demandContactsPhone: "", // 需求联系电话
        demandImage: "", // 需求检测样品图片
      },
      rules: Rules,
      abilityTypeOptions: [],
      qualificationOptions: [],
      cityOptions: regionData,
      protocolCheck: false,
      fileList: [],
      loading: false,
      btnLoading: false,
    };
  },
  computed: {
    ...mapGetters(["userBaseInfo"]),
  },
  async created() {
    // 获取检测分类
    await this.getCheckKind();
    // 资质分类
    await this.getQualificationList();
    if (this.demandId) this.getDetail();
  },
  methods: {
    // 获取检测分类
    async getCheckKind() {
      this.loading = true;
      const data = await this.$axios
        .get(abilityType, { pid: 0 })
        .finally(() => {
          this.loading = false;
        });
      this.abilityTypeOptions = data;
    },
    // 资质分类
    async getQualificationList() {
      this.loading = true;
      const data = await this.$axios.get(qualificationList).finally(() => {
        this.loading = false;
      });
      this.qualificationOptions = data;
    },
    demandImageSuccess(fileUrls) {
      Object.assign(this.form, { demandImage: fileUrls });
    },
    // 获取需求详情
    async getDetail() {
      this.loading = true;
      const data = await this.$axios
        .get(demandDetail, {
          demandId: this.demandId,
        })
        .finally(() => {
          this.loading = false;
        });
      const {
        qualificationId,
        demandPublishProvinceCode,
        demandPublishCityCode,
        demandPublishCountyCode,
        ...rest
      } = data;
      const qIds = qualificationId.split(",");

      this.form = {
        ...rest,
        qualificationId: qIds.length ? qIds.map((i) => +i) : qIds,
        demandPublishAddress: [
          demandPublishProvinceCode,
          demandPublishCityCode,
          demandPublishCountyCode,
        ],
      };
      this.protocolCheck = true;
    },
    async register() {
      try {
        await this.$refs["ruleForm"].validate();
        if (!this.protocolCheck) {
          this.$message.error("请先勾选《平台用户协议》");
          return;
        }
        const [demandPublishProvince, demandPublishCity, demandPublishCounty] =
          this.$refs.cityCascader.getCheckedNodes()[0].pathLabels;
        const { qualificationId, demandPublishAddress, ...rest } = this.form;
        const [
          demandPublishProvinceCode,
          demandPublishCityCode,
          demandPublishCountyCode,
        ] = demandPublishAddress;
        const params = {
          qualificationId: qualificationId.join(","),
          demandPublishProvinceCode,
          demandPublishCityCode,
          demandPublishCountyCode,
          demandPublishProvince,
          demandPublishCity,
          demandPublishCounty,
          userId: this.userBaseInfo.userId,
          ...rest,
        };
        if (this.demandId) {
          Object.assign({ demandId: this.demandId });
        }
        this.btnLoading = true;
        await this.$axios
          .post(this.demandId ? editDemand : addDemand, params)
          .finally(() => {
            this.btnLoading = false;
          });
        if (this.demandId) {
          this.$message.success("修改成功");
          this.$emit("updateSuccess");
        } else {
          this.$message.success("发布成功");
          this.$router.push({ name: "home" });
        }
      } catch (e) {
        console.log(e);
      }
    },
    handlePreview() {},
    handleRemove() {},
    handleExceed() {},
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.form {
  /deep/ textarea {
    resize: none;
  }
  &-imgs {
    > div {
      margin-right: 15px;
    }
  }
  &-protocol {
    margin-bottom: 15px;
    margin-left: 30px;
    > a {
      color: $theme-color;
      text-decoration: none;
      margin: 0 5px;
    }
  }
  &-btn {
    text-align: center;
    > .el-button {
      width: 100px;
    }
  }
}
.width-big {
  width: 310px;
}
.address {
  /deep/ .el-cascader {
    width: 300px;
  }
}
.qua {
  &-icon {
    width: 60px;
    height: 30px;
    &.isIco {
      border: 1px solid $border-color;
    }
    > img {
      width: 60px;
      height: 30px;
      object-fit: contain;
    }
  }
}
</style>
