<template>
  <div class="hotLab">
    <div class="hotLab-top flex flex-row flex-between">
      <div class="hotLab-label">热门实验室</div>
      <div class="hotLab-num">
        已入驻<span>{{ labTotal }}</span
        >家实验室
      </div>
    </div>
    <div class="list" v-loading="listLoading">
      <div
        v-for="(item, index) in lists"
        :key="index"
        class="flex flex-row list-item"
      >
        <div class="list-num" :class="{ [`index${index + 1}`]: true }">
          {{ index + 1 }}
        </div>
        <div class="list-con flex-1">
          <div class="list-labName pointer" @click="toLabDetail(item)">
            {{ item.laboratoryName }}
          </div>
          <div class="flex flex-row flex-between list-other">
            <div>设备 {{ item.waresSum }}</div>
            <div>能力 {{ item.abilitySum }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { recommendLabList, labTotalNum } from "@/api/lab";
export default {
  data() {
    return {
      lists: [],
      labTotal: 0,
      listLoading: false,
      page: {
        pageIndex: 1,
        pageSize: 10,
      },
    };
  },
  created() {
    this.getList();
    this.getLabTotal();
  },
  methods: {
    async getList() {
      this.listLoading = true;
      const res = await this.$axios
        .post(recommendLabList, this.page)
        .finally(() => {
          this.listLoading = false;
        });
      this.lists = res;
    },
    async getLabTotal() {
      const res = await this.$axios.post(labTotalNum, {});
      this.labTotal = res;
    },
    toLabDetail({ laboratoryId }) {
      this.$jump.push({ name: "labDetail", query: { id: laboratoryId } });
    },
  },
};
</script>
<style lang="scss" scoped>
.hotLab {
  background: white;
  width: 100%;
  padding: 18px;
  &-top {
    margin-bottom: 20px;
  }
  &-label {
    font-size: 18px;
    font-weight: bold;
    color: #1c1c1c;
  }
  &-num {
    font-size: 12px;
    color: #707070;
    span {
      color: #ff6600;
      font-weight: bold;
    }
  }
}
.list {
  min-height: 300px;
  &-item {
    margin-bottom: 10px;
  }
  &-num {
    width: 25px;
    text-align: right;
    margin-right: 20px;
    font-size: 14px;
    font-weight: bolder;
    padding-top: 5px;
    &.index1 {
      font-size: 16px;
      color: #fb0100;
    }
    &.index2,
    &.index3 {
      font-size: 16px;
      color: #fe6100;
    }
  }
  &-labName {
    font-size: 14px;
    color: #1c1c1c;
    margin-bottom: 8px;
  }
  &-other {
    font-size: 12px;
    color: #666666;
    padding-bottom: 8px;
    border-bottom: 1px dashed #d1d1d1;
  }
}
</style>
