<template>
  <div class="form-wrapper">
    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
      <el-form-item label="企业名称" prop="enterpriseName">
        <el-input
          v-model="form.enterpriseName"
          placeholder="请输入企业名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系人员" prop="enterpriseContactsName">
        <el-input
          v-model="form.enterpriseContactsName"
          placeholder="请输入联系人员"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="enterpriseContactsPhone">
        <el-input
          v-model="form.enterpriseContactsPhone"
          placeholder="请输入联系电话"
        >
        </el-input>
      </el-form-item>
      <div class="flex">
        <el-form-item label="企业地址">
          <el-cascader
            v-model="form.location"
            :options="cityOptions"
            filterable
            clearable
            placeholder="请选择省、市、区"
            class="width-middle"
            ref="cityCascader"
          ></el-cascader>
        </el-form-item>
        <el-form-item
          label=""
          label-width="5px"
          class="flex-1"
          prop="enterpriseAddress"
        >
          <el-input
            v-model="form.enterpriseAddress"
            placeholder="请输入详细地址"
            class="base-address"
          ></el-input>
        </el-form-item>
      </div>
      <el-form-item label="">
        <el-button
          type="primary"
          plain
          size="small"
          @click="saveBtnClick"
          :loading="saveLoading"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { saveEntInfo } from "@/api/enterprise";
import { regionData } from "element-china-area-data";
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      cityOptions: regionData,
      form: {
        enterpriseName: "",
        enterpriseContactsName: "",
        enterpriseContactsPhone: "",
        location: [],
        enterpriseAddress: "",
      },
      rules: {
        enterpriseName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        enterpriseContactsName: [
          { required: true, message: "请输入联系人员", trigger: "blur" },
        ],
        enterpriseContactsPhone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
        ],
        enterpriseAddress: [
          { required: true, message: "请输入企业详细地址", trigger: "blur" },
        ],
      },
      saveLoading: false,
    };
  },
  watch: {
    data(val) {
      Object.assign(this.form, val);
    },
  },
  methods: {
    async saveBtnClick() {
      await this.$refs.ruleForm.validate();
      const { location, ...rest } = this.form;
      if (!location || !location.length) {
        this.$message.error("请选择企业地址");
        return;
      }
      const [
        enterpriseLocationProvince,
        enterpriseLocationCity,
        enterpriseLocationCounty,
      ] = this.$refs.cityCascader.getCheckedNodes()[0].pathLabels;
      const params = {
        ...rest,
      };
      Object.assign(params, {
        enterpriseLocationProvince,
        enterpriseLocationCity,
        enterpriseLocationCounty,
        enterpriseLocationProvinceCode: location[0],
        enterpriseLocationCityCode: location[1],
        enterpriseLocationCountyCode: location[2],
      });
      this.saveLoading = true;
      await this.$axios.post(saveEntInfo, params).finally(() => {
        this.saveLoading = false;
      });
      this.$message.success("保存成功");
    },
  },
};
</script>
<style lang="scss" scoped>
.form {
  &-wrapper {
    width: 600px;
  }
}
</style>
