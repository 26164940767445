<template>
  <div class="plat pointer" @click="toPage">
    <div class="plat-title">标准服务平台</div>
    <div class="plat-text">
      由包头稀土研究院、国家新材料测试评价平台-稀土行业中心主办的“国家标准文献共享服务平台稀土行业服务站”于2021年12月正式上线运行，该服务站是目前国内稀土行业唯一的标准化信息专业服务平台。
    </div>
    <div class="plat-btnWrap">
      <span class="plat-btn">立即进入></span>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    toPage() {
      window.open("https://xitu.nssi.org.cn");
    },
  },
};
</script>
<style lang="scss" scoped>
.plat {
  width: 336px;
  height: 328px;
  background: url("https://store-img.cre-tep.com/frontDeskProject/images/home/标准服务.jpg");
  background-size: 100% 100%;
  color: white;
  &-title {
    font-size: 20px;
    text-align: center;
    padding: 26px 0 20px 0;
  }
  &-text {
    font-size: 15px;
    padding: 0 30px;
    line-height: 32px;
    text-indent: 30px;
    margin-bottom: 15px;
  }
  &-btnWrap {
    text-align: center;
    font-size: 16px;
  }
  &-btn {
    background: rgba(0, 107, 238, 0.5);
    padding: 4px 12px 6px 15px;
  }
}
</style>
