<template>
  <div>
    <div class="flex flex-row" v-for="(item, index) in items" :key="index">
      <div class="order-left">{{ index + 1 }}.</div>
      <div
        class="flex flex-1 flex-wrap order-item"
        :class="{ isLast: index + 1 == items.length }"
      >
        <div>
          <label>样品名称:</label>
          <span>{{ item.sampleName }}</span>
        </div>
        <div>
          <label>样品数量:</label>
          <span>{{ item.sampleQuantity }}</span>
        </div>
        <div>
          <label>样品标号/批号:</label>
          <span>{{ item.sampleLabel }}</span>
        </div>
        <div>
          <label>检测项目:</label>
          <span>{{ item.rareCheckmallSampleFirstRankExs | proFilter }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  filters: {
    proFilter(arr) {
      const names = [];
      arr.forEach((item) => {
        names.push(item.checkmallFirstRankName);
      });
      return names.join(",");
    },
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.order {
  &-left {
    width: 20px;
    padding-top: 10px;
  }
  &-item {
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
    padding: 10px 10px 0 10px;
    &.isLast {
      border-bottom: 1px solid #ddd;
    }
    > div {
      padding-right: 20px;
      padding-bottom: 10px;
      label {
        padding-right: 5px;
      }
      span {
        color: $theme-color;
      }
    }
  }
}
</style>
