<template>
  <div>
    <div class="tabs">
      <span
        v-for="(item, index) in tabs"
        :key="index"
        class="pointer"
        :class="{ active: tabIndex === index }"
        @click="tabClick(index)"
      >
        {{ item.label }}({{ grageNum[item.key] || 0 }})</span
      >
    </div>
    <div>
      <div
        class="infinite-lists"
        v-infinite-scroll="loadMore"
        style="overflow: auto"
      >
        <div
          v-for="(item, index) in list"
          class="infinite-item flex flex-row"
          :key="index"
        >
          <div class="infinite-left">
            <div class="infinite-avator">
              {{ item.userRealName.slice(0, 1) }}
            </div>
          </div>
          <div class="flex-1 infinite-right">
            <el-rate
              v-model="item.orderEvaluateScore"
              disabled
              class="infinite-rate"
            ></el-rate>
            <div class="infinite-con">{{ item.orderEvaluateContent }}</div>
            <div class="infinite-time">
              {{ item.createTime | transformTime }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { evaluateCount, evaluateListById } from "@/api/mall"; //
import { parseTime } from "@/utils/index";
export default {
  filters: {
    transformTime(val) {
      return parseTime(val, "{y}-{m}-{d} {h}:{i}");
    },
  },
  props: {
    // （0-检测商城；1-商品商城）
    mallType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      grageNum: {},
      tabs: [
        { label: "全部评价", key: "allEvaluateNum" },
        { label: "好评", key: "praiseEvaluateNum" },
        { label: "中评", key: "mediumEvaluateNum" },
        { label: "差评", key: "badEvaluateNum" },
      ],
      tabIndex: 0,
      list: [],
      totalItem: 0,
    };
  },
  async created() {
    await this.getEvaluateCount();
    this.getList();
  },
  methods: {
    async getEvaluateCount() {
      const res = await this.$axios.post(evaluateCount, {
        mallOrderType: this.mallType,
        waresId: this.$route.query.id,
      });
      this.grageNum = res || {};
    },
    async getList() {
      const key = this.tabs[this.tabIndex].key;
      console.log(this.grageNum[key]);
      const { list, totalItem } = await this.$axios.post(evaluateListById, {
        mallOrderType: this.mallType,
        waresId: this.$route.query.id,
      });
      this.list.push(...list);
      this.totalItem = totalItem;
    },
    tabClick(index) {
      this.tabIndex = index;
      this.list.length = 0;
      this.totalItem = 0;
      this.getList();
    },
    loadMore() {
      if (this.list.length < this.totalItem) {
        this.getList();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.tabs {
  background: #fafafa;
  padding: 12px 16px;
  border-bottom: 1px solid #eaeaea;
  span {
    margin-right: 20px;
    color: #666666;
    &.active {
      color: $theme-color;
    }
  }
}
.infinite {
  &-lists {
    min-height: 100px;
    max-height: 800px;
  }
  &-item {
    padding: 15px;
    border-bottom: 1px solid #eaeaea;
    &:last-child {
      border-bottom: 0;
    }
  }
  &-left {
    margin-right: 40px;
  }
  &-avator {
    text-align: center;
    font-size: 20px;
    background: $theme-color;
    color: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
  &-right {
    /deep/ .el-rate__icon {
      font-size: 24px;
    }
  }
  &-rate {
    margin-bottom: 10px;
  }
  &-con {
    padding-left: 5px;
    margin-bottom: 10px;
  }
  &-time {
    text-align: right;
    color: #999999;
  }
}
</style>
