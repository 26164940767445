<template>
  <div class="devices flex flex-row flex-wrap" v-loading="loading">
    <div
      class="item pointer"
      v-for="(item, index) in lists"
      :key="index"
      :class="{ isBottom: index > 3 }"
      @click="toDeviceDetail(item)"
    >
      <div class="item-cover">
        <img :src="item.imgsrc" v-if="item.imgsrc" />
        <img v-else :src="$oss('common/default_device.png')" />
      </div>
      <div class="item-desc">
        <div class="item-name ellipsis">
          {{ item.devicename }}
        </div>
        <div class="item-priceLine ellipsis">
          <span class="price">
            <span v-if="!item.preferentialPrice || item.face">面议</span>
            <template v-else>
              <span>￥{{ item.preferentialPrice }}</span>
              <span>/{{ unitMap[+item.unit] }}</span>
            </template>
          </span>
          <span class="throuth" v-if="item.discount !== '10'">
            <span v-if="!item.minPrince || item.face">面议</span>
            <template v-else>
              <span> ￥{{ item.minPrince }}</span>
              <span>/{{ unitMap[+item.unit] }}</span>
            </template>
          </span>
        </div>
      </div>
    </div>
    <div class="last pointer" v-if="lastItem && lastItem.id">
      <div class="last-device flex flex-row" @click="toDeviceDetail(lastItem)">
        <div class="last-cover">
          <img :src="lastItem.imgsrc" v-if="lastItem.imgsrc" />
          <img v-else :src="$oss('common/default_device.png')" />
        </div>
        <div class="flex-1">
          <div class="last-name ellipsis">{{ lastItem.devicename }}</div>
          <div class="last-priceLine">
            <div class="price">
              <span v-if="!lastItem.preferentialPrice || lastItem.face"
                >面议</span
              >
              <template v-else>
                <span>￥{{ lastItem.preferentialPrice }}</span>
                <span>/{{ unitMap[+lastItem.unit] }}</span>
              </template>
            </div>
            <div class="throuth" v-if="lastItem.discount !== '10'">
              <span v-if="!lastItem.minPrince || lastItem.face">面议</span>
              <template v-else>
                <span> ￥{{ lastItem.minPrince }}</span>
                <span>/{{ unitMap[+lastItem.unit] }}</span>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="more">
        <div class="pointer" @click="moreClick">
          点击查看更多<span><i class="el-icon-arrow-right"></i></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mallList } from "@/api/mall";
import { SERVICE_UNIT_TYPE } from "@/constant/dict";
export default {
  props: {
    cateId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      page: {
        pageIndex: 1,
        pageSize: 8,
      },
      lists: [],
      lastItem: {},
      loading: false,
      unitMap: {},
    };
  },
  created() {
    this.getDicts();
    this.getDevicesList();
  },
  methods: {
    async getDevicesList() {
      this.loading = true;
      const { list } = await this.$axios
        .post(mallList, {
          ...this.page,
          serviceCategorySuperId: this.cateId,
        })
        .finally(() => {
          this.loading = false;
        });
      const resLength = list.length;
      const listLen = Math.min(resLength, 7);
      if (resLength > listLen) {
        this.lists = list.slice(0, listLen) || [];
        this.lastItem = list[listLen];
      } else {
        this.lists = list.slice(0, listLen - 1) || [];
        this.lastItem = list[listLen - 1];
      }
    },
    toDeviceDetail(data) {
      this.$jump.push({ name: "deviceDetail", query: { id: data.id } });
    },
    moreClick() {
      this.$jump.push({
        name: "mallStrict",
        query: { cateId: this.cateId },
      });
    },
    async getDicts() {
      const units = await this.$store.dispatch(
        "getDictData",
        SERVICE_UNIT_TYPE
      );
      const unitMap = {};
      units.forEach((item) => {
        unitMap[item.dictValue] = item.dictLabel;
      });
      this.unitMap = unitMap;
    },
  },
};
</script>
<style lang="scss" scoped>
.devices {
  min-height: 484px;
}
.item {
  box-sizing: border-box;
  padding: 20px 20px 0 20px;
  background: white;
  margin-left: 17px;
  margin-bottom: 16px;
  width: 234px;
  height: 234px;
  &.isBottom {
    margin-bottom: 0;
  }
  &-cover {
    width: 188px;
    height: 126px;
    margin-bottom: 16px;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-name {
    text-align: center;
    font-size: 16px;
    color: #202020;
    width: 100%;
    margin-bottom: 6px;
    max-width: 188px;
  }
  &-priceLine {
    font-size: 16px;
    text-align: center;
    width: 100%;
    max-width: 188px;
    span {
      &.price {
        font-weight: bold;
        color: #e67009;
        margin-right: 10px;
      }
      &.throuth {
        color: #9e9e9e;
        font-size: 13px;
        text-decoration: line-through;
      }
    }
  }
}
.last {
  margin-left: 17px;
  width: 234px;
  &-device {
    box-sizing: border-box;
    padding: 22px 20px;
    background: white;
    margin-bottom: 15px;
  }
  &-cover {
    width: 84px;
    height: 65px;
    margin-right: 10px;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-name {
    font-size: 16px;
    color: #202020;
    width: 100%;
    max-width: 95px;
    margin-bottom: 10px;
  }
  &-priceLine {
    font-size: 14px;
    .price {
      font-weight: bold;
      color: #e67009;
    }
    .throuth {
      color: #9e9e9e;
      font-size: 13px;
      text-decoration: line-through;
    }
  }
}
.more {
  height: 105px;
  line-height: 105px;
  background: white;
  padding-left: 24px;
  font-size: 16px;
  font-weight: 400;
  color: #202020;
  span {
    border: 2px solid #e67009;
    border-radius: 50%;
    padding: 1px 3px;
    margin-left: 10px;
  }
  i {
    color: #e67009;
    font-weight: bold;
  }
}
</style>
