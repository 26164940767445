// 聊天定义的常量
// 身份/角色
export const IDENTITY_SERVICE = 1; // 客服
export const IDENTITY_CONSULT = 2; // 咨询者
export const IDENTITY_ROBOT = 3; // 机器人

// 消息类型
export const MSG_TEXT = 1; // 文本
export const MSG_IMG = 2; // 图片
export const MSG_SYS = 3; // 系统消息

// 系统消息类型

// 消息状态
export const MSG_STATUS_SUCCESS = 1; //发送成功
export const MSG_STATUS_FAILD = 2; //发送失败
