<template>
  <div class="btns">
    <div>
      <el-button size="mini" type="primary" plain @click="viewDeed"
        >查看委托单</el-button
      >
    </div>
    <template v-if="showGetSample">
      <div>
        <el-button
          size="mini"
          type="primary"
          plain
          @click="$emit('viewLogistics')"
          >查看寄样快递信息</el-button
        >
      </div>
      <div>
        <el-button
          size="mini"
          type="danger"
          plain
          @click="confirmSample"
          :loading="confirmSampleLoading"
          >确认收样</el-button
        >
      </div>
    </template>
    <div>
      <el-button
        size="mini"
        type="primary"
        plain
        @click="$emit('addRetreatLogistics')"
        >填写退样快递信息</el-button
      >
    </div>
    <div>
      <el-button
        size="mini"
        type="primary"
        plain
        @click="$emit('viewRetreatLogistics')"
        >查看退样快递信息</el-button
      >
    </div>
    <div v-if="showAfterPaySuccess">
      <el-button size="mini" type="warning" plain>后支付完成</el-button>
    </div>
    <div v-if="showUploadReport">
      <el-button
        size="mini"
        type="danger"
        plain
        @click="uploadReportBtnClick"
        :loading="uploadReportLoading"
        >上传/查看检测报告</el-button
      >
    </div>
    <div v-if="showCheckComplete">
      <el-button
        size="mini"
        type="primary"
        plain
        @click="checkComplete"
        :loading="checkCompleteLoading"
        >检测完成</el-button
      >
    </div>

    <div>
      <el-button size="mini" type="success" plain @click="discardDeed"
        >废弃此订单</el-button
      >
    </div>
    <!-- <div>
      <el-button size="mini" type="primary" plain @click="updatePrice"
        >修改订单价格</el-button
      >
    </div> -->
  </div>
</template>
<script>
import { updateStrictOrderStatus } from "@/api/mall";
import { discardDeedById } from "@/api/userCenter";
import {
  DEED_ORDER_SUCCESS,
  DEED_ORDER_FINISH,
  DEED_ORDER_CHECKING,
  DEED_ORDER_WAITSAMPLE,
  DEED_ORDER_REPORT,
  PAYTYPE_XY,
  PAYSTATUS_SUCCESS,
  PAYSTATUS_NO,
} from "@/constant/index";
export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      confirmSampleLoading: false,
      checkCompleteLoading: false,
      uploadReportLoading: false,
    };
  },
  computed: {
    // 确认收样
    showGetSample() {
      const { orderCheckStatus, orderPayStatus, orderPayType } = this.row;
      const success = orderCheckStatus === DEED_ORDER_SUCCESS;
      const pay =
        orderPayType === PAYTYPE_XY || orderPayStatus === PAYSTATUS_SUCCESS;
      return success && pay;
    },
    // 后支付完成
    showAfterPaySuccess() {
      const { orderCheckStatus, orderPayStatus, orderPayType } = this.row;
      const success = orderCheckStatus === DEED_ORDER_SUCCESS;
      const pay =
        orderPayType === PAYTYPE_XY && orderPayStatus === PAYSTATUS_NO;
      return success && pay;
    },
    // 检测完成
    showCheckComplete() {
      const { orderCheckStatus } = this.row;
      return +orderCheckStatus >= +DEED_ORDER_REPORT;
    },
    // 上传检测报告
    showUploadReport() {
      const { orderCheckStatus } = this.row;
      const arr = [DEED_ORDER_CHECKING, DEED_ORDER_REPORT, DEED_ORDER_FINISH];
      return arr.includes(orderCheckStatus);
    },
  },
  // computed:{},
  methods: {
    // 查看详情
    viewDeed() {
      this.$emit("viewDeed");
    },
    // 废弃此订单
    async discardDeed() {
      await this.$confirm("确定废弃此订单吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      await this.$axios.post(discardDeedById, {
        orderId: this.row.checkmallOrderMainId,
      });
      this.$message.success("废弃订单成功");
      this.$emit("refresh");
    },
    // 修改价格
    updatePrice() {
      this.$emit("updatePrice");
    },
    // 确认收样
    async confirmSample() {
      this.confirmSampleLoading = true;
      await this.changeStatus(DEED_ORDER_WAITSAMPLE, "确认收样完成").finally(
        () => {
          this.confirmSampleLoading = false;
        }
      );
    },
    // 检测完成
    async checkComplete() {
      this.checkCompleteLoading = true;
      await this.changeStatus(DEED_ORDER_FINISH, "检测完成").finally(() => {
        this.checkCompleteLoading = false;
      });
    },
    // 上传检测报告
    async uploadReportBtnClick() {
      this.$emit("uploadReport");
      // this.uploadReportLoading = true;
      // await this.changeStatus(DEED_ORDER_REPORT, "上传检测报告成功");
      // this.uploadReportLoading = false;
    },
    async changeStatus(status, tip) {
      try {
        await this.$axios.post(updateStrictOrderStatus, {
          checkmallOrderMainId: this.row.checkmallOrderMainId,
          orderCheckStatus: status,
        });
        this.$message.success(tip);
        this.$emit("refresh");
      } catch (e) {
        this.$message.error(e && e.msg);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.btns {
  padding-top: 10px;
  > div {
    margin-bottom: 10px;
    text-align: center;
  }
  /deep/ .el-button {
    width: 130px;
  }
}
</style>
