<template>
  <div class="flex flex-between nHead">
    <div class="type flex flex-row flex-1">
      <div
        v-for="(item, index) in data"
        :key="index"
        class="type-item pointer"
        :class="{ active: value === index }"
        @click="typeClick(index)"
      >
        <span>{{ item.dictLabel }}</span>
      </div>
      <div class="flex-1 type-last"></div>
    </div>
    <div class="type-more pointer" @click="toMore">更多</div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  methods: {
    toMore() {
      const newsType = this.data[this.value];
      this.$jump.push({
        name: "newsList",
        query: { type: newsType.dictValue },
      });
    },
    typeClick(index) {
      this.$emit("input", index);
      this.$emit("typeChange");
    },
  },
};
</script>
<style lang="scss" scoped>
.nHead {
  height: 57px;
  line-height: 57px;
  font-size: 18px;
  margin-bottom: 26px;
}
.type {
  &-item {
    width: 139px;
    border-bottom: 1px solid #dfdfdf;
    text-align: center;
    border-top: 1px solid white;
    border-left: 1px solid white;
    border-right: 1px solid white;
    &:first-child {
      border-left: 1px solid white !important;
    }
    &.active {
      border-top: 1px solid #dfdfdf;
      border-left: 1px solid #dfdfdf;
      border-right: 1px solid #dfdfdf;
      position: relative;
      border-bottom: none;
      &::before {
        content: "";
        position: absolute;
        width: 11px;
        height: 11px;
        background: #595959;
        top: 24px;
        left: 15px;
      }
    }
  }
  &-last {
    border-bottom: 1px solid #dfdfdf;
  }
  &-more {
    padding-right: 27px;
    border-bottom: 1px solid #dfdfdf;
    white-space: nowrap;
  }
}
</style>
