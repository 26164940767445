<template>
  <div class="block-wrapper">
    <div class="block-list" v-if="info.waresServiceBrief">
      <div class="block-title">服务简介</div>
      <EditorView :data="info.waresServiceBrief" />
    </div>
    <div class="block-list" v-if="info.waresServiceParam">
      <div class="block-title">服务参数</div>
      <EditorView :data="info.waresServiceParam" />
    </div>
    <div class="block-list" v-if="info.waresServiceItems">
      <div class="block-title">检测项目</div>
      <EditorView :data="info.waresServiceItems" />
    </div>
    <div class="block-list" v-if="info.checkmallWaresMethod">
      <div class="block-title">检测依据/检测方法</div>
      <EditorView :data="info.checkmallWaresMethod" />
    </div>
    <div class="block-list" v-if="info.checkmallWaresEquipment">
      <div class="block-title">检测使用设备</div>
      <EditorView :data="info.checkmallWaresEquipment" />
    </div>
    <div class="block-list" v-if="info.waresSampleRequirements">
      <div class="block-title">样品要求</div>
      <EditorView :data="info.waresSampleRequirements" />
    </div>
    <div class="block-list" v-if="lab.checkmallBusinessBrief">
      <div class="block-title">商户介绍</div>
      <EditorView :data="info.checkmallBusinessBrief" />
    </div>
  </div>
</template>
<script>
export default {
  name: "detailBase",
  components: {},
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    lab: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.block {
  &-wrapper {
    padding: 30px 50px;
  }
  &-list {
    border-bottom: 1px solid $border-color;
    padding-top: 10px;
    padding-bottom: 30px;
    /deep/ img {
      max-width: 100%;
    }
    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
  &-title {
    font-size: 1.42rem;
    font-weight: 600;
    margin-bottom: 30px;
  }
}
</style>
