import { render, staticRenderFns } from "./Cates.vue?vue&type=template&id=09a1bccd&scoped=true&"
import script from "./Cates.vue?vue&type=script&lang=js&"
export * from "./Cates.vue?vue&type=script&lang=js&"
import style0 from "./Cates.vue?vue&type=style&index=0&id=09a1bccd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09a1bccd",
  null
  
)

export default component.exports