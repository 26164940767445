<template>
  <div class="tables">
    <div v-for="(item, index) in info" :key="index" class="tables-item">
      <div v-if="checkIds.includes(item.checkmallFirstRankId + '')">
        <div class="tables-head">{{ item.checkmallFirstRankName }}</div>
        <el-table
          :data="item.secondRank"
          size="mini"
          border
          :highlight-current-row="false"
          @selection-change="
            handleSelectionChange($event, item.checkmallFirstRankId)
          "
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="checkmallSecondRankName" label="子项目名称">
          </el-table-column>
          <el-table-column prop="serviceCycle" label="周期(天)">
          </el-table-column>
          <el-table-column label="计价(元)/单位">
            <template slot-scope="scope">
              <span>{{ scope.row.price }}/{{ scope.row.serviceUnitType }}</span>
            </template>
          </el-table-column>
          <el-table-column label="检测依据/判定依据" min-width="100px">
            <template slot-scope="{ row }">
              <div class="tables-operation flex flex-bweteen">
                <div :row="row">依据实验室自主选择</div>
                <!-- <div>
                  <el-button
                    type="primary"
                    size="mini"
                    plain
                    @click="changeStandard(scope.row)"
                    class="tables-btn"
                  >
                    选择标准
                  </el-button>
                </div> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="600px" class="dialog">
      <div slot="title">
        <span class="dialog-title">选择标准</span>
        <label class="dialog-tip">(点击表格行，即可选中)</label>
      </div>
      <div class="dialog-table">
        <el-table
          ref="singleTable"
          :data="dialogTableData"
          highlight-current-row
          @current-change="handleDialogChange"
          border=""
          style="width: 100%"
        >
          <el-table-column type="index" width="50"> </el-table-column>
          <el-table-column
            property="checkmallSecondRankStandardName"
            label="标准名称"
          >
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "checkTables",
  props: {
    info: {
      type: Array,
      default: () => [],
    },
    checkIds: {
      type: Array,
      default: () => [],
    },
    unitMap: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      checkDatas: {},
      dialogVisible: false,
      dialogTableData: [],
      dialogCurrentRow: {},
      allChooseRow: {},
    };
  },
  methods: {
    // 项目表格多选框值改变时
    handleSelectionChange(val, id) {
      this.$emit("itemChildChange", { child: val, itemId: id });
    },
    // 点击选择标准按钮
    changeStandard(data) {
      this.dialogTableData = data.checkmallSecondRankStandardList;
      this.dialogVisible = true;
    },
    // 弹窗中
    handleDialogChange(val) {
      this.dialogCurrentRow = val;
    },
    dialogConfirm() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.tables {
  width: 70%;
  padding-left: 120px;

  &-item {
    margin-bottom: 10px;
    /deep/ .el-table__body tr:hover > td {
      background-color: white;
    }
  }
  &-head {
    background-color: $head-bar-bg;
    border-left: 1px solid $border-color;
    border-top: 1px solid $border-color;
    border-right: 1px solid $border-color;
    // color: white;
    height: 30px;
    line-height: 30px;
    padding-left: 15px;
  }
  &-operation {
    line-height: 28px;
  }
  &-btn {
    margin-left: 15px;
  }
}
.dialog {
  /deep/ .el-dialog__body {
    padding: 10px 20px;
  }
  &-title {
    line-height: 24px;
    font-size: 18px;
    color: #303133;
    margin-right: 10px;
  }
  &-tip {
    font-size: 0.85rem;
    color: #f56c6c;
  }
  &-table {
    /deep/ .el-table__body {
      //   padding: 20px 20px;
      tr.current-row > td {
        background-color: #cbe5ff;
      }
      tr:hover > td {
        cursor: pointer;
      }
    }
  }
}
</style>
