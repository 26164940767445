<template>
  <div class="confirm">
    <HeadBar /><!--顶部bar-->
    <HeadNav /><!-- 顶部导航-->
    <div class="confirm-wrapper flex flex-center">
      <div class="confirm-inner">
        <div class="train" v-loading="loading">
          <div class="train-title">确认订单</div>
          <div class="train-table">
            <el-table :data="tableData" border>
              <el-table-column prop="courseName" label="课程名称">
                <template slot-scope="scope">
                  <div class="train-cover flex flex-row">
                    <div>
                      <img
                        :src="scope.row.courseLogo"
                        v-if="scope.row.courseLogo"
                      />
                      <img :src="$oss('common/default_device.png')" v-else />
                    </div>
                    <div class="train-name">{{ scope.row.courseName }}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="courseOriginal" label="课程价格">
              </el-table-column>
              <el-table-column prop="courseOriginal" label="应付">
                <template slot-scope="scope">
                  <div>￥{{ scope.row.courseOriginal }}</div>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div class="price">
            <span class="price-tip">实付:</span>
            <span class="price-detail">￥{{ detail.courseOriginal }}</span>
          </div>
          <div>
            <div
              class="train-btn"
              @click="createOrderBtn"
              :loading="btnLoading"
            >
              提交订单
            </div>
          </div>
        </div>
      </div>
    </div>
    <FootBar />
  </div>
</template>
<script>
import { trainDetail, createOrder } from "@/api/onlineTrain";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      tableData: [],
      detail: {},
      id: null,
      loading: false,
      btnLoading: false,
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  created() {
    this.id = +this.$route.query.id;
    this.getDetail();
  },
  methods: {
    async getDetail() {
      this.loading = true;
      const data = await this.$axios
        .get(trainDetail, { id: this.id })
        .finally(() => {
          this.loading = false;
        });
      this.tableData = [data];
      this.detail = data;
    },
    async createOrderBtn() {
      if (this.token) {
        if (this.detail.isWatch) {
          this.$message.error("您已经购买了该课程，请勿重复购买");
          return;
        }
        this.btnLoading = true;
        const { courseId, courseName, courseOriginal } = this.detail;
        await this.$axios
          .post(createOrder, {
            courseId,
            courseName,
            pricePaid: courseOriginal,
          })
          .finally(() => {
            this.btnLoading = false;
          });
        this.$router.push({ name: "orderPay" });
      } else {
        this.$store.dispatch("login");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.confirm {
  &-wrapper {
    min-height: 500px;
  }
  &-inner {
    width: $max-width;
  }
}
.train {
  &-title {
    font-size: 1.42rem;
    margin-bottom: 20px;
  }
  &-cover {
    img {
      width: 150px;
      height: 100px;
    }
  }
  &-name {
    margin-left: 15px;
  }
  &-table {
    margin-bottom: 30px;
  }
  &-btn {
    padding: 10px 20px;
    border-radius: 4px;
    background: #eb4b35;
    color: white;
    display: inline-block;
    cursor: pointer;
  }
}
.price {
  margin-bottom: 15px;
  &-tip {
    font-size: 1.28rem;
    color: #a1a1a3;
  }
  &-detail {
    color: #eb4b35;
    font-size: 1.85rem;
    font-weight: bold;
  }
}
</style>
