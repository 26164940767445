<template>
  <div class="cate">
    <div class="cate-type flex flex-row">
      <div class="cate-label">类别</div>
      <div class="flex-1">
        <div class="cate-item cate-parent flex flex-row flex-wrap pointer">
          <div
            v-for="(item, index) in parentItem"
            :key="index"
            :class="{
              active: item.fieldCategoryId === currentParent.fieldCategoryId,
            }"
            @click="pItemClick(item)"
          >
            {{ item.fieldCategoryName }}
          </div>
        </div>
        <div class="cate-item cate-child flex flex-row flex-wrap pointer">
          <div
            v-for="(item, index) in childItem"
            :key="index"
            :class="{
              active: item.fieldCategoryId === currentChild.fieldCategoryId,
            }"
            @click="cItemClick(item)"
          >
            {{ item.fieldCategoryName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { expertCate } from "@/api/expert";
export default {
  data() {
    return {
      parentItem: [],
      childItem: [],
      currentParent: {},
      currentChild: {},
    };
  },
  created() {
    this.getType();
  },
  methods: {
    async getType(fieldCategoryPid) {
      const params = {};
      if (fieldCategoryPid) {
        params.fieldCategoryPid = fieldCategoryPid;
      }
      const data = await this.$axios.post(expertCate, params);
      this.parentItem = data;
      const { pId, cId } = this.$route.query;
      if (pId) {
        const pIndex = data.findIndex((item) => item.fieldCategoryId === +pId);
        if (cId) {
          const parent = data[pIndex];
          this.currentParent = parent;
          this.childItem = parent.children;
          const cIndex = data[pIndex].children.findIndex(
            (item) => item.fieldCategoryId === +cId
          );
          this.currentChild = data[pIndex].children[cIndex];
          this.getList();
        } else {
          this.pItemClick(data[pIndex]);
        }
      } else {
        this.pItemClick(data[0]);
      }
    },
    // 父类点击
    pItemClick(item) {
      this.currentParent = item;
      this.currentChild = {};
      this.childItem = item.children;
      this.getList();
    },
    // 子类点击
    cItemClick(item) {
      this.currentChild = item;
      this.getList();
    },
    // 获取列表数据
    async getList() {
      this.$emit("typeClick", {
        firstTypeId: this.currentParent.fieldCategoryId,
        secondTypeId: this.currentChild.fieldCategoryId,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.cate {
  margin-bottom: 30px;
  &-type {
    border: 1px solid #eeeff1;
    width: 1218px;
    margin: 0 auto;
    background: white;
    padding-top: 15px;
  }
  &-label {
    width: 100px;
    text-align: center;
    color: #888888;
    padding-top: 2px;
  }
  &-item {
    border-bottom: 1px solid #eeeff1;
    &:last-child {
      border-bottom: none;
    }
    div {
      padding: 5px 10px;
      margin-right: 10px;
      margin-bottom: 10px;
      // font-size: 12px;
      &:hover,
      &.active {
        background: #e3ecff;
        color: #4482ff;
      }
    }
  }
  &-parent {
    min-height: 40px;
  }
  &-child {
    padding-top: 10px;
    min-height: 33px;
  }
}
</style>
