<template>
  <div class="admin">
    <HeaderBar />
    <div class="admin-inner flex flex-row">
      <div class="admin-menu"><Menu :pathName="currentPathName" /></div>
      <div class="flex-1 admin-con"><router-view></router-view></div>
    </div>
    <!-- <div class="con-left"><Menu :pathName="currentPathName" /></div>
        <div class="con-right flex-1"></div> -->
    <FootBar class="footer" />
  </div>
</template>
<script>
import Menu from "./components/Menu";
import HeaderBar from "./components/HeadBar.vue";
export default {
  components: {
    HeaderBar,
    Menu,
  },
  data() {
    return {
      currentPathName: "",
    };
  },
  beforeRouteUpdate(to, from, next) {
    const { name } = to;
    this.currentPathName = name;
    next();
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.admin {
  background: #f7faff;
  &-inner {
    width: $max-width;
    margin: 0 auto;
    min-height: 670px;
    margin-bottom: 40px;
  }
  &-menu {
    width: 247px;
    background: #f2f7ff;
  }
  &-con {
    background: white;
  }
}
.footer {
  padding-top: 0;
}
</style>
