// import { APIPREFIX } from "./constant";
// 实验室列表
export const labList = `/api/unoauth/laboratory/laboratoryInfo/list`;
export const recommendLabList = `/api/unoauth/laboratory/laboratoryInfo/recommend`;
// 实验室数量
export const labTotalNum = `/api/unoauth/laboratory/selectLaboratoryCount`;
// 获取实验室详细信息
export const labDetail = `/api/unoauth/laboratory/getLaboratoryInfo`;
// 根据实验室用户ID获取检测项目
export const labCheckProjects = `/api/unoauth/laboratory/listAbility`;
// 根据实验室用户ID获取检测服务列表
export const labService = `/api/unoauth/checkmall/listRareCheckmallWares`;
// 实验室设备列表
export const labDeviceList = `/api/unoauth/laboratory/equipment/listForUser`;
// 实验室设备总数
export const labDeviceTotal = `/api/unoauth/laboratory/equipment/getTotalCount`;

// 实验室入驻
export const labRegister = `/api/oauth/laboratory/laboratoryInfo/insert`;

// 实验室管理
// 实验室关联的用户分页查询
export const labUser = `/api/oauth/relation/selectLaboratoryUserRelationList`;
// 审核加入实验室的用户
export const sheckApplyJoinLab = `/api/oauth/relation/updateLaboratoryUserRelation`;
// 删除关联
export const deleteLabUser = `/api/oauth/relation/deleteLaboratoryUserRelation`;

// 保存当前实验室用户基本信息
export const updateLaboratory = `/api/oauth/laboratory/updateLaboratory`;
