<template>
  <div class="specs">
    <div class="specs-head">
      <el-button type="primary" plain size="mini" @click="addBtnClick"
        >新增规格</el-button
      >
    </div>
    <div>
      <el-table border :data="waresRanks" size="mini">
        <el-table-column
          label="规格名称"
          key="waresRankName"
          prop="waresRankName"
          width="240"
        >
          <template slot-scope="scope">
            <el-input size="mini" v-model="scope.row.waresRankName"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="标价" prop="price">
          <template slot-scope="scope">
            <el-input size="mini" v-model="scope.row.price"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="供应商价格" prop="primePrice">
          <template slot-scope="scope">
            <el-input size="mini" v-model="scope.row.primePrice"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="折扣率">
          <template slot-scope="scope">
            <el-select v-model="scope.row.discountStatus" clearable size="mini">
              <el-option
                v-for="dict in discountStatusOptions"
                :key="dict.dictValue"
                :label="dict.dictLabel"
                :value="dict.dictValue"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="mini"
              @click="deleteRankRow(scope.$index)"
              :disabled="waresRanks.length == 1"
            >
              移除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { GOODS_DISCOUNT } from "@/constant/dict";
const Defaule_Item = {
  waresRankName: "",
  price: "",
  primePrice: "",
  discountStatus: "",
};

export default {
  data() {
    return {
      waresRanks: [{ ...Defaule_Item }],
      discountStatusOptions: [],
    };
  },
  created() {
    this.getDicts();
  },
  methods: {
    async getDicts() {
      const opts = await this.$store.dispatch("getDictData", GOODS_DISCOUNT);
      this.discountStatusOptions = opts;
    },
    addBtnClick() {
      this.waresRanks.push({ ...Defaule_Item });
    },
    deleteRankRow(index) {
      this.waresRanks.splice(index, 1);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable.scss";
.specs {
  &-head {
    border-left: 1px solid $border-color;
    border-top: 1px solid $border-color;
    border-right: 1px solid $border-color;
    padding-right: 10px;
    text-align: right;
  }
}
</style>
