<template>
  <div class="flex detail">
    <div>
      <div class="detail-cover">
        <!-- <img :src="data.banner[showImgIndex]" /> -->
      </div>
      <div class="detail-banners flex flex-row">
        <!-- <div
          v-for="(item, index) in data.banner"
          :key="index"
          @mouseover="mouseoverImg(index)"
        >
          <img :src="item" />
        </div> -->
      </div>
    </div>
    <div class="detail-intro">
      <h3 class="detail-title">{{ data.leagueName }}</h3>
      <div class="detail-lists">
        <div class="detail-list">
          <label>联盟简介：</label>{{ data.leagueBrief }}
        </div>
        <div class="detail-list">
          <label>联盟成员：</label>{{ data.memberNum }}家
        </div>
        <div class="detail-list">
          <label>最新活动：</label>{{ data.newActivityName }}
        </div>
      </div>
      <div v-if="data.isJoinLeague == 0">
        <el-button type="primary" size="small" @click="addAlliance"
          >加入联盟</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showImgIndex: 0,
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  methods: {
    mouseoverImg(index) {
      this.showImgIndex = index;
    },
    addAlliance() {
      if (!this.token) {
        this.$store.dispatch("login");
        return;
      }
      this.$router.push({
        name: "joinAlliance",
        query: {
          allianceId: this.data.leagueId,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
@mixin imgCommon() {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.detail {
  margin-bottom: 30px;
  &-cover {
    width: 500px; // w/h = 1.6
    height: 312px;
    margin-bottom: 10px;
    // border: 1px solid $border-color;
    > img {
      @include imgCommon;
    }
  }
  &-banners {
    > div {
      width: 120px;
      height: 75px;
      margin-right: 10px;
      cursor: pointer;
      //   border: 1px solid $border-color;
      > img {
        @include imgCommon;
      }
    }
  }
  &-intro {
    margin-left: 30px;
  }
  &-title {
    margin-bottom: 10px;
  }
  &-lists {
    margin-bottom: 30px;
  }
  &-list {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0px;
    }
    label {
      font-weight: bold;
    }
  }
}
</style>
