<template>
  <div class="unit">
    <div class="title title-big">
      国家新材料测试评价平台-稀土行业中心理事会单位名单
    </div>
    <el-table :data="tableData" border class="ext-table"
      ><el-table-column type="index" width="50" label="序号" align="center">
      </el-table-column>
      <el-table-column prop="unitName" label="共建单位" align="center">
      </el-table-column>
      <el-table-column
        prop="unitTypeStr"
        label="单位类型"
        align="center"
        width="180"
      >
      </el-table-column>
      <el-table-column
        prop="unitPostTypeStr"
        label="职位"
        align="center"
        width="180"
      >
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { selectConstructionUnits } from "@/api/baseIntroduction";
export default {
  data() {
    return {
      tableData: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      const res = await this.$axios.get(selectConstructionUnits);
      this.tableData = res;
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  text-align: center;
  &-big {
    font-size: 22px;
    margin-bottom: 15px;
  }
}
</style>
