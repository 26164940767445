<template>
  <div class="menu">
    <div class="title">
      <span>中心概况</span>
    </div>
    <div
      v-for="(item, index) in menus"
      :key="index"
      class="menu-item pointer"
      :class="{ active: menuActiveIndex === index }"
      @click="menuClick(index)"
    >
      {{ item.title }}
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menus: [
        { title: "中心简介", field: "subCenterIntroduce" },
        { title: "组织机构", field: "subCenterOrganization" },
        { title: "专家咨询委员会", field: "expert" },
        { title: "理事单位", field: "unit" },
      ],
      menuActiveIndex: null,
    };
  },
  created() {
    this.menuClick(0);
  },
  methods: {
    menuClick(index) {
      this.menuActiveIndex = index;
      this.$emit("menuClick", this.menus[index]);
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  height: 68px;
  line-height: 68px;
  background: #1f66f6;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;

  span {
    position: relative;
    &::before {
      content: "";
      display: inline-block;
      width: 9px;
      height: 9px;
      background: white;
      position: absolute;
      left: -20px;
      top: 10px;
      border-radius: 50%;
    }
  }
}
.menu {
  &-item {
    height: 62px;
    line-height: 62px;
    text-align: center;
    font-size: 16px;
    color: #888888;
    &:hover {
      background: #cdddff;
    }
    &.active {
      background: #cdddff;
      font-weight: bold;
      color: #222222;
    }
  }
}
</style>
