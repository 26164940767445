<template>
  <div class="set">
    <div class="set-head flex flex-row flex-between">
      <div class="set-headTitle">设置客服</div>
      <div>
        <el-button
          type="primary"
          plain
          size="mini"
          :disabled="status !== '1'"
          @click="submitBtnClick"
          >提交</el-button
        >
      </div>
    </div>
    <el-form
      class="set-form"
      :model="form"
      :rules="rules"
      ref="ruleForm"
      label-width="140px"
    >
      <el-form-item label="选择客服" prop="userId" class="set-formItem">
        <el-select
          v-model="form.userId"
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键词"
          :remote-method="remoteMethod"
          :loading="queryLoading"
        >
          <el-option
            v-for="item in options"
            :key="item.userId"
            :label="item.realName"
            :value="item.userId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <div class="set-formTip">温馨提示：商城信息为启用状态时，可设置客服</div>
    </el-form>
  </div>
</template>
<script>
import { labUser } from "@/api/lab";
import {
  getBusinessCustomerServer,
  setBusinessCustomerServer,
} from "@/api/userCenter";
import { queryUserInEnterprise } from "@/api/enterprise";
import { LAB_USER_STATUS_PASS, ENT_USER_STATUS_PASS } from "@/constant/dict";
export default {
  props: {
    status: {
      type: String,
      default: "",
    },
    businessId: {
      type: Number,
      default: null,
    },
    //（1-服务[严选]商城；2-产品[优选]商城）（固定值）
    businessType: {
      type: String,
      default: "",
    },
    // 是否为企业
    isEnt: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        userId: null,
      },
      rules: {},
      queryLoading: false,
      options: [],
      page: {
        pageIndex: 1,
        pageSize: 20,
      },
    };
  },
  created() {
    this.getCustomer();
  },
  methods: {
    // 根据名称模糊检索客服
    async remoteMethod(name) {
      this.queryLoading = true;
      const params = { ...this.page, realName: name };
      if (this.isEnt) {
        params.enterpriseRelationStatus = ENT_USER_STATUS_PASS;
      } else {
        params.laboratoryRelationStatus = LAB_USER_STATUS_PASS;
      }
      const { list } = await this.$axios
        .post(this.isEnt ? queryUserInEnterprise : labUser, params)
        .finally(() => {
          this.queryLoading = false;
        });
      this.options = list;
    },
    // 获取当前客服
    async getCustomer() {
      const res = await this.$axios.post(getBusinessCustomerServer, {
        businessId: this.businessId,
        businessType: this.businessType,
      });
      if (res) {
        const { userRealName, userId } = res;
        await this.remoteMethod(userRealName);
        Object.assign(this.form, { userId });
      }
    },
    // 设置客服按钮点击
    async submitBtnClick() {
      const { userId } = this.form;
      if (userId) {
        await this.$axios.post(setBusinessCustomerServer, {
          businessId: this.businessId,
          businessType: this.businessType,
          userId,
        });
        this.$message.success("设置客服成功");
      } else {
        this.$message.error("请先选择客服");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable.scss";
.set {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  &-head {
    border-bottom: 1px solid #dcdfe6;
    padding: 8px 10px;
    font-weight: bold;
  }
  &-headTitle {
    padding-top: 5px;
  }
  &-form {
    padding: 8px 10px 0 10px;
  }
  &-formItem {
    margin-bottom: 15px;
  }
  &-formTip {
    padding-left: 70px;
    padding-bottom: 10px;
    color: $theme-color;
  }
}
</style>
