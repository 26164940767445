<template>
  <div class="wrapper flex">
    <div class="title">{{ title }}</div>
    <div class="flex-1 content" :class="{ contentExpend: expend }">
      <el-checkbox-group v-model="selectItems" @change="checkboxChange">
        <el-checkbox v-for="(item, index) in info" :label="item" :key="index">
          {{ item.name }}
        </el-checkbox>
      </el-checkbox-group>
    </div>
    <div class="expend-arrow pointer" @click="expendClick">
      <span :class="{ down: expend }"></span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    info: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    delId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selectItems: [],
      expend: false,
    };
  },
  watch: {
    delId(val) {
      const index = this.selectItems.findIndex(({ id }) => val === id);
      this.selectItems.splice(index, 1);
    },
  },
  methods: {
    expendClick() {
      this.expend = !this.expend;
    },
    checkboxChange() {
      this.$emit("selectItemChange", this.selectItems);
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  min-height: 36px;
  border-top: 1px solid #ebeef5;
}
.title {
  width: 95px;
  background: #f3f6f8;
  padding-left: 20px;
  padding-top: 8px;
  border-right: 1px solid #ebeef5;
}
.content {
  padding: 10px 15px;
  height: 28px;
  overflow: hidden;
  /deep/ .el-checkbox {
    margin-bottom: 10px;
  }
  &.contentExpend {
    height: auto;
  }
}
.expend-arrow {
  width: 50px;
  text-align: right;
  padding-right: 12px;
  padding-top: 10px;

  > span {
    &::after {
      content: "";
      display: inline-block;
      position: relative;
      width: 6px;
      height: 6px;
      left: 6px;
      top: -2px;
      margin-right: 4px;
      border-bottom: 1px solid #666;
      border-right: 1px solid #666;
      transform: rotateZ(-45deg);
      transition: transform 0.5s;
    }
  }
  > .down {
    &::after {
      transform: rotateZ(45deg);
      transition: transform 0.3s;
    }
  }
}
</style>
