<template>
  <div>
    <el-form :inline="true" :model="form" class="qForm">
      <el-form-item label="委托单编号">
        <el-input
          v-model="form.demandTitle"
          placeholder="请输入委托单编号"
          class="title"
        ></el-input>
      </el-form-item>
      <el-form-item label="订单状态">
        <el-select
          v-model="form.demandStatus"
          placeholder="请选择状态"
          class="demandStatus"
          clearable
        >
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="(item, index) in statusOption"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
      </el-form-item>
    </el-form>
    <div class="myTable">
      <el-table :data="tableData" border>
        <el-table-column prop="orderNo" label="订单编号"> </el-table-column>
        <el-table-column prop="price" label="订单金额" width="90px">
        </el-table-column>
        <el-table-column prop="head" label="发票抬头"> </el-table-column>
        <el-table-column prop="type" label="发票类型"> </el-table-column>
        <el-table-column prop="attr" label="发票性质" width="90px">
        </el-table-column>
        <el-table-column prop="getType" label="收取方式" width="90px">
        </el-table-column>
        <el-table-column prop="status" label="发票状态" width="90px">
        </el-table-column>
        <el-table-column label="操作" width="100px">
          <template slot-scope="scope">
            <el-button size="mini" type="danger" @click="queryMail(scope.row)"
              >快递查询</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页-->
    <Pagination
      :total="totalItem"
      :page="page"
      @pageIndexChange="handlePageChange"
    />
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      form: {},
      statusOption: [
        { id: 1, name: "待审核" },
        { id: 2, name: "通过" },
        { id: 3, name: "驳回" },
      ],
      totalItem: 0,
      page: {
        pageIndex: 1,
        pageSize: 5,
      },
      tableData: [
        {
          id: 1,
          orderNo: "2008311234560269",
          price: "350",
          head: "深圳市倍测国际检测",
          type: "增值税普通发票",
          attr: "电子",
          getType: "客户自取",
          status: "通过",
        },
      ],
    };
  },
  methods: {
    onSubmit() {},
    handlePageChange() {},
  },
};
</script>
<style lang="scss" scoped>
.qForm {
  /deep/.el-form-item {
    margin-bottom: 10px;
  }
}
.myTable {
  margin-bottom: 10px;
  min-height: 500px;
}
</style>
