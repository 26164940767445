<template>
  <div class="ability">
    <div class="query">
      <el-form :inline="true" :model="queryForm" :size="queryFormSize">
        <el-form-item label="能力名称">
          <el-input
            v-model="queryForm.searchKey"
            placeholder="请输入能力名称"
            class="query-name"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="项目分类">
          <el-cascader
            v-model="queryForm.cateId"
            :options="allAbilityTypes"
            :props="{
              value: 'laboratoryAbilityTypeId',
              label: 'laboratoryAbilityTypeName',
              children: 'laboratoryAbilityTypeSon',
            }"
            clearable
            class="query-kind"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="状态">
          <el-select
            v-model="queryForm.abilityStatus"
            placeholder="请选择"
            class="query-status"
            clearable
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.dictValue"
              :label="item.dictLabel"
              :value="item.dictValue"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="queryBtnClick"
            :loading="listLoading"
            >查询</el-button
          >
          <el-button type="primary" @click="addBtnClick">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="data" v-loading="listLoading">
      <TableData
        :data="tableData"
        :statusMap="statusMap"
        @updateBtn="updateBtn"
        @viewBtn="viewBtn"
        @delBtn="delBtn"
        @refresh="refresh"
      />
    </div>
    <!-- 分页-->
    <Pagination
      :total="totalItem"
      :page="page"
      @pageIndexChange="handlePageChange"
    />
    <el-drawer
      title="能力维护"
      :visible.sync="showDrawer"
      :wrapperClosable="false"
      size="900px"
      class="drawer"
    >
      <AbilityDetail
        :curId="selectRowId"
        :isEdit="drawerIsEdit"
        :allAbilityTypes="allAbilityTypes"
        v-if="showDrawer"
        @saveSuccess="saveSuccess"
      />
    </el-drawer>
  </div>
</template>
<script>
import { abilityList, allAbilityType } from "@/api/hall";
import { delAbility } from "@/api/userCenter";
import { QUERY_FORM_SIZE } from "@/constant/size";
import { ABILITY_STATUS } from "@/constant/dict";
import TableData from "./components/TableData";
import AbilityDetail from "./components/AbilityDetail";
import Pagination from "@/components/Pagination/index";

export default {
  components: {
    Pagination,
    TableData,
    AbilityDetail,
  },
  data() {
    return {
      totalItem: 0,
      page: {
        pageIndex: 1,
        pageSize: 10,
      },
      queryFormSize: QUERY_FORM_SIZE,
      drawerIsEdit: null,
      selectRowId: null,
      showDrawer: false,
      queryForm: {
        searchKey: "",
        abilityStatus: null,
        cateId: [],
      },
      kindOptions: [],
      statusOptions: [],
      statusMap: {},
      tableData: [],
      listLoading: false,
      allAbilityTypes: [],
    };
  },
  created() {
    this.getDict();
    this.getAllAbilityType();
    this.queryList();
  },
  methods: {
    async getAllAbilityType() {
      const res = await this.$axios.get(allAbilityType);
      this.allAbilityTypes = res;
    },
    // 获取数据字典
    async getDict() {
      const data = await this.$store.dispatch("getDictData", ABILITY_STATUS);
      this.statusOptions = data;
      const statusMap = {};
      data.forEach((item) => {
        statusMap[item.dictValue] = item.dictLabel;
      });
      this.statusMap = statusMap;
    },
    // 查询列表
    async queryList() {
      this.listLoading = true;
      const params = {
        ...this.page,
        abilityType: 1, //0：首页用户，1：实验室
      };
      const { cateId, ...rest } = this.queryForm;
      Object.assign(params, { ...rest });
      if (cateId && cateId.length) {
        // 一级或二级id只穿一个
        if (cateId[1]) Object.assign(params, { secondTypeId: cateId[1] });
        else Object.assign(params, { firstTypeId: cateId[0] });
      }
      const { list, totalItem } = await this.$axios
        .post(abilityList, params)
        .finally(() => {
          this.listLoading = false;
        });
      this.totalItem = totalItem;
      this.tableData = list;
    },
    // 分页查询
    handlePageChange(index) {
      this.page.pageIndex = index;
      this.queryList();
    },
    refresh() {
      this.page.pageIndex = 1;
      this.queryList();
    },
    // 查询按钮
    queryBtnClick() {
      this.queryList();
    },
    // 新增按钮
    addBtnClick() {
      this.drawerIsEdit = false;
      this.selectRowId = null;
      this.showDrawer = true;
    },
    // 修改按钮
    updateBtn(id) {
      this.drawerIsEdit = true;
      this.selectRowId = id;
      this.showDrawer = true;
    },
    viewBtn(id) {
      this.selectRowId = id;
      this.showDrawer = true;
    },
    // 保存成功后
    saveSuccess() {
      this.showDrawer = false;
      this.queryList();
    },
    // 删除按钮
    async delBtn(abilityId) {
      await this.$confirm("确定删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      await this.$axios.post(delAbility, { abilityId });
      this.$message.success("删除成功");
      this.queryList();
    },
  },
};
</script>
<style lang="scss" scoped>
.drawer {
  /deep/ .el-drawer__header span {
    font-weight: bold;
    font-size: 1.07rem;
  }
}
.ability {
  padding: 10px;
}
.query {
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }
  &-status {
    width: 120px;
  }
  &-name {
    width: 220px;
  }
  &-kind {
    width: 252px;
  }
}
.data {
  margin-bottom: 20px;
  min-height: 500px;
}
</style>
