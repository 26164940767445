<template>
  <div class="list">
    <div class="list-head"><i class="el-icon-caret-bottom"></i>咨询会话</div>
    <div>
      <div
        v-for="(item, index) in data"
        :key="index"
        class="flex flex-row list-item pointer"
        :class="{ active: consultIndex === index }"
        @click="listItemClick(item, index)"
      >
        <div class="list-avator">
          <img src="@/assets/chartroom/customerImg.png" />
        </div>
        <div class="flex-1">
          {{ item.sendName }}
          <!-- <div class="list-last ellipsis">{{ item.last }}</div> -->
        </div>
        <div class="list-unread" v-if="item.unRead">
          <span>{{ item.unRead }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      consultIndex: null,
    };
  },
  methods: {
    listItemClick(item, index) {
      this.consultIndex = index;
      this.$emit("currentFriend", index);
      if (item.unRead) {
        this.$emit("unReadClear", index);
      }
      const { sendId, reviceId } = item;
      this.$emit("getHistory", { customerId: reviceId, consultId: sendId });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
@import "@/style/common";
.list {
  &-head {
    height: 30px;
    line-height: 30px;
    padding: 0 5px;
    border-bottom: 1px solid $border-color;
    i {
      color: black;
      font-size: 18px;
    }
  }
  &-item {
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #f3f3f3;
    &.active {
      background: #deecff;
    }
    &:last-child {
      border-bottom: none;
    }
    padding: 0 5px;
  }
  &-avator {
    margin-right: 8px;
    img {
      width: 35px;
      height: 35px;
    }
  }
  // &-userName {
  //   font-size: 12px;
  //   font-weight: bold;
  //   padding-top: 5px;
  //   padding-bottom: 5px;
  // }
  // &-last {
  //   color: $font-color-gray;
  //   font-size: 12px;
  //   max-width: 120px;
  // }
  &-unread {
    span {
      background: #f56c6c;
      padding: 1px 5px;
      font-size: 12px;
      border-radius: 50%;
      color: white;
    }
  }
}
</style>
