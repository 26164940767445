<template>
  <el-drawer
    title="确认委托单"
    :visible.sync="showDrawer"
    :wrapperClosable="false"
    size="900px"
    class="drawer"
  >
    <div class="flex flex-center table-wrapper">
      <div class="border">
        <div class="head">
          <div class="head-name">{{ serve.checkmallWaresName }}</div>
          <!-- <div class="head-no">订单编号: {{ data.orderNo }}</div> -->
        </div>
        <div class="order">
          <TableForm
            :laboratory="laboratory"
            :order="order"
            @showProtocol="dialogVisible = true"
            @confirmDeed="$emit('confirmDeed')"
          />
          <div class="tel">电话：0731-84118699</div>
        </div>
      </div>
    </div>
    <div class="flex flex-center">
      <el-button type="primary" size="small" @click="printClick">
        打印委托协议</el-button
      >
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="70%"
      class="dialogP"
    >
      <div class="dialogP-con">
        <div class="dialogP-title">协议条款</div>
        <div
          v-for="(item, index) in protocol"
          :key="index"
          class="dialogP-list"
        >
          <span class="dialogP-index">{{ index + 1 }}.</span>{{ item }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </el-drawer>
</template>
<script>
import TableForm from "./TableForm.vue";
export default {
  components: {
    TableForm,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      default: () => ({}),
    },
    serve: {
      type: Object,
      default: () => ({}),
    },
    laboratory: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dialogVisible: false,
      protocol: [
        "委托方对所提供的委托方单位、地址、样品名称等所有与样品检测有关的资料的正确性、真实性、合法性负责。上述信息未经湖南航天天麓新材料检测有限责任公司（以下简称天麓公司）证实，天麓公司不对其真实性负责。因委托方提供的资料存在虚假、误导、隐瞒、重大遗漏而造成损失的，由委托方承担全部责任，天麓公司不承担由于委托方填写信息内容不清晰、不完整所带来的相关责任。",
        "委托方在办理委托首先是应书面指定检测标准或方法提供全面、完整、清晰的相关信息。委托方未书面指定的，天麓公司有权自行决定检测标准或方法。",
        "委托方应当根据天麓公司的要求提供检测样品，因委托方无法提供符合检查需求的样品而导致延迟或无法出具检测报告的责任，由委托方承担全部责任。委托方需要退还样品的，必须在办理委托检测时书面告知天麓公司，并在收到检查报告三十天内取回剩余检测样品，另有约定的除外。委托方未书面告知退还样品的，天麓公司有权在检测完成一个月后对剩余样品作遗弃处理。样品品质不宜保留的，天麓公司可在检测结束后立即处理。",
        "除非另有约定，检测周期为委托方办理委托和缴费手续，并递交样品后第二个工作日起计时的工作天数，不包括星期六、星期日和法定节假日。天麓公司在约定的检测期间内无法出具检测报告的，应提前告知委托方延迟的原因，并另行商定出具检测报告的时间。",
        "天麓公司严格遵守法律、法规，对检测报告的公正性、准确性和真实性负责，对委托方的技术资料、检测数据等做到严格保密，未经委托方书面同意，不得向第三方披露，因法律规定、司法程序或行政监管的要求而强制披露的除外。",
        "天麓公司出具检测报告包括正本一份（交委托方）、副本一份（天麓公司留存）。委托方要求增加检测报告副本的，每份副本收取工本费100元。委托方需要英文检测报告的，按约定另行收取费用。除双方约定使用快递送达外，委托方应及时领取检测报告，逾期三个月不领取的，天麓公司有权将检测报告做遗弃处理。",
        "委托方对检测报告有异议的，应当自收到检测报告之日起七个工作日内向天麓公司提出书面异议，逾期未提出书面异议的，视为无异议。由于天麓公司的原因导致需对检测报告内容进行更改的，天麓公司应当重新为委托方出具检测报告，并承担更改报告产生的费用，委托方向天麓公司交还原检测报告。由于委托方自身的原因导致需要对检测报告内容进行更改的，委托方应当向天麓公司提交修改申请。经天麓公司审核同意予以重新出具检测报告，相关费用由委托方承担。",
        "检测报告得出的数据或结论是基于特定的时间、特定的方法依据特定的适用标准对检测样品特征、成分、性能或质量进行的描述，采用不同的方法和标准、在不同的环境条件下对样品进行检测有可能得出不同的结论。",
        "由委托方自行送样时，天麓公司检测报告得出的数据或结论仅对检测样品有效，不适用于检测样品以外的相同批次、相同规格或相同品牌的产品，也不适用于证明与制作、加工或生产检测样品相关的方法、流程或工艺的正确性、合理性。委托方或者第三人仅以本检测报告为依据进行批量生产所造成的任何损失均自行承担，与天麓公司无关。委托方如需对某一批次产品进行检测，应向天麓公司申请对整批产品进行抽样检测并支付相关费用。",
        "检测报告仅限于委托方使用，委托方将检测报告转给任何第三方使用所造成的任何损失，天麓公司将不承担赔偿责任。委托方将检测报告用于对外公示、广告宣传以及司法目的之前，其相关内容积及形式应事先征得天麓公司的书面同意。委托方应将报告作为一个整体使用，未经天麓公司书面同意，任何部分引述或者部分复制检测报告的行为视为无效。委托方应合法合理的使用检测报告，不得对检测报告内容进行私自涂改、篡改。如果委托方滥用或者违法使用检测报告，侵犯第三方全系或者造成第三方损失的，天麓公司不承担任何责任。如果因委托方不合理使用检测报告，导致天麓公司被司法或行政机关裁判或决定向第三方承担责任，或者遭受其他损失的，委托方应当赔偿天麓公司所遭受的所有损失，包括但不限于赔偿款、诉讼费、律师费、交通费、公证费等。",
        "因天麓公司的过失或疏忽导致检测报告失实，造成委托方直接经济损失的，天麓公司负责赔偿，赔偿限额不超过检测费用的两倍；造成委托方间接经济损失、精神损害以及订立合同时无法预见因违反合同可能造成的损失的，天麓公司不承担赔偿责任；造成第三方的任何损失，天麓公司将不承担赔偿责任。",
        "因不可抗力、自然灾害、国家法律政策、行政强制行为或司法强制行为导致委托方提供的检测样品损毁、灭失，或者导致天麓公司无法出具检测报告，或者造成委托方的任何损失和费用，天麓公司不负责赔偿责任。",
        "本协议的成立、生效、履行和解释，均适用于中华人民共和国法律；法律法规无明文规定的，可适用于通行的检测行业惯例。本协议的任何条款如因任何原因而被确认无效，都不影响本协议其他条款的效力。",
        "本委托协议自委托方盖章或签名时生效。",
      ],
    };
  },
  computed: {
    showDrawer: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    showProtocol() {
      this.dialogVisible = true;
    },
    // 点击打印按钮
    printClick() {
      this.$jump.push({ name: "deedPrint" });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.border {
  border: 1px solid #ddd;
  border-radius: 4px;
}
.head {
  width: 678px;
  border-bottom: 1px solid #ddd;
  padding: 20px 0;
  text-align: center;
  background: $head-bar-bg;
  &-name {
    font-size: 1.14rem;
    font-weight: 600;
    margin-bottom: 8px;
  }
  &-no {
    font-size: 12px;
    color: #999;
  }
}
.table-wrapper {
  margin-bottom: 15px;
}

.order {
  padding: 15px 10px;
}
.tel {
  text-align: right;
  padding-right: 2px;
}
.dialogP {
  /deep/ .el-dialog__body {
    padding: 5px 20px;
  }
  &-con {
    max-height: 500px;
    overflow: scroll;
  }
  &-title {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  &-list {
    line-height: 25px;
    margin-bottom: 8px;
  }
  &-index {
    font-weight: bold;
    margin-right: 5px;
  }
}
</style>
