<template>
  <div class="query flex">
    <el-form
      :inline="true"
      :model="form"
      class="qForm flex-1"
      :class="{ contentExpend: expend }"
      :size="queryFormSize"
    >
      <el-form-item label="订单编号">
        <el-input
          v-model="form.orderCode"
          placeholder="请输入订单编号"
          class="demandTitle"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="下单时间">
        <el-date-picker
          v-model="form.time"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="委托单位">
        <el-input
          v-model="form.orderCompany"
          placeholder="请输入委托单位"
          class="demandTitle"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="委托人员">
        <el-input
          v-model="form.orderLinkman"
          placeholder="请输入委托人员"
          class="demandTitle"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="订单状态">
        <OrderStatus v-model="form.orderCheckStatus" class="demandStatus" />
      </el-form-item>
      <el-form-item label="支付状态">
        <PayStatus v-model="form.orderPayStatus" class="demandStatus" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit" :loading="load"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <div class="expend-arrow pointer" @click="expendClick">
      <span :class="{ down: expend }"></span>
    </div>
  </div>
</template>
<script>
import PayStatus from "@/components/ManageQueryOptions/PayStatus";
import OrderStatus from "@/components/ManageQueryOptions/DeedOrderStatus";
import { abilityType } from "@/api/hall";
import { QUERY_FORM_SIZE } from "@/constant/size";
export default {
  props: {
    load: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    OrderStatus,
    PayStatus,
  },
  data() {
    return {
      expend: true,
      queryFormSize: QUERY_FORM_SIZE,
      form: {
        orderCode: null,
        orderCheckStatus: null,
        orderPayStatus: null,
        orderCompany: null,
        orderLinkman: null,
        time: [],
      },
      typeOptions: [],
      orderStatusOption: [
        { id: 1, name: "等待付款" },
        { id: 2, name: "等待样品" },
      ],
      payStatusOption: [
        { id: 1, name: "已支付" },
        { id: 2, name: "未支付" },
      ],
    };
  },
  created() {
    // this.getType();
  },
  methods: {
    expendClick() {
      this.expend = !this.expend;
    },
    // 获取需求分类
    async getType() {
      const data = await this.$axios.get(abilityType, { pid: 0 });
      this.typeOptions = data;
    },
    onSubmit() {
      this.$emit("toQuery", this.form);
    },
  },
};
</script>
<style lang="scss" scoped>
.demandTitle {
  width: 300px;
}
.demandStatus {
  width: 120px;
}
.qForm {
  height: 42px;
  overflow: hidden;
  &.contentExpend {
    height: auto;
  }
  /deep/.el-form-item {
    margin-bottom: 10px;
  }
  /deep/ .el-date-editor {
    width: 400px;
  }
}
.expend-arrow {
  width: 50px;
  text-align: right;
  padding-right: 12px;
  padding-top: 10px;

  > span {
    &::after {
      content: "";
      display: inline-block;
      position: relative;
      width: 6px;
      height: 6px;
      left: 6px;
      top: -2px;
      margin-right: 4px;
      border-bottom: 1px solid #666;
      border-right: 1px solid #666;
      transform: rotateZ(-45deg);
      transition: transform 0.5s;
    }
  }
  > .down {
    &::after {
      transform: rotateZ(45deg);
      transition: transform 0.3s;
    }
  }
}
</style>
