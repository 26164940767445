<template>
  <el-select v-model="payStatus" placeholder="请选择分类" clearable>
    <el-option
      :label="item.dictLabel"
      :value="item.dictValue"
      v-for="(item, index) in payStatusOption"
      :key="index"
    ></el-option>
  </el-select>
</template>
<script>
import { PAY_STATUS } from "@/constant/dict";
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    payStatus: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {
      payStatusOption: [],
    };
  },
  created() {
    this.getPayTayeDict();
  },
  methods: {
    async getPayTayeDict() {
      const data = await this.$store.dispatch("getDictData", PAY_STATUS);
      this.payStatusOption = data;
    },
  },
};
</script>
