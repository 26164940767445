<template>
  <table class="onlyHead">
    <thead>
      <tr>
        <th width="3.5%">
          <el-checkbox v-model="allCheck" @change="checkChange"
            >全选</el-checkbox
          >
        </th>
        <th width="46.5%">商品</th>
        <th width="15%">单价</th>
        <th width="15%">数量</th>
        <th width="10%">金额</th>
        <th width="10%">操作</th>
      </tr>
    </thead>
  </table>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    allCheck: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    checkChange(val) {
      this.$emit("checkChange", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.onlyHead {
  width: 100%;
  margin-bottom: 30px;
}
</style>
