<template>
  <div>
    <HeadBar />
    <!--顶部bar-->
    <HeadNav /><!-- 顶部导航-->
    <div class="expert">
      <div class="expert-inner" v-loading="loading">
        <div class="brief flex flex-row">
          <div class="brief-cover">
            <img :src="detail.trainerImage" />
          </div>
          <div class="flex-1">
            <div class="flex flex-row flex-between brief-little">
              <div>
                <div class="brief-name">{{ detail.trainerName }}</div>
                <div class="brief-flag">
                  <span>{{ detail.trainerUniversity }}</span>
                  <span>{{ eduMap[+detail.trainerEducation] }}</span>
                  <span v-if="detail.trainerPositionTitle">{{
                    detail.trainerPositionTitle
                  }}</span>
                </div>
              </div>
            </div>
            <div class="brief-detail">
              <!-- <div class="brief-list brief-m-bottom flex flex-row">
                <div><i class="el-icon-star-on"></i></div>
                <div>
                  <label>擅长</label>
                  <span>{{ detail.fieldExesStr }}</span>
                </div>
              </div> -->
              <div class="flex flex-row">
                <div class="brief-list flex flex-row brief-m-right">
                  <div><i class="el-icon-folder-opened"></i></div>
                  <div>
                    <label class="brief-title">研究领域</label>
                    <span>{{ detail.trainerField }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="detail-title">培训讲师介绍</div>
          <div class="detail-desc">{{ detail.trainerBrief }}</div>
        </div>
      </div>
    </div>
    <FootBar class="footer" />
  </div>
</template>
<script>
import { EDU_GRADE } from "@/constant/dict";
import { mapGetters } from "vuex";
import { teacherDetail } from "@/api/teacher";
export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      detail: {},
      loading: false,
      eduMap: {},
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  created() {
    this.getDict();
    this.getDetail();
  },
  methods: {
    async getDict() {
      const res = await this.$store.dispatch("getDictData", EDU_GRADE);
      const eduMap = {};
      res.forEach((item) => {
        eduMap[item.dictValue] = item.dictLabel;
      });
      this.eduMap = eduMap;
    },
    async getDetail() {
      this.loading = true;
      const { id } = this.$route.query;
      const data = await this.$axios.get(teacherDetail + id).finally(() => {
        this.loading = false;
      });
      this.detail = data;
    },
    toEvaluation() {
      if (!this.token) {
        this.$store.dispatch("login");
        return;
      }
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.expert {
  min-height: 550px;
  background: $home-bg;
  padding-top: 20px;
  &-inner {
    width: $max-width;
    margin: auto;
    background: white;
    min-height: 500px;
    padding: 35px 40px;
  }
}
.footer {
  padding-top: 0;
}
.brief {
  margin-bottom: 48px;
  &-cover {
    width: 224px;
    height: 250px;
    margin-right: 30px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-title {
    font-weight: bold;
  }
  &-little {
    margin-bottom: 17px;
  }
  &-name {
    font-size: 27px;
    font-weight: 400;
    color: #333333;
    margin-bottom: 14px;
  }
  &-flag {
    > span {
      padding: 5px 20px;
      font-size: 16px;
      margin-right: 13px;
      &:nth-child(1) {
        background: #e3ecff;
        color: #4482ff;
      }
      &:nth-child(2) {
        background: #fff2de;
        color: #f5a623;
      }
      &:nth-child(3) {
        background: #fff0f2;
        color: #fd485e;
      }
    }
    > span:empty {
      display: none;
    }
  }
  &-btn {
    margin-bottom: 36px;
    span {
      color: white;
      background: #4482ff;
      padding: 8px 28px;
      display: inline-block;
    }
  }
  &-detail {
    background: #f5faff;
    padding: 25px 24px;
  }
  &-m-bottom {
    margin-bottom: 18px;
  }
  &-m-right {
    margin-right: 75px;
  }
  &-list {
    i {
      background: #b6ceff;
      color: white;
      border-radius: 50%;
      padding: 4px;
      margin-right: 13px;
    }
    label {
      margin-right: 5px;
      color: #666666;
    }
  }
}
.detail {
  // &-border {
  //   border-left: 4px solid #4482ff;
  //   margin-right: 12px;
  // }
  &-title {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    position: relative;
    padding-left: 15px;
    margin-bottom: 20px;
    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      left: 0;
      top: 4px;
      width: 4px;
      background: #4482ff;
      height: 18px;
    }
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      right: 0;
      top: 12px;
      width: 90%;
      background: #ebebeb;
      height: 1px;
    }
  }
  &-desc {
    padding: 0 17px;
    line-height: 35px;
    color: #6b7474;
  }
}
</style>
