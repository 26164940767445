<!-- 客户信息 -->
<template>
  <table border="1" cellspacing="0" width="100%" class="cus-table">
    <tbody align="center">
      <tr>
        <td colspan="6" class="bold">客户信息</td>
      </tr>
      <tr>
        <td width="14%" class="bold">单位名称</td>
        <td colspan="3">{{ data.companyName }}</td>
        <td width="10%" class="bold">联系人</td>
        <td width="24%">{{ data.linkMan }}</td>
      </tr>
      <tr>
        <td>邮寄地址</td>
        <td colspan="3">
          <span>{{ data.addressProvince }}</span>
          <span>{{ data.addressCity }}</span>
          <span>{{ data.addressDistrict }}</span>
          <span>{{ data.addressDetailed }}</span>
        </td>
        <td class="bold">电&nbsp;&nbsp;&nbsp;&nbsp;话</td>
        <td>{{ data.linkPhone }}</td>
      </tr>
      <tr>
        <td class="bold">来样日期</td>
        <td>
          <span>{{ sampleTime.year }}</span>
          <label>年</label>
          <span>{{ sampleTime.month }}</span>
          <label>月</label>
          <span>{{ sampleTime.day }}</span>
          <label>日</label>
          <span>{{ sampleTime.hour }}</span>
          <label>时</label>
        </td>
        <td width="10%">传&nbsp;&nbsp;&nbsp;真</td>
        <td width="14%"></td>
        <td>邮&nbsp;&nbsp;&nbsp;&nbsp;箱</td>
        <td>{{ data.linkEmail }}</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { parseTime } from "@/utils/index";
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      sampleTime: {},
    };
  },
  created() {
    this.getTimeNow();
  },
  methods: {
    getTimeNow() {
      const time = new Date().getTime();
      const splitTime = parseTime(time, "{y}-{m}-{d}-{h}").split("-");
      Object.assign(this.sampleTime, {
        year: splitTime[0],
        month: splitTime[1],
        day: splitTime[2],
        hour: splitTime[3],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.cus {
  &-table {
    tr td {
      padding: 4px 0;
    }
  }
}
</style>
