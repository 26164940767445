<template>
  <el-upload
    :action="cloudParams.uploadUrl"
    :data="postData"
    list-type="picture-card"
    class="imgUpload"
    :class="{ uploadHidden: fileList.length === limit, limitWidth: limit == 1 }"
    :multiple="false"
    :limit="limit"
    :file-list="fileList"
    :on-success="onSuccess"
    :on-preview="onPreview"
    :before-upload="beforeUpload"
    :on-change="onChange"
    :on-remove="onRemove"
  >
    <i class="el-icon-plus"></i>
  </el-upload>
</template>
<script>
import { fileCloudToken } from "@/api/common";
export default {
  props: {
    limit: {
      type: Number,
      default: 1,
    },
    imgs: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      disabled: false,
      cloudParams: {
        uploadUrl: "",
      },
      postData: {
        token: "",
      },
      fileList: [],
    };
  },
  watch: {
    async imgs(newVal, oldVal) {
      const _newVal = newVal || "";
      const _imgs = _newVal.split(_newVal.includes(";") ? ";" : ",");
      if (!oldVal || _newVal.length > oldVal.length) {
        // 增加
        _imgs.forEach((img) => {
          const fIndex = this.fileList.findIndex(({ url }) => url === img);
          if (fIndex < 0) this.fileList.push({ url: img });
        });
      } else {
        // 删除
        _imgs.forEach((img) => {
          const fIndex = this.fileList.findIndex(({ url }) => url === img);
          if (fIndex > -1) this.fileList.slice(fIndex, 1);
        });
      }
    },
  },
  created() {
    this.getToken();
  },
  methods: {
    async getToken() {
      const res = await this.$axios.get(fileCloudToken);
      this.cloudParams = res;
      this.postData.token = res.token;
    },
    onRemove(file, fileList) {
      this.fileList = fileList;
      const { displayUrl } = this.cloudParams;
      const urls = fileList.map(({ name, url, response }) => {
        return name ? `${displayUrl}/${response.key}` : url;
      });
      this.$emit("onSuccess", urls.join(";"));
    },
    onSuccess(res, file, fileList) {
      const { displayUrl } = this.cloudParams;
      const urls = fileList.map(({ name, url, response }) => {
        return name ? `${displayUrl}/${response.key}` : url;
      });
      this.$emit("onSuccess", urls.join(";"));
    },
    onPreview() {
      // console.log(file);
    },
    beforeUpload(file) {
      this.postData.key = `${new Date().getTime()}/${file.name}`;
    },
    onChange() {
      // console.log("onChange", file);
    },
    // sliderRequest(upload) {
    //   const formData = new FormData(); // 创建FormData对象 添加相关上传参数
    //   // 文件对象
    //   formData.append("file", upload.file);
    //   // key 文件名处理 images/时间戳_随机字符串.文件后缀
    //   formData.append("key", `images/${upload.file.name}`);
    //   // token
    //   formData.append("token", this.postData.token);

    //   // 上传文件
    //   this.$axios
    //     .post(this.postData.uploadUrl, formData, {
    //       onUploadProgress: (event) => {
    //         // 监听上传进度
    //         event.percent = (event.loaded / event.total) * 100;
    //         upload.onProgress(event);
    //       },
    //     })
    //     .then((response) => {
    //       const res = response.data;
    //       if (res.code === 200) {
    //         // 调用组件上传成功方法
    //         upload.onSuccess();
    //         // 轮播图上传成功 这里做相关逻辑this.$message.success('上传成功')
    //       }
    //     })
    //     .catch((err) => {
    //       // 调用组件上传失败方法
    //       upload.onError();
    //       this.$message.error("上传失败," + err);
    //     });
    // },
  },
};
</script>
<style lang="scss" scoped>
.uploadHidden {
  /deep/ .el-upload {
    display: none;
  }
}
.imgUpload {
  height: 148px;
  overflow: hidden;
  /deep/ .el-upload-list__item {
    > img {
      object-fit: contain;
    }
  }
  // /deep/.el-upload-list__item.is-ready {
  //   display: none;
  // }
}
.limitWidth {
  width: 148px;
}
</style>
