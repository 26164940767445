<template>
  <div class="brief flex">
    <div class="brief-cover">
      <img :src="data.courseLogo" v-if="data.courseLogo" />
      <img :src="$oss('common/default_device.png')" v-else />
    </div>
    <div class="flex-1">
      <div class="brief-name">{{ data.courseName }}</div>
      <div class="brief-brief">{{ data.courseBrief }}</div>
      <div class="brief-price">
        <span v-if="data.courseOriginal">￥{{ data.courseOriginal }}</span>
        <span v-else>免费</span>
      </div>
      <div
        class="brief-view brief-btn"
        @click="toView"
        v-if="(data.isWatch && token) || !data.courseOriginal"
      >
        立即观看
      </div>
      <div class="brief-pay brief-btn" @click="toPay" v-else>立即购买</div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["token"]),
  },
  methods: {
    toPay() {
      if (this.token) {
        this.$router.push({
          name: "trainOrderConfirm",
          query: { id: this.data.courseId },
        });
      } else {
        this.$store.dispatch("login");
      }
    },
    toView() {
      this.$router.push({
        name: "onlineVideoView",
        query: { id: this.data.courseId },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.brief {
  padding-bottom: 28px;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 30px;
  &-cover {
    width: 557px;
    height: 310px;
    margin-right: 44px;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-name {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 25px;
  }
  &-brief {
    font-size: 12px;
    color: #666666;
    margin-bottom: 22px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  &-price {
    height: 50px;
    line-height: 50px;
    padding-left: 20px;
    background: #f4f5f6;
    font-size: 24px;
    color: #ff6000;
    font-weight: bold;
    margin-bottom: 20px;
  }
  &-btn {
    display: inline-block;
    width: 145px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 2px;
    color: white;
    font-size: 1.28rem;
    cursor: pointer;
  }
  &-pay {
    background: #4483ff;
  }
  &-view {
    background: #4483ff;
  }
}
</style>
