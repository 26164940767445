export default {
  demandTitle: [
    {
      required: true,
      message: "请输入需求标题",
      trigger: ["blur", "change"],
    },
  ],
  demandProjectName: [
    {
      required: true,
      message: "请输入检测项目",
      trigger: ["blur", "change"],
    },
  ],
  laboratoryAbilityTypeId: [
    {
      required: true,
      message: "请选择检测分类",
      trigger: "change",
    },
  ],
  demandSampleInfo: [
    {
      required: true,
      message: "请输入样品信息",
      trigger: ["blur", "change"],
    },
  ],
  qualificationId: [
    {
      required: true,
      message: "请选择资质要求",
      trigger: "change",
    },
  ],
  demandRequirements: [
    {
      required: true,
      message: "请输入检测要求",
      trigger: ["blur", "change"],
    },
  ],
  demandPublishAddress: [
    {
      required: true,
      message: "请选择城市",
      trigger: ["change"],
    },
  ],
  demandContactsPhone: [
    {
      required: true,
      message: "请输入联系电话",
      trigger: ["blur", "change"],
    },
  ],
};
