<template>
  <el-form
    ref="form"
    :model="form"
    label-width="130px"
    class="form"
    v-loading="pageLoading"
  >
    <div v-if="applyResult" class="block">
      <div class="block-title">审核结果</div>
      <div class="block-con">
        <div class="block-line">
          <label>企业名称:</label>
          <span>{{ applyResult.enterpriseName }}</span>
        </div>
        <div class="block-line">
          <label>企业联系人:</label>
          <span>{{ applyResult.enterpriseContactsName }}</span>
          <span>({{ applyResult.enterpriseContactsPhone }})</span>
        </div>
        <div class="block-line">
          <label>审核时间:</label>
          <span>{{ applyResult.createTime }}</span>
        </div>
        <div class="block-line">
          <label>审核状态:</label>
          <span>{{ statusMap[applyResult.enterpriseRelationStatus] }}</span>
        </div>
      </div>
    </div>
    <div v-if="canApply">
      <div class="block">
        <div class="block-title">{{ tipText }}选择机构</div>
        <div class="block-con">
          <div class="block-line">
            <label>选择机构：</label>
            <el-select
              v-model="form.enterpriseId"
              size="small"
              filterable
              remote
              reserve-keyword
              clearable
              placeholder="请输入机构名称"
              :remote-method="searchOrg"
              :loading="searchLoading"
              class="block-select"
              @change="labChange"
            >
              <el-option
                v-for="item in orgs"
                :key="item.enterpriseId"
                :label="item.enterpriseName"
                :value="item.enterpriseId"
              >
              </el-option>
            </el-select>
          </div>
          <template v-if="selectOrg">
            <div class="block-line">
              <label>实验室名称:</label>
              <span>{{ selectOrg.enterpriseName }}</span>
            </div>
            <div class="block-line">
              <label>实验室联系人:</label>
              <span>{{ selectOrg.enterpriseContactsName }}</span>
              <span>({{ selectOrg.enterpriseContactsPhone }})</span>
            </div>
          </template>
        </div>
      </div>
      <div>
        <el-button
          type="primary"
          @click="joinOrgBtnClick"
          :loading="btnLoading"
          size="small"
          >{{ tipText }}提交</el-button
        >
      </div>
    </div>
  </el-form>
</template>
<script>
import {
  queryEntByKeyword,
  toJoinEnterprise,
  queryUserEnterpriseStatus,
} from "@/api/enterprise";
import { MALL_STATUS_FAILD } from "@/constant/dict.js";
export default {
  props: {
    statusMap: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      form: {
        enterpriseId: null,
      },
      searchLoading: false,
      orgs: [],
      orgInfo: {},
      selectOrg: null,
      btnLoading: false,
      applyResult: null,
      pageLoading: false,
    };
  },
  computed: {
    canApply() {
      return (
        !this.applyResult ||
        this.applyResult.enterpriseRelationStatus === MALL_STATUS_FAILD
      );
    },
    tipText() {
      return this.applyResult &&
        this.applyResult.enterpriseRelationStatus === MALL_STATUS_FAILD
        ? "重新"
        : "";
    },
  },
  created() {
    // 查询申请记录
    this.judgeJoinOrg();
  },
  methods: {
    // 查询申请记录
    async judgeJoinOrg() {
      this.pageLoading = true;
      const res = await this.$axios
        .post(queryUserEnterpriseStatus)
        .finally(() => {
          this.pageLoading = false;
        });
      const {
        passEnterpriseUserRelationRespond: pass,
        checkingEnterpriseUserRelationRespond: checking,
        noCheckEnterpriseUserRelationRespondList: failed,
      } = res || {};
      const applyResult =
        pass || checking || (failed && failed[failed.length - 1]);
      if (applyResult) {
        await this.getOrgInfo(applyResult.enterpriseId);
        this.applyResult = { ...applyResult, ...this.orgInfo };
      }
    },
    async getOrgInfo(orgId) {
      this.pageLoading = true;
      const data = await this.$axios
        .post(queryEntByKeyword, {
          enterpriseId: orgId,
          pageIndex: 1,
          pageSize: 20,
        })
        .finally(() => {
          this.pageLoading = false;
        });

      this.orgInfo = data[0];
    },
    async searchOrg(value) {
      if (value !== "") {
        this.searchLoading = true;
        const list = await this.$axios
          .post(queryEntByKeyword, {
            enterpriseName: value,
            pageIndex: 1,
            pageSize: 20,
          })
          .finally(() => {
            this.searchLoading = false;
          });
        this.orgs = list || [];
      } else {
        this.orgs = [];
      }
    },
    labChange(val) {
      if (val) {
        const info = this.orgs.find((item) => item.enterpriseId === val);
        this.selectOrg = info || null;
      } else {
        this.selectOrg = null;
      }
    },
    async joinOrgBtnClick() {
      const { enterpriseId } = this.selectOrg;
      if (!enterpriseId) {
        this.$message.warning("请先选择企业");
        return;
      }
      this.btnLoading = true;
      await this.$axios
        .post(toJoinEnterprise, {
          enterpriseId,
        })
        .finally(() => {
          this.btnLoading = false;
        });
      this.$message.success("提交成功，请等待审核");
      this.$emit("applySuccess");
      this.judgeJoinOrg();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.block {
  margin-bottom: 20px;
  border: 1px solid $border-color;
  &-title {
    border-bottom: 1px solid $border-color;
    padding: 5px 10px;
    background: #4482ff;
    color: white;
    font-weight: bold;
  }
  &-con {
    padding: 5px 10px;
  }
  &-line {
    margin-bottom: 10px;
    > label {
      margin-right: 10px;
      color: $font-color-gray;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &-select {
    width: 350px;
  }
}
</style>
