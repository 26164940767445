<template>
  <el-dialog
    title="申请申述"
    :visible.sync="dialogVisible"
    width="500px"
    class="dialog"
  >
    <el-form :model="form" label-width="100px" class="form">
      <el-form-item label="订单编号" class="margin-bottom-0">
        {{ data.orderCode }}
      </el-form-item>
      <el-form-item label="仪器名称" class="margin-bottom-10">
        {{ data.checkmallWaresName }}
      </el-form-item>
      <el-form-item label="申请申述内容">
        <el-input
          v-model="form.applyAppealContent"
          type="textarea"
          :rows="5"
          resize="none"
          :maxlength="400"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitBtn" :loading="loading"
        >提 交</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import { toAskFor } from "@/api/userCenter";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    dialogVisible: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {
      form: {
        applyAppealContent: "",
      },
      loading: false,
    };
  },
  methods: {
    async submitBtn() {
      this.loading = true;
      await this.$axios
        .post(toAskFor, {
          ...this.form,
          orderId: this.data.checkmallOrderMainId,
          mallOrderType: 0, // 商城订单类别 0:严选商城，1：优选商城
        })
        .finally(() => {
          this.loading = false;
        });
      this.$message.success("提交成功");
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.margin-bottom-0 {
  margin-bottom: 0;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.dialog {
  /deep/ .el-dialog__body {
    padding: 0 20px;
  }
}
</style>
