<template>
  <div class="title">
    <span>
      <label v-for="(item, index) in title.split('')" :key="index">{{
        item
      }}</label>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.title {
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: url("../../../assets/home/mall/title-bg.png");
  background-size: auto 4px;
  background-repeat: repeat-x;
  background-position-y: center;
  font-size: 22px;
  margin-bottom: 25px;
  span {
    background: $home-bg;
    padding: 0 40px;
    color: #333333;
  }
  label {
    padding-right: 30px;
    position: relative;

    &::after {
      position: absolute;
      content: "/";
      top: 0px;
      right: 8px;
    }
    &:last-child {
      padding-right: 0px;
      &::after {
        content: none;
      }
    }
  }
}
</style>
