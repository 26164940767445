<template>
  <el-dialog title="添加物流信息" :visible.sync="dialogVisible" width="30%">
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="快递单号">
        <el-input v-model="form.orderExpressNum"></el-input>
      </el-form-item>
      <el-form-item label="快递公司">
        <el-select
          v-model="form.orderExpressCompany"
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键词"
          :remote-method="remoteMethod"
          :loading="searchLoading"
        >
          <el-option
            v-for="item in expressCompanyOptions"
            :key="item.kuaidiCompanyCode"
            :label="item.kuaidiCompanyName"
            :value="item.kuaidiCompanyCode"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="saveBtn" :loading="saveLoading"
        >保 存</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import {
  getKuaidiList,
  goodAddKuaidiInfo,
  serviceAddKuaidiInfo,
} from "@/api/userCenter";
export default {
  props: {
    // 控制弹窗的显示和隐藏
    value: {
      type: Boolean,
      default: false,
    },
    // 订单id
    orderId: {
      type: Number,
      default: null,
    },
    // 委托单id
    deedId: {
      type: Number,
      default: null,
    },
    // 订单或者委托单信息
    order: {
      type: Object,
      default: () => ({}),
    },
    // 是否是退样
    isRetreat: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expressCompanyOptions: [],
      form: {
        orderExpressCompany: "",
        orderExpressNum: "",
      },
      saveLoading: false,
      searchLoading: false,
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created() {
    this.queryLog();
  },
  methods: {
    async queryLog() {
      // 订单
      if (this.orderId) {
        const { orderExpressNum, orderExpressCompany } = this.order;
        if (orderExpressNum) {
          const res = await this.$axios.post(getKuaidiList, {
            kuaidiCompanyCode: orderExpressCompany,
          });
          this.expressCompanyOptions = res;
          const { kuaidiCompanyName } = res[0];
          Object.assign(this.form, {
            orderExpressNum,
            orderExpressCompany: kuaidiCompanyName,
          });
        }
      } else if (this.deedId) {
        // 委托单
        const {
          sampleMailNumber,
          sampleMailExpressCompany,
          recoverMailNumber,
          recoverMailExpressCompany,
        } = this.order.checkmallOrdeListMailExpress;
        // 退样添加物流信息
        if (this.isRetreat) {
          if (recoverMailNumber) {
            const res = await this.$axios.post(getKuaidiList, {
              kuaidiCompanyCode: recoverMailExpressCompany,
            });
            this.expressCompanyOptions = res;
            const { kuaidiCompanyName } = res[0];
            Object.assign(this.form, {
              orderExpressNum: recoverMailNumber,
              orderExpressCompany: kuaidiCompanyName,
            });
          }
        } else if (sampleMailNumber) {
          const res = await this.$axios.post(getKuaidiList, {
            kuaidiCompanyCode: sampleMailExpressCompany,
          });
          this.expressCompanyOptions = res;
          const { kuaidiCompanyName } = res[0];
          Object.assign(this.form, {
            orderExpressNum: sampleMailNumber,
            orderExpressCompany: kuaidiCompanyName,
          });
        }
      }
    },
    async remoteMethod(value) {
      this.searchLoading = true;
      if (value) {
        const res = await this.$axios
          .post(getKuaidiList, {
            kuaidiCompanyName: value,
          })
          .finally(() => {
            this.searchLoading = false;
          });
        this.expressCompanyOptions = res;
      }
    },
    async saveBtn() {
      const { orderExpressCompany, orderExpressNum } = this.form;
      if (orderExpressCompany && orderExpressNum) {
        // 优选商城管理，添加物流信息
        if (this.orderId) {
          await this.$axios.post(goodAddKuaidiInfo, {
            orderExpressCompany,
            orderExpressNum,
            productmallOrderId: this.orderId,
          });
        } else if (this.deedId) {
          // 我的委托单寄样，添加样品物流信息
          await this.$axios.post(serviceAddKuaidiInfo, {
            orderExpressCompany,
            orderExpressNum,
            checkmallOrderId: this.deedId,
            expressType: this.isRetreat ? "2" : "1", // 快递类别（1-用户寄样；2-实验室退样)
          });
        }

        this.$message.success("添加成功");
        this.$emit("addLogSuccess");
        this.dialogVisible = false;
      } else {
        this.$message.error("快递单号和快递公司不能为空");
      }
    },
  },
};
</script>
