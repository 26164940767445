<template>
  <el-form :inline="true" :model="form" class="qForm" :size="queryFormSize">
    <el-form-item label="需求名称">
      <el-input
        v-model="form.demandTitle"
        placeholder="请输入需求名称"
        class="demandTitle"
        clearable
      ></el-input>
    </el-form-item>
    <el-form-item label="分类">
      <el-select v-model="form.firstTypeId" placeholder="请选择分类" clearable>
        <el-option
          :label="item.laboratoryAbilityTypeName"
          :value="item.laboratoryAbilityTypeId"
          v-for="(item, index) in typeOptions"
          :key="index"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="需求状态">
      <el-select
        v-model="form.demandStatus"
        placeholder="请选择分类"
        class="demandStatus"
        clearable
      >
        <el-option
          :label="item.dictLabel"
          :value="item.dictValue"
          v-for="(item, index) in statusOption"
          :key="index"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="发布时间">
      <el-date-picker
        v-model="form.time"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="timestamp"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit" :loading="load"
        >查询</el-button
      >
    </el-form-item>
  </el-form>
</template>
<script>
import { QUERY_FORM_SIZE } from "@/constant/size";
import { abilityType } from "@/api/hall";
export default {
  props: {
    statusOption: {
      type: Array,
      default: () => [],
    },
    load: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      queryFormSize: QUERY_FORM_SIZE,
      form: {
        demandTitle: null,
        firstTypeId: null,
        demandStatus: null,
        time: [],
      },
      typeOptions: [],
      day: 24 * 60 * 60 * 1000,
    };
  },
  created() {
    this.getType();
  },
  methods: {
    // 获取需求分类
    async getType() {
      const data = await this.$axios.get(abilityType, { pid: 0 });
      this.typeOptions = data;
    },
    onSubmit() {
      const { time, ...rest } = this.form;
      const params = {
        ...rest,
      };
      if (time && time.length) {
        Object.assign(params, {
          beginTime: time[0],
          endTime: time[1] + this.day,
        });
      }
      this.$emit("query", params);
    },
  },
};
</script>
<style lang="scss" scoped>
.demandTitle {
  width: 300px;
}
.demandStatus {
  width: 120px;
}
.qForm {
  /deep/.el-form-item {
    margin-bottom: 10px;
  }
  /deep/ .el-date-editor {
    width: 300px;
  }
}
</style>
