<template>
  <div class="tabs">
    <el-tabs v-model="activeName">
      <el-tab-pane label="基本信息" name="first">
        <Base v-if="activeName === 'first'" />
      </el-tab-pane>
      <el-tab-pane label="修改密码" name="second">
        <Password v-if="activeName === 'second'" />
      </el-tab-pane>
      <!-- <el-tab-pane label="修改手机号" name="third">
        <EditPhone v-if="activeName === 'third'" />
      </el-tab-pane> -->
      <el-tab-pane label="所属机构" name="fourth">
        <Company v-if="activeName === 'fourth'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import Base from "./components/Base";
import Password from "./components/Password";
// import EditPhone from "./components/EditPhone";
import Company from "./components/Company";
import { mapGetters } from "vuex";
export default {
  components: {
    Base,
    Password,
    // EditPhone,
    Company,
  },
  data() {
    return {
      activeName: "first",
    };
  },
  computed: {
    ...mapGetters(["userBaseInfo"]),
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.tabs {
  padding: 0 15px;
}
</style>
