<template>
  <el-dialog title="创建会议" :visible.sync="dialogVisible" width="500px">
    <el-form
      :model="form"
      :rules="rules"
      label-width="110px"
      class="form"
      ref="ruleForm"
    >
      <el-form-item label="会议主题" prop="subject">
        <el-input v-model="form.subject" :maxlength="100"></el-input>
      </el-form-item>
      <el-form-item label="会议开始时间" prop="startTime">
        <el-date-picker
          type="datetime"
          placeholder="选择开始时间"
          v-model="form.startTime"
          style="width: 100%"
          value-format="timestamp"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="会议时长" prop="duration">
        <el-input-number
          v-model="form.duration"
          controls-position="right"
          :min="1"
          :max="999"
          class="duration"
        ></el-input-number>
        分钟
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirmClick" :loading="btnLoading"
        >提 交</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import { createMeetting } from "@/api/meetting";

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; //如果没有后面的-8.64e7就是不可以选择今天的
        },
      },
      form: {
        subject: "",
        startTime: null,
        duration: null,
      },
      rules: {
        subject: [
          { required: true, message: "请输入会议主题", trigger: "blur" },
        ],
        startTime: [
          { required: true, message: "选择开始时间", trigger: "change" },
        ],
        duration: [
          { required: true, message: "请输入会议时长", trigger: "blur" },
        ],
      },
      btnLoading: false,
    };
  },
  computed: {
    dialogVisible: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    async confirmClick() {
      await this.$refs.ruleForm.validate();
      this.btnLoading = true;
      await this.$axios.post(createMeetting, this.form).finally(() => {
        this.btnLoading = false;
      });
      this.$message.success("创建会议成功");
      this.$emit("input", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.duration {
  width: 150px;
  margin-right: 10px;
}
</style>
