<template>
  <div class="settle">
    <div class="settle-inner flex flex-row">
      <Platform />
      <Scroll
        title="入驻企业"
        btnText="立即入驻"
        @btnClick="settledEnt"
        :lists="ents"
        :bg="$oss('home/入驻企业.jpg')"
      />
      <Scroll
        title="入驻实验室"
        btnText="立即入驻"
        @btnClick="settledLab"
        :lists="labs"
        :bg="$oss('home/入住实验室.jpg')"
      />
      <Scroll
        title="最新需求"
        btnText="发布需求"
        :bg="$oss('home/最新需求.jpg')"
        :lists="demands"
        @btnClick="releaseReq"
      />
    </div>
  </div>
</template>
<script>
import Platform from "./Platform.vue";
import Scroll from "./Scroll.vue";
import { demandList } from "@/api/hall";
import { getUserLabInfo } from "@/api/userCenter";
import { settledNewEnt, settledNewLab } from "@/api/home";
import { mapGetters } from "vuex";
import { parseTime } from "@/utils/index";
export default {
  components: {
    Platform,
    Scroll,
  },
  data() {
    return {
      page: {
        pageIndex: 1,
        pageSize: 7,
      },
      demands: [],
      ents: [],
      labs: [],
    };
  },
  computed: {
    ...mapGetters(["token", "userBaseInfo"]),
  },
  created() {
    // 获取需求
    this.getDemandList();
    // 获取企业
    this.getEnt();
    // 获取实验室
    this.getLab();
  },
  methods: {
    async getEnt() {
      const { list } = await this.$axios.post(settledNewEnt, this.page);
      this.ents = list.map((item) => {
        return {
          title: item.enterpriseName,
          date: parseTime(item.createTimeStamp, "{m}-{d}"),
        };
      });
    },
    async getLab() {
      const { list } = await this.$axios.post(settledNewLab, this.page);
      this.labs = list.map((item) => {
        return {
          title: item.laboratoryName,
          date: parseTime(item.createTimeStamp, "{m}-{d}"),
        };
      });
    },
    // 获取需求
    async getDemandList() {
      const { list } = await this.$axios.post(demandList, this.page);
      this.demands = list.map((item) => {
        return {
          title: item.demandProjectName,
          date: item.createTime.substr(5),
        };
      });
    },
    addDemandClick() {},
    // 点击入驻实验室
    async settledLab() {
      if (this.token) {
        const isLab = await this.isJoinLab();
        if (isLab) {
          this.$message.warning("您已入驻实验室，不能重复入驻");
          return;
        }
        if (this.isJoinEnt("您已申请入驻企业，不能再入驻实验室")) return;
        this.$jump.push({ name: "labRegister" });
      } else {
        this.$store.dispatch("login");
      }
    },
    // 发布检测需求
    releaseReq() {
      if (this.token) {
        this.$jump.push({ name: "releaseReq" });
      } else {
        this.$store.dispatch("login");
      }
    },
    // 入驻企业
    async settledEnt() {
      if (this.token) {
        if (this.isJoinEnt('"您已申请入驻企业，不能重复入驻"')) return;
        const isLab = await this.isJoinLab();
        if (isLab) {
          this.$message.warning("您已入驻实验室，不能再入驻企业");
          return;
        }
        this.$jump.push({ name: "entRegister" });
      } else {
        this.$store.dispatch("login");
      }
    },
    isJoinEnt(msg) {
      const { isBusiness } = this.userBaseInfo;
      const isEnt = "1";
      const entChecking = "2";
      if ([isEnt, entChecking].includes(isBusiness)) {
        this.$message.warning(msg);
        return true;
      }
      return false;
    },
    async isJoinLab() {
      const data = await this.$axios.get(getUserLabInfo);
      if (data && data.laboratoryId) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
@import "@/style/common";
.settle {
  &-inner {
    width: $max-width;
    margin: 0 auto;
  }
}
</style>
