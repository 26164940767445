<template>
  <div class="send">
    <div class="send-inner flex flex-column">
      <!-- <SendMsgBar @emojiClick="emojiClick" /> -->
      <div class="flex-1">
        <textarea
          class="textarea"
          v-model.trim="content"
          placeholder="请输入您想咨询的问题"
        />
      </div>
      <div class="send-btn">
        <el-button
          type="primary"
          size="mini"
          @click="sendMsgBtnClick"
          :disabled="!content"
          >发送</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
// import SendMsgBar from "./SendMsgBar.vue";
export default {
  components: {
    // SendMsgBar,
  },
  data() {
    return {
      content: "",
    };
  },
  methods: {
    emojiClick(item) {
      this.content += item.title;
    },
    // 发送按钮点击
    sendMsgBtnClick() {
      if (!this.content) {
        this.$message.info("不能发送空消息");
        return;
      }
      this.$emit("send", this.content);
      this.content = "";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.send {
  padding: 0 3px;
  height: 150px;
  &-inner {
    border: 4px solid #f7f7f7;
    border-radius: 5px 5px 0 0;
    height: 100%;
    box-sizing: border-box;
  }
  &-btn {
    text-align: right;
    padding: 0 5px 2px 0;
  }
}
.textarea {
  height: 100%;
  width: 100%;
  resize: none;
  border: none;
  padding: 10px;
  box-sizing: border-box;
}
</style>
