export default [
  {
    name: "南海诸岛",
    itemStyle: {
      areaColor: "#6F798D",
      opacity: 0.65,
    },
  },
  {
    name: "北京",
    itemStyle: {
      areaColor: "#034DE4",
      opacity: 0.65,
    },
  },
  {
    name: "天津",
    itemStyle: {
      areaColor: "#034DE4",
      opacity: 0.65,
    },
  },
  {
    name: "上海",
    itemStyle: {
      areaColor: "#27E9FD",
      opacity: 0.65,
    },
  },
  {
    name: "重庆",
    itemStyle: {
      areaColor: "#0E7DEB",
      opacity: 0.65,
    },
  },
  {
    name: "河北",
    itemStyle: {
      areaColor: "#034DE4",
      opacity: 0.65,
    },
  },
  {
    name: "河南",
    itemStyle: {
      areaColor: "#034DE4",
      opacity: 0.65,
    },
  },
  {
    name: "云南",
    itemStyle: {
      areaColor: "#20CAF8",
      opacity: 0.65,
    },
  },
  {
    name: "辽宁",
    itemStyle: {
      areaColor: "#034DE4",
      opacity: 0.65,
    },
  },
  {
    name: "黑龙江",
    itemStyle: {
      areaColor: "#17A3F1",
      opacity: 0.65,
    },
  },
  {
    name: "湖南",
    itemStyle: {
      areaColor: "#0554E4",
      opacity: 0.65,
    },
  },
  {
    name: "安徽",
    itemStyle: {
      areaColor: "#27E8FD",
      opacity: 0.65,
    },
  },
  {
    name: "山东",
    itemStyle: {
      areaColor: "#4BFFFF",
      opacity: 0.65,
    },
  },
  {
    name: "新疆",
    itemStyle: {
      areaColor: "#17A3F1",
      opacity: 0.65,
    },
  },
  {
    name: "江苏",
    itemStyle: {
      areaColor: "#00b6f6",
      opacity: 0.65,
    },
  },
  {
    name: "浙江",
    itemStyle: {
      areaColor: "#00b6f6",
      opacity: 0.65,
    },
  },
  {
    name: "江西",
    itemStyle: {
      areaColor: "#01a4f1",
      opacity: 0.65,
    },
  },
  {
    name: "湖北",
    itemStyle: {
      areaColor: "#005ce5",
      opacity: 0.65,
    },
  },
  {
    name: "广西",
    itemStyle: {
      areaColor: "#0088ec",
      opacity: 0.65,
    },
  },
  {
    name: "甘肃",
    itemStyle: {
      areaColor: "#0042df",
      opacity: 0.65,
    },
  },
  {
    name: "山西",
    itemStyle: {
      areaColor: "#01d0fa",
      opacity: 0.65,
    },
  },
  {
    name: "内蒙古",
    itemStyle: {
      areaColor: "#00ccf7",
      opacity: 0.65,
    },
  },
  {
    name: "陕西",
    itemStyle: {
      areaColor: "#01b0f3",
      opacity: 0.65,
    },
  },
  {
    name: "吉林",
    itemStyle: {
      areaColor: "#0051e1",
      opacity: 0.65,
    },
  },
  {
    name: "福建",
    itemStyle: {
      areaColor: "#0158e3",
      opacity: 0.65,
    },
  },
  {
    name: "贵州",
    itemStyle: {
      areaColor: "#0199ef",
      opacity: 0.65,
    },
  },
  {
    name: "广东",
    itemStyle: {
      areaColor: "#00d9fc",
      opacity: 0.65,
    },
  },
  {
    name: "青海",
    itemStyle: {
      areaColor: "#005ae4",
      opacity: 0.65,
    },
  },
  {
    name: "西藏",
    itemStyle: {
      areaColor: "#01e9fd",
      opacity: 0.65,
    },
  },
  {
    name: "四川",
    itemStyle: {
      areaColor: "#0069e8",
      opacity: 0.65,
    },
  },
  {
    name: "宁夏",
    itemStyle: {
      areaColor: "#01b0f3",
      opacity: 0.65,
    },
  },
  {
    name: "海南",
    itemStyle: {
      areaColor: "#00f0ff",
      opacity: 0.65,
    },
  },
  {
    name: "台湾",
    itemStyle: {
      areaColor: "#0067e7",
      opacity: 0.65,
    },
  },
  {
    name: "香港",
    itemStyle: {
      areaColor: "#00dafa",
      opacity: 0.65,
    },
  },
  {
    name: "澳门",
    itemStyle: {
      areaColor: "#00dafa",
      opacity: 0.65,
    },
  },
];
