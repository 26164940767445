<template>
  <el-drawer title="申请申述结果" :visible.sync="showDrawer" size="800px">
    <div class="ask">
      <div v-for="(item, index) in results" :key="index" class="ask-item">
        <div class="flex">
          <div>申请申述：</div>
          <div class="ask-con flex-1">{{ item.applyAppealContent }}</div>
        </div>
        <div class="ask-createTime">
          <div>申述时间：{{ item.createTime }}</div>
        </div>
        <div class="flex">
          <div>申述结果：</div>
          <div :class="{ [statusClass[+item.applyAppealStatus]]: true }">
            {{ +item.applyAppealStatus ? "已处理" : "未处理" }}
          </div>
        </div>
        <div class="flex ask-list" v-if="!!+item.applyAppealStatus">
          <div>处理时间：</div>
          <div>{{ item.updateTime }}</div>
        </div>
      </div>
    </div>
  </el-drawer>
</template>
<script>
import { askForResult } from "@/api/userCenter";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    deedId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    showDrawer: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {
      statusClass: {
        0: "no",
        1: "yes",
      },
      results: [],
    };
  },
  created() {
    this.getResult();
  },
  methods: {
    async getResult() {
      const res = await this.$axios.get(askForResult, {
        orderId: this.deedId,
      });
      this.results = res;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.ask {
  padding: 0 20px;
  &-item {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 2px dashed $border-color;
  }
  &-con {
    border: 1px solid $border-color;
    padding: 5px 10px;
    border-radius: 5px;
    min-height: 50px;
    margin-bottom: 3px;
  }
  &-createTime {
    text-align: right;
    color: #999;
    font-size: 0.85rem;
    margin-bottom: 10px;
  }
}
div.no {
  color: #f56c6c;
}
div.yes {
  color: #67c23a;
  margin-bottom: 10px;
}
</style>
