<template>
  <div>
    <HeadBar /><!--顶部bar-->
    <HeadNav /><!-- 顶部导航-->
    <div class="demand">
      <div class="demand-inner" v-loading="loading">
        <div class="demand-head">{{ detail.demandProjectName }}</div>
        <div class="demand-content">
          <div class="demand-list flex flex-row">
            <div class="demand-label">发布时间</div>
            <div class="demand-con">{{ detail.createTime }}</div>
          </div>
          <div class="demand-list flex flex-row" v-if="token">
            <div class="demand-label">样品信息</div>
            <div class="demand-con">{{ detail.demandSampleInfo }}</div>
          </div>
          <div class="demand-list flex flex-row">
            <div class="demand-label">检测项目</div>
            <div class="demand-con">{{ detail.demandProjectName }}</div>
          </div>
          <div class="demand-list flex flex-row">
            <div class="demand-label">检测分类</div>
            <div class="demand-con">
              {{ detail.laboratoryAbilityTypePidName }}
            </div>
          </div>
          <div class="demand-list flex flex-row">
            <div class="demand-label">资质要求</div>
            <div class="demand-con icons flex flex-row">
              <div
                v-for="(item, index) in detail.qualificationList"
                :key="index"
                class="demand-icons"
              >
                <img :src="item.qualificationIco" />
              </div>
            </div>
          </div>
          <div class="demand-list flex flex-row" v-if="token">
            <div class="demand-label">检测要求</div>
            <div class="demand-con flex-1">{{ detail.demandRequirements }}</div>
          </div>
          <div class="demand-list flex flex-row">
            <div class="demand-label">联系方式</div>
            <div class="demand-con flex-1">
              <span v-if="token">{{ detail.demandContactsPhone }}</span>
              <span v-else class="demand-telLogin pointer" @click="toLogin"
                >请登录查看</span
              >
            </div>
          </div>
          <div class="demand-list flex flex-row">
            <div class="demand-label">需求状态</div>
            <div class="demand-con flex-1">
              {{ statusMap[+detail.demandStatus] }}
            </div>
          </div>
          <div class="demand-list flex flex-row" v-if="token">
            <div class="demand-label">
              图<span class="demand-labelSpan"></span>片
            </div>
            <div class="demand-con flex-1">
              <img
                :src="detail.demandImage"
                v-if="detail.demandImage"
                class="demand-dImg"
              />
            </div>
          </div>
          <div class="demand-list flex flex-row" v-if="token">
            <div class="demand-label">
              位<span class="demand-labelSpan"></span>置
            </div>
            <div class="demand-con flex-1">
              <span>{{ detail.demandPublishProvince }}</span>
              <span>{{ detail.demandPublishCity }}</span>
              <span>{{ detail.demandPublishCounty }}</span>
              <span>{{ detail.demandPublishAddress }}</span>
            </div>
          </div>
        </div>
        <div class="demand-tip pointer" v-if="!token" @click="toLogin">
          更多内容请登录查看
        </div>
      </div>
    </div>
    <FootBar />
  </div>
</template>
<script>
import { demandDetail } from "@/api/hall";
import { mapGetters } from "vuex";
import { DEMAND } from "@/constant/dict";
export default {
  data() {
    return {
      detail: {},
      form: {},
      statusMap: {},
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  created() {
    this.getStatus();
    this.getDetail();
  },
  methods: {
    async getStatus() {
      const data = await this.$store.dispatch("getDictData", DEMAND);
      this.statusOption = data;
      const statusMap = {};
      data.forEach((item) => {
        statusMap[item.dictValue] = item.dictLabel;
      });
      statusMap["1"] = "对接中";
      statusMap["0"] = "对接完成";
      this.statusMap = statusMap;
    },
    async getDetail() {
      const id = this.$route.query.id;
      this.loading = true;
      const data = await this.$axios
        .get(demandDetail, { demandId: id })
        .finally(() => {
          this.loading = false;
        });
      this.detail = data;
    },
    toLogin() {
      this.$store.dispatch("login");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.demand {
  min-height: 500px;
  padding-top: 50px;
  &-inner {
    width: 780px;
    margin: 0 auto;
  }
  &-head {
    height: 55px;
    line-height: 55px;
    background: #dee9ff;
    font-size: 20px;
    font-weight: bold;
    color: #343434;
    text-align: center;
  }
  &-list {
    border-left: 1px solid #ecf0f7;
    border-right: 1px solid #ecf0f7;
    &:nth-child(even) {
      background: #f2f5fb;
    }
  }
  &-labelSpan {
    padding: 0 16px;
  }
  &-content {
    border-bottom: 1px solid #ecf0f7;
    margin-bottom: 20px;
  }
  &-label {
    width: 246px;
    padding: 20px 20px 20px 117px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 400;
    color: #888888;
    border-right: 1px solid #ecf0f7;
  }
  &-con {
    box-sizing: border-box;
    padding: 20px 20px 20px 40px;
    &.icons {
      padding: 8px 20px 8px 40px;
    }
  }
  &-icons {
    background: #ffffff;
    width: 60px;
    height: 40px;
    margin-right: 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &-telLogin {
    color: #4482ff;
  }
  &-tip {
    font-size: 18px;
    text-align: center;
  }
  &-dImg {
    background: #f3f4f8;
    max-width: 300px;
    max-height: 300px;
    vertical-align: middle;
    object-fit: contain;
  }
}
</style>
