<template>
  <el-dialog title="废弃订单" :visible.sync="dialogVisible" width="600px">
    <div>
      <el-form ref="form" label-width="120px">
        <el-form-item label="废弃订单备注：">
          <el-input v-model="type"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dialogVisible: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {
      // dialogVisible: false,
      type: "",
    };
  },
};
</script>
