<template>
  <div class="pro">
    <div class="pro-top flex flex-row">
      <div class="flex-1">
        <div class="pro-name pointer" @click="checkDetail">
          {{ info.abilityName }}
        </div>
        <div class="pro-desc ellipsis">
          <label>检测分类：</label
          ><span>{{ info.laboratoryAbilityTypeName }}</span>
        </div>
        <div class="pro-desc ellipsis">
          <label>检测方法：</label>
          <span>{{ info.checkMethod }}</span>
        </div>
      </div>
      <div class="pro-lab flex flex-row">
        <div class="flex-1">
          <div class="lab-name pointer" @click="toLab">
            {{ info.laborarotyName }}
          </div>
          <div class="lab-address">
            <span>{{ info.laboratoryLocationProvince }}</span>
            <span>{{ info.laboratoryLocationCity }}</span>
            <span>{{ info.laboratoryLocationCounty }}</span>
            <span>{{ info.laboratoryAddress }}</span>
          </div>
        </div>
        <div class="lab-img pointer" @click="toLab">
          <img :src="info.laboratoryLogo" v-if="info.laboratoryLogo" />
        </div>
      </div>
    </div>
    <div class="pro-bottom flex flex-row flex-between">
      <div class="pro-zizhi">
        <span
          v-for="(item, index) in info.rareLaboratoryAbilityQualifications"
          :key="index"
          >{{ item.qualificationName }}</span
        >
      </div>
      <div class="pro-tel">
        <label>服务热线：</label>
        <span>{{ info.laboratoryContactsPhone }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  methods: {
    checkDetail() {
      const { laboratoryAbilityId } = this.info;
      this.$jump.push({
        name: "checkProjectDetail",
        query: { id: laboratoryAbilityId },
      });
    },
    toLab() {
      const { laborarotyId } = this.info;
      if (laborarotyId) {
        this.$jump.push({
          name: "labDetail",
          query: { id: laborarotyId },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.pro {
  margin-top: 24px;
  &-top {
    background: white;
    // min-height: 121px;
    padding: 20px;
    box-sizing: border-box;
  }
  &-bottom {
    background: #f7faff;
    padding: 6px 22px;
    box-sizing: border-box;
  }
  &-lab {
    width: 450px;
  }
  &-name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  &-desc {
    font-size: 14px;
    color: #9c9c9c;
    max-width: 700px;
  }
  &-zizhi {
    span {
      background: #ffffff;
      border: 1px solid #e3ecff;
      border-radius: 3px;
      padding: 0px 12px;
      font-size: 18px;
      color: #4482ff;
      margin-right: 12px;
    }
  }
  &-tel {
    width: 438px;
    font-size: 18px;
    font-weight: bold;
    color: #4482ff;
  }
}
.lab {
  &-name {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 6px;
  }
  &-address {
    font-size: 15px;
    color: #666666;
  }
  &-img {
    width: 160px;
    height: 85px;
    border: 1px solid #eaf0f1;
    margin-left: 24px;
    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
