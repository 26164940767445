<template>
  <div class="order" v-loading="loading">
    <el-collapse v-model="activeNames">
      <el-collapse-item title="订单信息" name="1">
        <div class="flex">
          <div>
            <div class="order-list">
              <label>订单编号：</label>
              <span>{{ orderInfo.productmallOrderCode }}</span>
            </div>
            <div class="order-list">
              <label>下单时间：</label>
              <span>{{ orderInfo.createTime }}</span>
            </div>
          </div>
          <div class="order-status flex-1 flex">
            <div class="order-status-label"><label>订单状态：</label></div>
            <div class="flex-1">
              <Flow class="order-flow" />
            </div>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item title="商品信息" name="2">
        <Goods
          :goods="orderInfo.orderRanks"
          :labName="orderInfo.productmallBusinessName"
          :totalMoney="orderInfo.orderPrice"
        />
      </el-collapse-item>
      <el-collapse-item title="收货人信息" name="3">
        <ReceiverInfo :data="receiver" />
      </el-collapse-item>
      <el-collapse-item title="收货人留言" name="4">
        <div class="order-message">{{ orderInfo.orderLeavingMessage }}</div>
      </el-collapse-item>
      <el-collapse-item title="物流信息" name="5">
        <div class="order-express">暂无物流信息</div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import Flow from "../OrderFlow/Order.vue";
import Goods from "./Goods";
import ReceiverInfo from "./ReceiverInfo";
import { userGoodOrderDetail, mallGoodOrderDetail } from "@/api/userCenter";
export default {
  props: {
    orderId: {
      type: Number,
      default: null,
    },
    isLab: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Flow,
    Goods,
    ReceiverInfo,
  },
  data() {
    return {
      activeNames: ["1", "2", "3", "4", "5"],
      orderInfo: {},
      loading: false,
      receiver: {}, // 收货人信息
    };
  },
  created() {
    this.getOrderDetail();
  },
  methods: {
    async getOrderDetail() {
      this.loading = true;
      console.log("orderId", this.orderId);
      const res = await this.$axios
        .get(this.isLab ? mallGoodOrderDetail : userGoodOrderDetail, {
          productmallOrderId: this.orderId,
        })
        .finally(() => {
          this.loading = false;
        });
      this.orderInfo = res;
      this.receiver = res.productmallOrderAddressResponse;
    },
  },
};
</script>
<style lang="scss" scoped>
.order {
  padding: 0 20px;
  /deep/ .el-collapse-item__content {
    padding-bottom: 0;
  }
  /deep/ .el-collapse-item__header {
    font-weight: bold;
  }
  &-list {
    margin-bottom: 10px;
    > label {
      color: #909399;
    }
  }
  &-status {
    margin-left: 50px;
    &-label {
      color: #909399;
    }
  }
  &-flow {
    width: 300px;
  }
  &-message {
    margin-bottom: 10px;
  }
  &-express {
    margin-bottom: 10px;
  }
}
</style>
