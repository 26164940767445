<template>
  <div class="head">
    <div class="head-title">{{ serveName }}</div>
    <div class="head-lab"><span>服务方 : </span>{{ laboratoryName }}</div>
  </div>
</template>
<script>
export default {
  name: "headerOrder",
  props: {
    serveName: {
      type: String,
      default: "",
    },
    laboratoryName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.head {
  border-bottom: 1px solid $border-color;
  padding: 10px 15px;
  &-title {
    font-size: 1.42rem;
    margin-bottom: 5px;
  }
  &-lab {
    > span {
      color: $font-color-gray;
    }
  }
}
</style>
