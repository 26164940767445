<template>
  <div class="device">
    <el-form :inline="true" :model="queryForm" :size="queryFormSize">
      <el-form-item label="设备名称">
        <el-input
          v-model="queryForm.equipmentName"
          placeholder="请输入设备名称"
          class="query-name"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select
          v-model="queryForm.equipmentStatus"
          placeholder="请选择"
          class="query-status"
          clearable
        >
          <el-option
            v-for="item in statusOptions"
            :key="item.dictValue"
            :label="item.dictLabel"
            :value="item.dictValue"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryBtnClick" :loading="listLoading"
          >查询</el-button
        >
        <el-button type="primary" @click="addBtnClick">新增</el-button>
      </el-form-item>
    </el-form>
    <div class="device-table">
      <TableData
        :data="tableData"
        :statusMap="statusMap"
        @updateBtn="updateBtn"
        @viewBtn="viewBtn"
        @delBtn="delBtn"
        @refresh="refresh"
        v-loading="listLoading"
      />
    </div>
    <!-- 分页-->
    <Pagination
      :total="totalItem"
      :page="page"
      @pageIndexChange="handlePageChange"
    />
    <el-drawer
      title="设备维护"
      :visible.sync="showDrawer"
      :wrapperClosable="false"
      size="900px"
      class="drawer"
    >
      <DeviceDetail
        :curId="selectRowId"
        :isEdit="drawerIsEdit"
        v-if="showDrawer"
        @saveSuccess="saveSuccess"
      />
    </el-drawer>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index";
import { DEVICE_STATUS } from "@/constant/dict";
import { QUERY_FORM_SIZE } from "@/constant/size";
import DeviceDetail from "./components/DeviceDetail.vue";
import { deviceList, delDevice } from "@/api/userCenter";
import TableData from "./components/TableData.vue";
export default {
  components: {
    DeviceDetail,
    TableData,
    Pagination,
  },
  data() {
    return {
      queryFormSize: QUERY_FORM_SIZE,
      queryForm: {
        equipmentName: "",
        equipmentStatus: null,
      },
      statusOptions: [],
      statusMap: {},
      listLoading: false,
      drawerIsEdit: null,
      selectRowId: null,
      showDrawer: false,
      page: {
        pageIndex: 1,
        pageSize: 5,
      },
      totalItem: 0,
      tableData: [],
    };
  },
  created() {
    this.getDict();
    this.queryList();
  },
  methods: {
    handlePageChange(index) {
      this.page.pageIndex = index;
      this.queryList();
    },
    async queryList() {
      this.tableLoading = true;
      const { list, totalItem } = await this.$axios
        .post(deviceList, {
          ...this.page,
          ...this.queryForm,
        })
        .finally(() => {
          this.tableLoading = false;
        });
      this.tableData = list;
      this.totalItem = totalItem;
    },

    // 获取数据字典
    async getDict() {
      const data = await this.$store.dispatch("getDictData", DEVICE_STATUS);
      this.statusOptions = data;
      const statusMap = {};
      data.forEach((item) => {
        statusMap[item.dictValue] = item.dictLabel;
      });
      this.statusMap = statusMap;
    },
    refresh() {
      this.page.pageIndex = 1;
      this.queryList();
    },
    // 查询按钮
    queryBtnClick() {
      this.page.pageIndex = 1;
      this.queryList();
    },
    // 新增按钮
    addBtnClick() {
      this.drawerIsEdit = false;
      this.selectRowId = null;
      this.showDrawer = true;
    },
    // 修改按钮
    updateBtn(id) {
      this.drawerIsEdit = true;
      this.selectRowId = id;
      this.showDrawer = true;
    },
    viewBtn(id) {
      this.selectRowId = id;
      this.showDrawer = true;
    },
    // 删除按钮
    async delBtn(equipmentId) {
      await this.$confirm("确定删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      await this.$axios.post(delDevice, { equipmentIds: [equipmentId] });
      this.$message.success("删除成功");
      this.queryList();
    },
    // 保存成功后
    saveSuccess() {
      this.showDrawer = false;
      this.page.pageIndex = 1;
      this.queryList();
    },
  },
};
</script>
<style lang="scss" scoped>
.device {
  padding: 10px;
  &-table {
    margin-bottom: 10px;
  }
}
</style>
