<template>
  <div class="good-inner flex">
    <div class="good-cover pointer" @click="toDetail">
      <img :src="info.imgsrc" v-if="info.imgsrc" />
      <img v-else :src="$oss('common/default_device.png')" />
    </div>
    <div class="good-content flex-1">
      <div class="good-title">
        <!-- <label class="discount" v-if="info.discount">优惠</label> -->
        <label class="name">{{ info.devicename }}</label>
        <i class="el-icon-location"></i>
        <span>{{ info.city }}</span>
      </div>
      <div class="good-mail" v-if="+info.isFreeMail">
        <span>顺丰包邮</span>
      </div>
      <div class="detail flex flex-between">
        <div class="detail-left flex-1">
          <div v-if="info.brand || info.model">
            <div class="flex">
              <div class="detail-brand" v-if="info.brand">
                <label>品<i></i>牌</label>{{ info.brand }}
              </div>
              <div v-if="info.model">
                <label>型<i></i>号</label>{{ info.model }}
              </div>
            </div>
            <div></div>
          </div>
          <div>
            <label>功<i></i>能</label>{{ info.functions }}
          </div>
          <div>
            <label>模<i></i>式</label>{{ info.pattern }}
          </div>
          <div>
            <label>店<i></i>铺</label>{{ info.checkmallBusinessName }}
          </div>
        </div>
        <div class="detail-right flex flex-column flex-right">
          <div>
            <div class="flex flex-row flex-right">
              <div class="detail-price">
                <span v-if="!info.preferentialPrice || info.face">面议</span>
                <span v-else
                  >￥{{ info.preferentialPrice }} /
                  {{ unitMap[+info.unit] }}</span
                >
              </div>
              <div class="detail-origin" v-if="info.discount !== '10'">
                <span v-if="!info.minPrince || info.face">面议</span>
                <span v-else
                  >￥{{ info.minPrince }} / {{ unitMap[+info.unit] }}</span
                >
              </div>
            </div>
            <div class="detail-btn">
              <span class="pointer" @click.stop="toDetail">查看详情</span>
              <span class="pointer" @click.stop="toPay">在线下单</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    unitMap: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  methods: {
    toDetail() {
      this.$jump.push({ name: "deviceDetail", query: { id: this.info.id } });
    },
    toPay() {
      this.$jump.push({ name: "deviceOrder", query: { id: this.info.id } });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.good {
  &-inner {
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid #f0f0f0;
  }
  &-cover {
    width: 176px;
    height: 144px;
    border-radius: $border-radius;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $border-radius;
    }
  }
  &-content {
    padding-left: 20px;
  }
  &-title {
    margin-bottom: 10px;
    > label.name {
      font-size: 16px;
      font-weight: 600;
      margin-right: 27px;
    }
    i {
      color: #4482ff;
      font-size: 18px;
      margin-right: 2px;
    }
    > span {
      color: #4482ff;
    }
  }
  &-mail {
    margin-bottom: 10px;
    span {
      background: #ffdebd;
      border-radius: 3px;
      padding: 2px 14px;
      color: #ff6600;
      font-size: 15px;
    }
  }
}
.detail {
  background: #f9f9f9;
  border-radius: $border-radius;
  padding: 14px;
  font-size: 15px;
  &-right {
    padding-bottom: 5px;
    min-width: 200px;
    text-align: right;
  }
  &-left {
    > div {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  label {
    color: #999999;
    margin-right: 18px;
    i {
      padding: 0 8px;
    }
  }

  &-brand {
    margin-right: 100px;
  }
  &-price {
    color: $price-color;
    margin-bottom: 10px;
    text-align: right;
    > span {
      font-size: 16px;
    }
  }
  &-origin {
    margin-left: 10px;
    text-decoration: line-through;
    position: relative;
    top: 2px;
    color: #737373;
    > span {
      font-size: 14px;
    }
  }
  &-btn {
    > span {
      border: 1px solid #4482ff;
      color: #4482ff;
      text-align: center;
      font-size: 17px;
      padding: 2px 15px;
      display: inline-block;
      &:first-child {
        margin-right: 10px;
      }
      &:last-child {
        background: #4482ff;
        color: white;
      }
    }
  }
}
</style>
