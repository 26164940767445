<template>
  <div>
    <HeadBar /><!--顶部bar-->
    <!--顶部logo、检索-->
    <HeadNav /><!-- 顶部导航-->
    <div class="lab">
      <div class="lab-inner">
        <div class="base flex felx-row">
          <div class="base-left">
            <div class="base-cover">
              <img :src="detail.laboratoryLogo" />
            </div>
            <div class="base-btn">委托检测</div>
          </div>
          <div class="flex-1">
            <div class="base-labName">{{ detail.laboratoryName }}</div>
            <div class="flex flex-row base-contact">
              <div>
                <label>联&nbsp;&nbsp;系&nbsp;&nbsp;人</label
                ><span>{{ detail.laboratoryContactsName }}</span>
              </div>
              <div>
                <label>电&nbsp;&nbsp;&nbsp;话</label>
                <span>{{ detail.laboratoryContactsPhone }}</span>
              </div>
              <div>
                <label>邮&nbsp;&nbsp;&nbsp;箱</label>
                <span>{{ detail.laboratoryEmail }}</span>
              </div>
            </div>
            <div class="base-line flex flex-row">
              <label>地&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;址</label>
              <span>{{ detail.laboratoryLocationProvince }}</span>
              <span>{{ detail.laboratoryLocationCity }}</span>
              <span>{{ detail.laboratoryLocationCounty }}</span>
              <span>{{ detail.laboratoryAddress }}</span>
            </div>
            <div class="base-line flex flex-row">
              <label>简&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;介</label>
              <span>{{ detail.laboratoryBrief }}</span>
            </div>
            <div class="base-line flex flex-row base-check">
              <label>检测业务</label>
              <span>{{ detail.laboratoryCheckInfo }}</span>
            </div>
            <div class="base-line flex flex-row base-check">
              <label>特色检测<br />能力</label>
              <span>{{ detail.specialAbility }}</span>
            </div>
            <div class="flex flex-row base-qua">
              <div class="label">资质认证</div>
              <div class="flex flex-row">
                <div v-for="(item, index) in qualifications" :key="index">
                  <el-image
                    :src="item"
                    :preview-src-list="[item]"
                    class="base-quaImg"
                  >
                  </el-image>
                </div>
              </div>
            </div>
            <div class="flex flex-row base-qua">
              <div class="label">营业执照</div>
              <div class="flex flex-row">
                <div v-for="(item, index) in businessLicence" :key="index">
                  <el-image
                    :src="item"
                    :preview-src-list="[item]"
                    class="base-quaImg"
                  >
                  </el-image>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gray-span"></div>
      <div class="lab-inner">
        <LabImgs :data="banners" />
      </div>
      <Devices />
      <CheckProducts />
    </div>
    <FootBar class="footer" />
  </div>
</template>
<script>
import { labDetail } from "@/api/lab";
import LabImgs from "./components/LabImgs.vue";
import Devices from "./components/Devices";
import CheckProducts from "./components/CheckProducts";
export default {
  components: {
    LabImgs,
    Devices,
    CheckProducts,
  },
  data() {
    return {
      detail: {},
      qualifications: [],
      businessLicence: [],
      banners: [],
      loading: false,
      mainId: null,
    };
  },
  created() {
    this.mainId = this.$route.query.id;
    this.getDetail();
  },
  methods: {
    async getDetail() {
      try {
        this.loading = true;
        const data = await this.$axios.get(labDetail, {
          laboratoryId: this.mainId,
        });
        this.loading = false;
        this.detail = data;
        const { checkQualification, laboratoryBanner, businessLicence } = data;
        if (checkQualification) {
          this.qualifications = checkQualification.split(";");
        }
        if (laboratoryBanner) {
          this.banners = laboratoryBanner.split(";");
        }
        if (businessLicence) {
          this.businessLicence = businessLicence.split(";");
        }
      } catch (e) {
        this.$message.error("服务异常，请稍后再试");
      }
    },
    // 发布需求
    toRelease() {
      this.$jump.push({
        name: "releaseReq",
        query: { relationLabId: this.mainId },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
$item-h: 140px;
.lab {
  padding-top: 20px;
  &-inner {
    width: $max-width;
    margin: 0 auto;
  }
}
.base {
  &-left {
    margin-right: 30px;
    width: 150px;
  }
  &-cover {
    width: 150px;
    height: 118px;
    border: 1px solid #eaeef1;
    margin-bottom: 18px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &-btn {
    width: 150px;
    height: 31px;
    line-height: 31px;
    background: #4482ff;
    border-radius: 2px;
    text-align: center;
    color: white;
  }
  &-labName {
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 20px;
  }
  &-contact {
    margin-bottom: 13px;
    > div {
      margin-right: 40px;
    }
    > div:first-child {
      label {
        margin-right: 40px;
      }
    }
    label {
      color: #999999;
      margin-right: 15px;
    }
    span {
      padding: 2px 14px;
      background: #eef3ff;
      color: #4482ff;
    }
  }
  &-line {
    margin-bottom: 10px;
    label {
      color: #999999;
      line-height: 25px;
      white-space: nowrap;
      margin-right: 40px;
    }

    span {
      line-height: 25px;
    }
  }
  &-check {
    margin-bottom: 20px;
  }
  &-qua {
    margin-bottom: 20px;
    .label {
      color: #999999;
      margin-right: 40px;
    }
  }
  &-quaImg {
    width: 77px;
    height: 108px;
    margin-right: 20px;
    img {
      object-fit: contain;
    }
  }
}
.gray-span {
  height: 20px;
  background: $home-bg;
}
.footer {
  padding-top: 0;
}
</style>
