export default {
  strictFormRule: {
    checkmallBusinessName: [
      {
        required: true,
        message: "请输入检测商城商家名称",
        trigger: ["blur", "change"],
      },
    ],
    // checkmallBusinessLogo: [
    //   {
    //     required: true,
    //     message: "请上传检测商城商家logo",
    //     trigger: ["blur", "change"],
    //   },
    // ],
    checkmallBusinessContactsName: [
      {
        required: true,
        message: "请输入商家联系人姓名",
        trigger: ["blur", "change"],
      },
    ],
    checkmallBusinessContactsPhone: [
      {
        required: true,
        message: "请输入商家联系电话",
        trigger: ["blur", "change"],
      },
    ],
    checkmallBusinessBrief: [
      {
        required: true,
        message: "请输入检测商城商家介绍",
        trigger: ["blur", "change"],
      },
    ],
  },
  superFormRule: {
    productmallBusinessName: [
      {
        required: true,
        message: "请输入检测商城商家名称",
        trigger: ["blur", "change"],
      },
    ],
    // productmallBusinessLogo: [
    //   {
    //     required: true,
    //     message: "请上传检测商城商家logo",
    //     trigger: ["blur", "change"],
    //   },
    // ],
    productmallBusinessContactsName: [
      {
        required: true,
        message: "请输入商家联系人姓名",
        trigger: ["blur", "change"],
      },
    ],
    productmallBusinessContactsPhone: [
      {
        required: true,
        message: "请输入商家联系电话",
        trigger: ["blur", "change"],
      },
    ],
    productmallBusinessBrief: [
      {
        required: true,
        message: "请输入检测商城商家介绍",
        trigger: ["blur", "change"],
      },
    ],
  },
};
