<template>
  <div class="block-wrapper">
    <EditorView
      :data="info.productmallWaresAbstracts"
      v-if="info.productmallWaresAbstracts"
    />
  </div>
</template>
<script>
export default {
  name: "detailBase",
  components: {},
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.block {
  &-wrapper {
    padding: 30px 30px;
  }
}
</style>
