// 获取当前页面的来源页面，用于数据埋点
export default {
  data() {
    return {
      routeFrom: {},
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeFrom = { fullPath: from.fullPath, name: from.name };
      const referrer = document.referrer;
      if (!from.name && referrer) {
        const pSplit = referrer.split("/");
        const last = pSplit[pSplit.length - 1].split("?")[0];
        Object.assign(vm.routeFrom, {
          fullPath: referrer,
          name: last,
        });
      }
    });
  },
};
