<template>
  <div class="flex flex-row">
    <div class="flex-1">
      <div class="list">
        <label>仪器名称：</label><span>{{ data.checkmallWaresName }}</span>
      </div>
      <div class="list">
        <label>订单创建时间：</label><span>{{ data.createTime }}</span>
      </div>
      <div class="list">
        <label>报告交付方式：</label
        ><span>{{ deliveryMap[data.checkReportDeliveryType] }}</span>
      </div>
      <div class="list">
        <label>此次委托同意接受分包：</label
        ><span>{{ subcontractTypeMap[data.subcontractType] }}</span>
      </div>
    </div>
    <div class="flex-1">
      <div class="list">
        <label>订单价格：</label><span>￥{{ data.orderPrice }}元</span>
      </div>
      <!-- <div class="list"><label>样品是否回收：</label><span>快递邮寄</span></div> -->
    </div>
  </div>
</template>
<script>
import { SUB_CONTRACT_TYPE, CHECK_REPORT_DELIVERY_TYPE } from "@/constant/dict";
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      subcontractTypeMap: {},
      deliveryMap: {},
    };
  },
  created() {
    this.getDict();
  },
  methods: {
    async getDict() {
      const data = await this.$store.dispatch("getDictData", SUB_CONTRACT_TYPE);
      const map = {};
      data.forEach((item) => {
        map[item.dictValue] = item.dictLabel;
      });
      this.subcontractTypeMap = map;
      const res = await this.$store.dispatch(
        "getDictData",
        CHECK_REPORT_DELIVERY_TYPE
      );
      const dMap = {};
      res.forEach((item) => {
        dMap[item.dictValue] = item.dictLabel;
      });
      this.deliveryMap = dMap;
    },
  },
};
</script>
<style lang="scss" scoped>
.list {
  margin-bottom: 8px;
  > label {
    color: #909399;
  }
}
</style>
