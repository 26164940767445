<template>
  <div class="rank">
    <div class="rank-title">排名前5的省份的设备占比</div>
    <div>
      <div v-for="(item, index) in rank" :key="index" class="rank-block">
        <template v-if="index < 3">
          <img :src="$oss(`home/rank${index + 1}.png`)" class="rank-bg" />
        </template>
        <div class="rank-con flex flex-row">
          <div class="rank-index">
            <img :src="require(`@/assets/home/mapData/${index + 1}.png`)" />
          </div>
          <div :class="{ afterItem: index > 2 }">
            <div class="rank-address">{{ item.regionName }}</div>
            <div>
              <span class="rank-num">{{ item.deviceCount }}</span>
              <span class="rank-radio">{{ item.scale }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { deviceTop5 } from "@/api/home";
export default {
  data() {
    return {
      rank: [],
    };
  },
  created() {
    this.getTop5();
  },
  methods: {
    async getTop5() {
      const res = await this.$axios.get(deviceTop5);
      this.rank = res;
    },
  },
};
</script>
<style lang="scss" scoped>
.rank {
  width: 202px;
  margin-left: 40px;
  &-title {
    font-size: 1.14rem;
    color: #4483ff;
    text-align: center;
    margin-bottom: 20px;
  }
  &-block {
    position: relative;
    margin-bottom: 11px;
    height: 80px;
    width: 100%;
    background: white;
    border-radius: 5px;
  }
  &-bg {
    position: absolute;
    top: 0;
    left: 0;
  }
  &-con {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    padding-left: 24px;
    padding-top: 16px;
    height: 80px;
    width: 100%;
    color: white;
  }
  &-index {
    margin-right: 14px;
    padding-top: 15px;
  }
  &-address {
    font-size: 1.14rem;
  }
  &-num {
    font-size: 1.28rem;
    font-weight: bold;
    margin-right: 14px;
  }
}
.afterItem {
  color: #0d6dbb;
}
</style>
