<template>
  <div class="hot">
    <div class="hot-inner">
      <div class="hot-head">设备展示</div>
      <div class="flex flex-row flex-wrap" v-loading="loading">
        <div
          class="item pointer"
          :class="{ isRight: (index + 1) % 4 == 0 }"
          v-for="(item, index) in labDevices"
          :key="index"
          @click="deviceClick(item)"
        >
          <img class="item-img" :src="item.equipmentImage" />
          <div class="item-desc">
            <div class="item-name ellipsis">
              {{ item.equipmentName }}
            </div>
            <div class="item-brief ellipsis">{{ item.equipmentBrand }}</div>
          </div>
        </div>
      </div>
    </div>
    <DeviceDialog v-model="dialogShow" :data="currentData" />
  </div>
</template>
<script>
import DeviceDialog from "./DeviceDialog.vue";
import { labDeviceList } from "@/api/lab";
export default {
  name: "Devices",
  components: {
    DeviceDialog,
  },
  data() {
    return {
      labDevices: [],
      loading: true,
      dialogShow: false,
      currentData: {},
    };
  },
  created() {
    // 获取实验室设备列表
    this.getLabDevices();
  },
  methods: {
    // 获取实验室设备列表
    async getLabDevices() {
      this.loading = true;
      const { list } = await this.$axios.post(labDeviceList, {
        laborarotyId: this.$route.query.id,
      });
      this.loading = false;
      this.labDevices = list;
    },
    deviceClick(data) {
      this.currentData = data;
      this.dialogShow = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.hot {
  background: $home-bg;
  min-height: 100px;
  &-inner {
    width: $max-width;
    margin: 0 auto;
  }
  &-head {
    font-size: 18px;
    font-weight: bold;
    color: #000000;
    text-align: center;
    padding-top: 38px;
    padding-bottom: 20px;
  }
}
.item {
  width: 278px;
  margin-right: 29px;
  margin-bottom: 30px;
  &.isRight {
    margin-right: 0;
  }
  &-img {
    width: 100%;
    height: 222px;
    object-fit: cover;
    vertical-align: middle;
  }
  &-desc {
    padding: 16px;
    background: white;
  }
  &-name {
    font-weight: bold;
    color: #333333;
    margin-bottom: 4px;
    font-size: 16px;
  }
  &-brief {
    color: #999999;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
</style>
