<template>
  <div class="account">
    <div class="form">
      <el-form
        :inline="true"
        :model="queryFrom"
        class="demo-form-inline"
        :size="queryFormSize"
      >
        <el-form-item label="账号状态">
          <el-select
            v-model="queryFrom.enterpriseRelationStatus"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.dictValue"
              :label="item.dictLabel"
              :value="item.dictValue"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账号真实名称">
          <el-input v-model="queryFrom.realName" clearable />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryBtnClick">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="account-data">
      <el-table
        :data="tableData"
        border
        :size="tableSize"
        v-loading="tableLoading"
      >
        <el-table-column type="index" label="#"> </el-table-column>
        <el-table-column prop="realName" label="账号真实名称">
        </el-table-column>
        <!-- <el-table-column prop="accountType" label="账户权限"> </el-table-column> -->
        <el-table-column prop="enterpriseRelationStatus" label="账户状态">
          <template slot-scope="{ row }">
            {{ statusMap[row.enterpriseRelationStatus] }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="申请时间"> </el-table-column>
        <el-table-column label="操作" width="160px">
          <template slot-scope="{ row }">
            <div v-if="row.userId !== userBaseInfo.userId">
              <template v-if="row.enterpriseRelationStatus === '1'">
                <el-button type="text" size="small" @click="editBtnClick(row)"
                  >分配权限</el-button
                >
                <el-button
                  @click="delBtnClick(row)"
                  type="text"
                  size="small"
                  class="danger"
                  >解除关联</el-button
                >
              </template>
              <template v-else-if="row.enterpriseRelationStatus === '2'">
                <el-button type="text" size="small" @click="checkBtnClick(row)"
                  >审核</el-button
                >
              </template>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页-->
    <Pagination
      :total="totalItem"
      :page="page"
      @pageIndexChange="handlePageChange"
    />
    <SetAuth
      v-model="authDialogVisible"
      :data="currentRow"
      v-if="authDialogVisible"
    />
    <CheckDialog
      v-model="checkDialogVisible"
      :data="currentRow"
      @refresh="refresh"
      v-if="checkDialogVisible"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { TABLE_SIZE, QUERY_FORM_SIZE } from "@/constant/size";
import SetAuth from "./components/SetAuth.vue";
import CheckDialog from "./components/CheckDialog.vue";
import { deleteLabUser } from "@/api/lab";
import { queryUserInEnterprise } from "@/api/enterprise";
import Pagination from "@/components/Pagination/index";
import { ENT_USER_STATUS } from "@/constant/dict";
export default {
  components: {
    SetAuth,
    Pagination,
    CheckDialog,
  },
  data() {
    return {
      queryFormSize: QUERY_FORM_SIZE,
      tableSize: TABLE_SIZE,
      authDialogVisible: false,
      checkDialogVisible: false,
      statusOptions: [],
      statusMap: {},
      queryFrom: {
        enterpriseRelationStatus: null,
      },
      tableData: [],
      totalItem: 0,
      page: {
        pageIndex: 1,
        pageSize: 10,
      },
      tableLoading: false,
      currentRow: {},
    };
  },
  computed: {
    ...mapGetters(["userBaseInfo"]),
  },
  created() {
    this.getDict();
    this.getEntUser();
  },
  methods: {
    // 获取数据字典
    async getDict() {
      const data = await this.$store.dispatch("getDictData", ENT_USER_STATUS);
      this.statusOptions = data;
      const statusMap = {};
      data.forEach((item) => {
        statusMap[item.dictValue] = item.dictLabel;
      });
      this.statusMap = statusMap;
    },
    // 编辑
    editBtnClick(data) {
      this.currentRow = data;
      this.authDialogVisible = true;
    },
    // 审核
    checkBtnClick(data) {
      this.currentRow = data;
      this.checkDialogVisible = true;
    },
    // 删除关联
    async delBtnClick(data) {
      await this.$confirm("确定删除该用户吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      const { relationId } = data;
      await this.$axios.post(deleteLabUser, {
        relationId,
      });
      this.$message.success("删除成功");
      this.refresh();
    },
    queryBtnClick() {
      this.getEntUser();
    },
    // 分页查询
    handlePageChange(index) {
      this.page.pageIndex = index;
      this.getEntUser();
    },
    // 刷新页面
    refresh() {
      this.page.pageIndex = 1;
      this.getEntUser();
    },
    // 获取列表数据
    async getEntUser() {
      this.tableLoading = true;
      const { list, totalItem } = await this.$axios
        .post(queryUserInEnterprise, {
          ...this.page,
          ...this.queryFrom,
        })
        .finally(() => {
          this.tableLoading = false;
        });
      this.tableData = list;
      this.totalItem = totalItem;
    },
  },
};
</script>
<style lang="scss" scoped>
.account {
  padding: 10px;
  &-data {
    min-height: 500px;
  }
}
.form {
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }
}
.danger {
  color: #f56c6c;
}
</style>
