<template>
  <div>
    <HeadBar @loginSuccess="loginSuccess" /><!--顶部bar-->
    <!--顶部logo、检索-->
    <HeadNav /><!-- 顶部导航-->
    <div class="detail">
      <div class="detail-inner">
        <div class="detail-path">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ name: 'discountActs' }"
              >优惠活动</el-breadcrumb-item
            >
            <el-breadcrumb-item>优惠活动详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="con">
          <div class="con-title">{{ act.discountActivityTitle }}</div>
          <div class="con-time">
            <span class="con-timeLabel">活动时间</span>
            <span>{{ act.discountActivityStartTime | transformTime }}</span>
            <span class="con-timeZhi">至</span>
            <span>{{ act.discountActivityEndTime | transformTime }}</span>
          </div>
          <EditorView :data="act.discountActivityIntroduce" />
          <Coupons :data="act.discountActivityCoupons" />
        </div>
      </div>
    </div>
    <FootBar class="footer" />
  </div>
</template>
<script>
import { discountActDetail } from "@/api/activity";
import { parseTime } from "@/utils/index";
import Coupons from "./components/coupons.vue";
export default {
  components: {
    Coupons,
  },
  filters: {
    transformTime(val) {
      return parseTime(val, "{y}/{m}/{d} {h}:{i}");
    },
  },
  data() {
    return {
      mainId: null,
      act: {},
    };
  },
  created() {
    const { id } = this.$route.query;
    this.mainId = id;
    this.getDetail(); // 获取活动详情
  },
  methods: {
    // 获取活动详情
    async getDetail() {
      const res = await this.$axios.get(discountActDetail, {
        discountActivityId: this.mainId,
      });
      this.act = res;
    },
    // 监听登录成功，重新获取数据
    loginSuccess() {
      this.getDetail();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.detail {
  background: $home-bg;
  &-inner {
    width: $max-width;
    margin: 0 auto;
    padding-bottom: 30px;
  }
  &-path {
    padding-top: 26px;
  }
}
.con {
  min-height: 400px;
  background: white;
  margin-top: 20px;
  padding: 25px;
  &-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #dfe5ec;
    margin-bottom: 15px;
  }
  &-time {
    text-align: center;
    font-size: 16px;
    color: #999999;
    margin-bottom: 30px;
  }
  &-timeLabel {
    margin-right: 10px;
  }
  &-timeZhi {
    margin: 0 5px;
  }
}
</style>
