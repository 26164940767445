<template>
  <div class="address">
    <div class="address-top flex flex-between">
      <div class="address-title">用户地址</div>
      <div class="address-jump" @click="manageAddess">管理收货地址</div>
    </div>
    <div class="address-con" v-loading="loading">
      <div
        v-for="(item, index) in address"
        :key="index"
        class="flex flex-between address-list"
        :class="{ active: item.userAddressId === selectAddressId }"
        @click="addressSelectChange(item)"
      >
        <div class="flex flex-row">
          <div>
            <span class="address-radio"></span>
          </div>
          <div class="flex">
            <div class="address-link">
              <label>{{ item.linkMan }}</label>
              <label class="linkPhone">{{ item.linkPhone }}</label>
            </div>
            <div>
              <span>{{ item.addressProvince }}</span>
              <span>{{ item.addressCity }}</span>
              <span>{{ item.addressDistrict }}</span>
              <span>{{ item.addressDetailed }}</span>
            </div>
          </div>
        </div>
        <div
          v-if="item.userAddressId === selectAddressId"
          class="address-edit"
          @click="showEditDialog"
        >
          修改本地址
        </div>
      </div>
      <div class="address-add">
        <el-button type="info" plain size="mini" @click="showAddDialog"
          >新增地址</el-button
        >
      </div>
    </div>
    <Dialog
      v-model="dialogVisible"
      :is-edit="dialogIsEdit"
      :cur-id="selectAddressId"
      @refreshAddress="getAddressList"
    />
  </div>
</template>
<script>
import { addressList } from "@/api/userCenter";
import Dialog from "@/components/AddressDialog/index";
export default {
  components: {
    Dialog,
  },
  data() {
    return {
      options: [false, false],
      selectAddressId: null,
      address: [],
      dialogVisible: false,
      dialogIsEdit: false,
      loading: false,
    };
  },
  computed: {
    dialogTitle() {
      return this.dialogType === "edit" ? "修改收货地址" : "新增收货地址";
    },
  },
  created() {
    this.getAddressList();
  },
  methods: {
    async getAddressList() {
      this.loading = true;
      const data = await this.$axios.get(addressList).finally(() => {
        this.loading = false;
      });
      this.address = data;
      if (!this.selectAddressId && data && data.length)
        this.selectAddressId = data[0].userAddressId;
    },
    addressSelectChange(item) {
      this.selectAddressId = item.userAddressId;
    },
    showEditDialog() {
      this.dialogIsEdit = true;
      this.dialogVisible = true;
    },
    showAddDialog() {
      this.dialogIsEdit = false;
      this.dialogVisible = true;
    },
    manageAddess() {
      this.$router.push({ name: "addressManage" });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.address {
  border: 1px solid $border-color;
  border-radius: $border-radius;
  margin-bottom: 20px;
  &-top {
    padding: 8px 15px;
    border-bottom: 1px solid $border-color;
  }
  &-title {
    font-size: 1.14rem;
  }
  &-jump {
    color: $theme-color;
    cursor: pointer;
    font-size: 0.85rem;
  }
  &-con {
    padding: 15px;
  }
  &-list {
    padding: 5px;
    margin-bottom: 3px;
    border: 1px solid white;
    &:hover {
      background: #d9e8f9;
      cursor: pointer;
    }
    &.active {
      background: #c6e2ff;
      border: 1px solid $theme-color;
      .address-radio {
        width: 14px;
        height: 14px;
        border: 3px solid $theme-color;
      }
    }

    /deep/ .el-radio__input.is-checked + .el-radio__label {
      color: #2c3e50;
      font-weight: bold;
    }
  }
  &-radio {
    display: inline-block;
    width: 14px;
    height: 14px;
    border: 1px solid $border-color;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 5px;
  }
  &-link {
    margin-right: 10px;
    > label.linkPhone {
      margin-left: 10px;
    }
  }
  &-edit {
    &:hover {
      color: $theme-color;
    }
  }
  &-add {
    padding-left: 85px;
    margin-top: 10px;
  }
}
</style>
