export default {
  checkmallWaresName: [
    {
      required: true,
      message: "请输入检测商城服务名称",
      trigger: ["blur", "change"],
    },
  ],
  serviceCategoryId: [
    {
      required: true,
      message: "请选择服务分类",
      trigger: ["change"],
    },
  ],
  checkmallWaresPattern: [
    {
      required: true,
      message: "请输入检测模式",
      trigger: ["blur", "change"],
    },
  ],
  checkmallWaresFunctions: [
    {
      required: true,
      message: "请输入检测模式",
      trigger: ["blur", "change"],
    },
  ],
  serviceProject: [
    {
      required: true,
      message: "请输入服务项目",
    },
  ],
  waresServiceBrief: [
    {
      required: true,
      message: "请输入服务简介",
      trigger: ["blur", "change"],
    },
  ],
  waresServiceParam: [
    {
      required: true,
      message: "请输入服务参数",
    },
  ],
  waresSampleRequirements: [
    {
      required: true,
      message: "请输入样品要求",
    },
  ],
  checkmallWaresMethod: [
    {
      required: true,
      message: "检测依据/方法",
      trigger: ["blur", "change"],
    },
  ],
  checkmallWaresEquipment: [
    {
      required: true,
      message: "请输入检测使用设备",
      trigger: ["blur", "change"],
    },
  ],
  isFreeMail: [
    {
      required: true,
      message: "请选择是否包邮",
      trigger: ["change"],
    },
  ],
};
