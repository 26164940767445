<template>
  <div>
    <HeadBar /><!--顶部bar-->
    <!--顶部logo、检索-->
    <HeadNav /><!-- 顶部导航-->
    <div class="jor">
      <div class="jor-inner flex flex-row">
        <div class="type" v-loading="typeLoading">
          <div
            class="type-item pointer"
            v-for="(item, index) in journalTypes"
            :key="index"
            :class="{ active: typeActiveIndex === index }"
            @click="typeClick(index)"
          >
            {{ item.dictLabel }}
          </div>
        </div>
        <div class="flex-1" v-loading="listLoading">
          <JorItem
            v-for="(item, index) in journalList"
            :key="index"
            :data="item"
          />
        </div>
      </div>
      <!-- 分页-->
      <Pagination
        :total="totalItem"
        :page="page"
        @pageIndexChange="handlePageChange"
      />
    </div>
    <FootBar class="footer" />
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index";
import { JOURNAL_TYPE } from "@/constant/dict";
import { jourList } from "@/api/journal";
import JorItem from "./components/jorItem.vue";
export default {
  components: {
    Pagination,
    JorItem,
  },
  data() {
    return {
      journalTypes: [],
      typeLoading: false,
      listLoading: false,
      typeActiveIndex: 0,
      journalList: [],
      page: {
        pageIndex: 1,
        pageSize: 6,
      },
      totalItem: 0,
    };
  },
  created() {
    this.getDict();
  },
  methods: {
    async getDict() {
      this.typeLoading = true;
      const data = await this.$store
        .dispatch("getDictData", JOURNAL_TYPE)
        .finally(() => {
          this.typeLoading = false;
        });
      this.journalTypes = data;
      this.getList();
    },

    async getList() {
      this.listLoading = true;
      this.journalList.length = 0;
      const { list, totalItem } = await this.$axios
        .post(jourList, {
          ...this.page,
          goodsCategory: this.journalTypes[this.typeActiveIndex].dictValue,
        })
        .finally(() => {
          this.listLoading = false;
        });
      this.journalList = list;
      this.totalItem = totalItem;
    },
    typeClick(index) {
      this.typeActiveIndex = index;
      this.page.pageIndex = 1;
      this.getList();
    },
    handlePageChange(index) {
      this.page.pageIndex = index;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.jor {
  background: #f5f5f5;
  padding-top: 20px;
  padding-bottom: 30px;
  &-inner {
    width: $max-width;
    margin: 0 auto;
    min-height: 500px;
    margin-bottom: 20px;
  }
}
.type {
  width: 177px;
  min-height: 200px;
  background: white;
  text-align: center;
  margin-right: 25px;
  &-item {
    height: 44px;
    line-height: 44px;
    font-size: 16px;
    color: #666666;
    &.active,
    &:hover {
      background: #4482ff;
      color: white;
    }
  }
}
.footer {
  padding-top: 0 !important;
}
</style>
