// 能力大厅
export const abilityType = `/api/unoauth/laboratory/getLaboratoryAbilityTypeList`;
export const abilityList = `/api/unoauth/laboratory/getAbilityListPage`;
export const newLabList = `/api/unoauth/homepage/newSettledLaboratory`;
export const abilityDetail = `/api/unoauth/laboratory/laboratoryAbilityByAbilityId`; // 能力详情
export const allAbilityType = `/api/unoauth/laboratory/getAllAbilityType`; // 获取所有能力分类
// 需求大厅
export const demandList = `/api/unoauth/customer/listRareDemand`; // 检测需求列表

// 需求大厅详情
export const demandDetail = `/api/unoauth/customer/getRareDemandDetail`;

// 发布需求页面
export const qualificationList = `/api/unoauth/qualification/listCheckQualification`; // 获取检测资质列表
export const addDemand = `/api/oauth/customer/addDemand`; // 新增检测需求
