<template>
  <div class="type">
    <img
      :src="require(`../../../assets/mall/mallHome/type${index + 1}.png`)"
      class="type-bg"
    />
    <div class="type-con">
      <div class="type-name">{{ data.serviceCategoryName }}</div>
      <div v-if="data.child && data.child.length">
        <span
          v-for="(child, cIndex) in data.child.slice(
            0,
            Math.min(data.child.length, 8)
          )"
          :key="`c${cIndex}`"
          class="type-title"
          >{{ child.serviceCategoryName }}、</span
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: null,
    },
  },
};
</script>
<style lang="scss" scoped>
.type {
  width: 216px;
  height: 484px;
  position: relative;
  overflow: hidden;
  &-bg {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  &-con {
    padding: 56px 20px;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  &-name {
    margin-bottom: 30px;
    font-size: 30px;
    color: #333333;
  }
  &-title {
    font-size: 17px;
    font-weight: 300;
    color: #666666;
  }
}
</style>
